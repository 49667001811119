import styled from "styled-components/macro";
export const Wrapper = styled.div`
  width: calc(100%);
  max-width: 210px;

  position: relative;

  @media (max-width: 768px) {
    /* width: 90px; */
  }
`;
export const Inner = styled.button`
  /* ESTILO GLOBAL */
  position: relative;

  height: 40px;
  width: ${(props) => (props.opened || props.hoverOpened ? "100%" : "40px")};

  font-size: 18px;
  font-weight: bold;

  display: grid;
  grid-gap: 6px;
  grid-template-rows: 1fr;
  grid-template-columns: ${(props) =>
    props.opened || props.hoverOpened ? "24px 1fr 24px" : "1fr"};

  place-items: center;

  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  color: ${(props) => (props.active ? "#FFF" : "#1d9d61")};

  transition: ease 0.3s all;
  cursor: pointer;

  border: none;
  padding: 8px 10px;
  border-radius: 8px;

  &:focus {
    box-shadow: 0px 0px 0 1px #1d9d61, 0px 0px 0 3px #fff;
    outline: none;
  }
  background-color: ${(props) => (props.active ? "#1d9d61" : "#00000000")};
  border-color: #1d9d61;
`;

export const Title = styled.div`
  place-self: center start;
  animation: ease 0.4s fadeInRight;
  will-change: auto;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;
export const Arrow = styled.button`
  display: grid;
  place-items: center;
  height: 100%;
  width: 40px;
  position: absolute;
  background-color: transparent;
  border: none;
  cursor: pointer;
  top: 0;
  right: 0;
  z-index: 10;
  transform: ${(props) => (props.expanded ? "rotate(0deg)" : "rotate(-90deg)")};
  transition: ease 0.3s all;

  @media (max-width: 768px) {
    /* display: none; */
  }
`;
export const Icon = styled.div`
  transition: ease 0.3s all;
  will-change: auto;
  ${(props) => (props.opened || props.hoverOpened ? "100%" : "40px")};
  display: grid;
  place-items: center start;
  svg {
    width: 16px;
  }

  @media (max-width: 768px) {
    /* display: none; */
  }
`;
export const ButtonItems = styled.div`
  will-change: auto;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center start;
`;
export const ItemArrow = styled.div`
  transform: rotate(-90deg);
  position: absolute;
  left: 0;
  padding: 10px;

  transition: ease 0.3s all;

  @media (max-width: 768px) {
    /* display: none; */
  }
`;
export const ButtonItemWrapper = styled.button`
  animation: ease 0.4s fadeInRight;

  white-space: nowrap;
  border: none;
  background-color: transparent;
  color: #1d9d61;
  border-bottom: 1px solid #1d9d61;

  &:last-child {
    border: none;
  }
  .itemArrow {
    margin-left: ${(props) => props.active && "6px"};
  }
  &:hover {
    .itemArrow {
      margin-left: 6px;
    }
  }

  position: relative;
  display: flex;
  align-items: center;

  cursor: pointer;
  padding-left: 40px;

  width: 172px;
  height: 46px;

  //styleName: paragraph-desktop;

  font-size: 14px;

  font-weight: ${(props) => props.active && "700"};
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 768px) {
    /* width: 90px;
    font-size: 10px;
    padding-left: 0; */
  }
`;
