import { useState } from "react";
import { Form } from "@unform/web";
import Select from "Components/Admin/Inputs/Select";
import { useEffect } from "react";
import UseServices from "hooks/UseServices";
import { transformArrayIntoSelectOptions } from "helpers/util";
import { HTTPErrorCallback } from "helpers/errorHelper";
import Button from "Components/Admin/Buttons/Button";
import { useRef } from "react";
import * as S from "./styles";
import { showNotification } from "helpers/notificationHelper";

const ReplaceTeacher = ({
  open,
  setOpen,
  teacherStudentId,
  studentId,
  currentTeacherId,
}) => {
  const { api } = UseServices();
  const [nameOrEmail, setNameOrEmail] = useState("f");
  const [loading, setLoading] = useState(false);
  const [teacher, setTeacher] = useState(null);
  const [options, setOptions] = useState([]);
  const dialogRef = useRef(null);

  useEffect(() => {
    if (!dialogRef?.current) return;

    if (open) dialogRef.current.showModal();
    else dialogRef.current.close();
  }, [open]);

  useEffect(() => {
    if (!nameOrEmail) return;

    api
      .get(`/users/admin/teachers_by_name?name_or_email=${nameOrEmail}`)
      .then((response) => {
        setOptions(
          transformArrayIntoSelectOptions(
            response.data.filter((r) => r.id !== currentTeacherId),
            (u) => u.full_name,
            (u) => u.id
          )
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  }, [nameOrEmail, api, currentTeacherId]);

  const appendStudent = () => {
    api
      .put("/users/admin/change_student_teacher", {
        teacher_student_id: teacherStudentId,
        new_student_user_id: studentId,
        new_teacher_user_id: teacher,
      })
      .then(() => {
        showNotification("success", "Boa!", "Professor alterado com sucesso!");
        setOpen(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
      });
  };

  return (
    <S.Dialog ref={dialogRef}>
      <h1>Escreva um nome ou e-mail para buscar um novo professor</h1>
      <Form onSubmit={appendStudent}>
        <Select
          isLoading={loading}
          label="Nome Ou Email"
          name="student"
          onChange={(v) => setTeacher(v.value)}
          onInputChange={(e) => setNameOrEmail(e)}
          options={options}
        />
        <Button type="submit">Alterar Professor</Button>
        <Button type="button" onClick={() => setOpen(false)}>
          Cancelar
        </Button>
      </Form>
    </S.Dialog>
  );
};

export default ReplaceTeacher;
