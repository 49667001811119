import React from "react";

const FilledHome = ({ color }) => {
  return (
    <svg
      width="27"
      height="23"
      viewBox="0 0 27 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8334 22.6667V14.6667H16.1667V22.6667H22.8334V12H26.8334L13.5 0L0.166687 12H4.16669V22.6667H10.8334Z"
        fill={color}
      />
    </svg>
  );
};
export default FilledHome;
