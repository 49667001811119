import "twin.macro";
import * as S from "./styles";
import { useState } from "react";
import UseServices from "hooks/UseServices";
import SectionLoading from "Components/Global/SectionLoading";
import Image from "./Components/Image";
import Security from "./Components/Security";
import Personal from "./Components/Personal";

const Profile = () => {
  const { profile, loadingProfile } = UseServices();
  const [loading, setLoading] = useState(false);

  const isLoading = loadingProfile || loading;

  return (
    <S.Content>
      {loading && <SectionLoading />}
      <S.ProfileWrapper>
        {!isLoading && <Image data={profile} setLoading={setLoading} />}
        {!isLoading && <Personal data={profile} setLoading={setLoading} />}
        {!isLoading && <Security data={profile} setLoading={setLoading} />}
      </S.ProfileWrapper>
    </S.Content>
  );
};

export default Profile;
