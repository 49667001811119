import { showNotification } from "./notificationHelper";

const logout = () => {
  window.location.reload();
};

const getErrorMessage = (error) => {
  if (error.status === 404) return "Não encontrado";

  return "Tente novamente mais tarde";
};

export const HTTPErrorCallback = (errorReponse, onRemove) => {
  const error = errorReponse.response;

  if (error?.data) {
    showNotification(
      "danger",
      "Atenção",
      error.data.error || getErrorMessage(error),
      () => {
        onRemove && onRemove();
        error.data.disconnect_user && logout();
      }
    );
  } else {
    showNotification("danger", "Atenção", "Tente novamente mais tarde");
  }
};
