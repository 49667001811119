import { css } from "@emotion/react";
import tw, { styled } from "twin.macro";

export const Text = styled.span``;
export const Wrapper = styled.button`
  ${tw`w-full bg-white pl-6 text-left`}
  ${css`
  @media screen and (max-width: 670px) {
      padding-right: 20px;
    }
  `}
`;

export const ButtonTitle = styled.div`
  ${tw`p-4 px-8 text-left w-full whitespace-nowrap`}
  ${css``}
  ${({ opened }) =>
    opened &&
    css`
      display: none;

      @media screen and (max-width: 670px) {
        display: block;
      }
    `}
`;
export const ButtonGroup = styled.div`
  ${tw` `}
  ${css``}
  ${({ opened }) =>
    opened &&
    tw`

    `}
`;

export const ButtonIcon = styled.div`
  ${tw`grid place-items-center rounded-full`}
  ${css`
    width: 50px;
    aspect-ratio: 1;
  `}
  ${({ opened }) =>
    opened &&
    tw`

    `}
`;
export const ButtonInner = styled.div`
  ${tw` hover:(bg-lightGray text-primary) rounded-bl-2xl font-bold rounded-tl-2xl grid place-items-center pl-4 text-left`}
  ${css`
    grid-template-columns: 30px 1fr;

    :hover {
      svg path {
        fill: rgb(0, 182, 144);
      }
    }

    @media screen and (max-width: 670px) {
      border-radius: 6px;

    }
  `}
  ${({ active }) =>
    active &&
    tw`bg-primary/20 text-black  border-r-4 border-primary hover:(bg-primary/40 text-black)`}
`;
export const Button = styled.div`
  ${tw` `}
  ${css``}
  ${({ opened }) =>
    opened &&
    tw`

    `}
`;
