import React from "react";

const Actions = ({ color }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.44444 0C1.08778 0 0 1.08778 0 2.44444V19.5556C0 20.9122 1.08778 22 2.44444 22H19.5556C20.9122 22 22 20.9122 22 19.5556V2.44444C22 1.08778 20.9122 0 19.5556 0H2.44444ZM2.44444 2.44444H19.5556V19.5556H2.44444V2.44444ZM4.88889 4.88889V7.33333H17.1111V4.88889H4.88889ZM4.88889 9.77778V12.2222H17.1111V9.77778H4.88889ZM4.88889 14.6667V17.1111H13.4444V14.6667H4.88889Z"
        fill={color}
      />
    </svg>
  );
};
export default Actions;
