import CommonLogin from "Pages/Common/Login";
import AdminLogin from "Pages/Admin/Login";

const routes = {
  CommonLogin: {
    path: "/",
    exact: true,
    component: CommonLogin,
  },
  AdminLogin: {
    path: "/admin",
    exact: true,
    component: AdminLogin,
  },
};

export default routes;
