import React from "react";

const Exercise = ({ color }) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.2 6.71675H20.8V19.7167H18.2V13.8667H7.8V19.7167H5.2V6.71675H7.8V12.5667H18.2V6.71675ZM1.3 12.5667V8.66675H3.9V17.7667H1.3V13.8667H0V12.5667H1.3ZM26 13.8667H24.7V17.7667H22.1V8.66675H24.7V12.5667H26V13.8667Z"
        fill={color}
      />
    </svg>
  );
};
export default Exercise;
