import * as S from "./styles";

const FormWrapper = ({
  rows,
  columns,
  center,
  placeItems,
  children,
  maxWidth,
}) => {
  return (
    <S.Wrapper
      rows={rows}
      columns={columns}
      center={center}
      placeItems={placeItems}
      maxWidth={maxWidth}
    >
      {children}
    </S.Wrapper>
  );
};

export default FormWrapper;
