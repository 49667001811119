import { useEffect } from "react";
import { useHistory } from "react-router";
import commonRoutes from "Routes/Components/Common/routes";
import "twin.macro";
import UseServices from "hooks/UseServices";
import StudentHome from "./StudentHome";
import Students from "Pages/Common/Students";

const Home = () => {
  const { parsedJWT } = UseServices();
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("userJWTToken"))
      history.push(commonRoutes.Home.path);

    //eslint-disable-next-line
  }, []);

  const HomeComponent = (userType) => {
    switch (userType) {
      case 2:
      case "2":
        return <Students />;

      case 3:
      case "3":
        return <StudentHome />;

      default:
        return <h1>Carregando...</h1>;
    }
  };

  return HomeComponent(parsedJWT.user_type_id);
};

export default Home;
