import { useEffect, useState } from "react";
import StatusTag from "Components/Admin/StatusTag";
import { HTTPErrorCallback } from "helpers/errorHelper";
import UseServices from "hooks/UseServices";
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from "styles/layouts/admin/wrappers";
import ContainerWithTitle from "Components/Admin/ContainerWithTitle";
import { columns, downloadColumns } from "./columns";
import Table from "Components/Admin/Table";
import { toDateTime } from "helpers/formatHelper";
import Actions from "./Actions";

const Videos = () => {
  const { api } = UseServices();
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getVideos();
    //eslint-disable-next-line
  }, []);

  const getVideos = () => {
    setLoading(true);
    api
      .get(`/videos`)
      .then((response) => {
        setVideos(response.data.videos);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  return (
    <>
      <HeaderWrapper>
        <StatusTag
          icon="camera"
          title="Relatório de vídeos"
          infos={[{ label: "Total de videos", value: videos?.length }]}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <Table
              data={videos}
              columns={[
                ...columns,
                {
                  name: "Ações",
                  cell: (r) => (
                    <Actions
                      setLoading={setLoading}
                      getVideos={getVideos}
                      id={r.id}
                    />
                  ),
                },
              ]}
              loading={loading}
              customHeader
              downloadColumns={downloadColumns}
              dataTableProps={{ pagination: true }}
              fileName={`Vídeos ${toDateTime(new Date())}`}
              refresh={getVideos}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default Videos;
