import NavHeader from "Components/NavHeader";
import Sidebar from "Components/Sidebar";
import { useState } from "react";
import * as S from "./styles.js";

const CommonLayout = ({ children }) => {
  const [openedMenu, setOpenedMenu] = useState(false);

  return (
    <S.CommonLayout opened={openedMenu}>
      <NavHeader setOpenedMenu={setOpenedMenu} openedMenu={openedMenu} />
      <Sidebar opened={openedMenu} setOpenedMenu={setOpenedMenu} />
      {children}
    </S.CommonLayout>
  );
};

export default CommonLayout;
