import { useField } from "@unform/core";
import React, { useEffect, useRef } from "react";
import InputSelect, { components } from "react-select";
import getIcon from "helpers/getIcon";
import * as S from "./styles";

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      Não há opções..
    </components.NoOptionsMessage>
  );
};

const Select = ({
  options,
  alertType,
  alertMessage,
  label,
  large,
  small,
  status,
  placeholder = "",
  gridarea,
  name,
  required,
  ...rest
}) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);
  const mainColor = "#1d9d61";
  const blackColor = "#323133";
  const optionHoverColor = "#FFECC5";
  const borderColor = "#adadad";

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        }
        if (!ref.state.value) {
          return "";
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const customStyles = {
    option: (base, state) => ({
      ...base,
      color: `${blackColor}`,
      backgroundColor:
        (state.isHover || state.isFocused || state.isActive) &&
        `${optionHoverColor}`,
    }),
    control: (base, state) => ({
      ...base,

      boxShadow:
        state.isFocused || (state.isHover && state.isFocused)
          ? `0 0 0 1px ${mainColor}`
          : 0,
      fontSize: 16,
      margin: 0,
      textAlign: "left",

      borderRadius: 8,
      borderColor:
        state.isFocused || (state.isHover && state.isFocused)
          ? mainColor
          : `${borderColor}`,
    }),
  };
  const { Option } = components;
  const IconSelectOption = (props) => (
    <Option {...props}>
      <S.Selector>
        {getIcon("status-" + props.value)}
        {props.label}
      </S.Selector>
    </Option>
  );

  // options: value, label
  return (
    <S.Wrapper
      large={large}
      small={small}
      alertType={alertType}
      gridarea={gridarea}
    >
      {label && (
        <label>
          {label}
          <span>{required && "*"}</span>
        </label>
      )}
      <InputSelect
        ref={selectRef}
        defaultValue={defaultValue}
        styles={customStyles}
        classNamePrefix="custom-select"
        className="Select"
        maxMenuHeight="100px"
        placeholder={placeholder}
        options={options}
        required={required}
        {...rest}
        components={{
          NoOptionsMessage,
          Option: IconSelectOption,
          // SingleValue: IconSelectValue,
        }}
      />
      {alertMessage && (
        <S.Message alertType={alertType}>{alertMessage}</S.Message>
      )}
    </S.Wrapper>
  );
};

export default Select;
