
import { Form } from "@unform/web";
import Input from "Components/Admin/Inputs/Input";
import ContainerWithTitle from "Components/Admin/ContainerWithTitle";
import StatusTag from "Components/Admin/StatusTag";
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from "styles/layouts/admin/wrappers";
import Button from "Components/Admin/Buttons/Button";
import * as S from "./styles.js";
import { useState } from "react";
import UseServices from "hooks/UseServices.js";
import { HTTPErrorCallback } from "helpers/errorHelper.js";
import { showNotification } from "helpers/notificationHelper.js";
import SectionLoading from "Components/Global/SectionLoading/index.js";
import { useEffect } from "react";
import { transformArrayIntoSelectOptions } from "helpers/util.js";
import Select from "Components/Admin/Inputs/Select/index.js";
import Video from "Pages/Admin/Videos/Components/Video/index.js";

const CreateExercise = () => {
  const { api } = UseServices();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    title: "",
    description: "",
    category: "",
    videoId: "",
  });
  const [videos, setVideos] = useState([]);
  const [videoOptions, setVideoOptions] = useState([]);
  const selectedVideo = videos.find((v) => v.id === data.videoId);

  if (selectedVideo) selectedVideo.video = selectedVideo.src;

  useEffect(() => {
    getVideos();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!data.videoId) return;

    const selectedVideo = videos.find((v) => v.id === data.videoId);

    const source = document.getElementById("video_source");
    source.innerHTML = "";
    const newSource = document.createElement("source");
    newSource.src = selectedVideo.src;
    source.appendChild(newSource);
    source.load();
  }, [videos, data]);

  const getVideos = () => {
    setLoading(true);
    api
      .get(`/videos`)
      .then((response) => {
        setVideoOptions(
          transformArrayIntoSelectOptions(
            response.data.videos,
            (v) => v.title,
            (v) => v.id
          )
        );
        setVideos(response.data.videos);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  const create = (submited_data) => {
    setLoading(true);

    api
      .post("/exercises/create", { ...submited_data, ...data })
      .then(() => {
        showNotification(
          "success",
          "Sucesso!",
          "Exercício criado com sucesso!"
        );
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <div>
      {loading && <SectionLoading />}
      <HeaderWrapper>
        <StatusTag icon="menweight" title="Criar Exercício" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <S.Wrapper>
              <Form onSubmit={create}>
                <Input
                  label="Título"
                  name="title"
                  required
                  onChange={(e) => setData({ ...data, title: e.target.value })}
                />
                <Input
                  label="Descrição"
                  name="description"
                  required
                  onChange={(e) =>
                    setData({ ...data, description: e.target.value })
                  }
                />
                <Input
                  label="Categoria"
                  name="category"
                  required
                  onChange={(e) =>
                    setData({ ...data, category: e.target.value })
                  }
                />
                <Select
                  name="videoId"
                  options={videoOptions}
                  label="Selecione um vídeo"
                  placeholder="Selecione um vídeo"
                  onChange={(e) => setData({ ...data, videoId: e.value })}
                />

                <Button type="submit">Salvar</Button>
              </Form>
              <Video {...selectedVideo} thumbnail={selectedVideo?.thumbnail_src} />
            </S.Wrapper>
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </div>
  );
};

export default CreateExercise;
