import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;
  min-height: calc(100vh - 200px);
  form {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const Upload = styled.div`
  input {
    display: none;
  }

  label {
    border-radius: 8px;
    background-color: #1d9d61;
    padding: 6px 16px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    height: 40px;
    cursor: pointer;
  }
`;
