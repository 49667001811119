import React from "react";

const Home = ({ color }) => {
  return (
    <svg
      width="27"
      height="23"
      viewBox="0 0 27 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4997 4.34958L19.958 10.1621V20.25H17.3747V12.5H9.62467V20.25H7.04134V10.1621L13.4997 4.34958ZM13.4997 0.875L0.583008 12.5H4.45801V22.8333H12.208V15.0833H14.7913V22.8333H22.5413V12.5H26.4163L13.4997 0.875Z"
        fill={color}
      />
    </svg>
  );
};
export default Home;
