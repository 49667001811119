import getIcon from "helpers/getIcon";

const { default: Button } = require("Components/Admin/Buttons/Button");
const { HTTPErrorCallback } = require("helpers/errorHelper");
const { default: UseServices } = require("hooks/UseServices");

const Actions = ({ getFeedbacks, setSelectedFeedback, setLoading, id, row }) => {
  const { api } = UseServices();

  const remove = (feedbackId) => {
    setLoading(true);
    api
      .delete(`/feedbacks/${feedbackId}`)
      .then(() => {
        getFeedbacks();
      })
      .catch((error) => {
        HTTPErrorCallback(error);
      });
  };

  return (
    <>
      <Button onClick={() => remove(id)} model="other">
        {getIcon("trash")}
      </Button>
      <Button
        title={"Editar"}
        type="button"
        model="other"
        onClick={() => setSelectedFeedback(row)}
      >
        {getIcon("edit")}
      </Button>
    </>
  );
};

export default Actions;
