import React from "react";

const Grid = ({ color }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 11.1667H10.6667V0.5H0M0 24.5H10.6667V13.8333H0M13.3333 24.5H24V13.8333H13.3333M13.3333 0.5V11.1667H24V0.5"
        fill={color}
      />
    </svg>
  );
};
export default Grid;
