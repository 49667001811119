import { useEffect, useState } from "react";
import StatusTag from "Components/Admin/StatusTag";
import { HTTPErrorCallback } from "helpers/errorHelper";
import UseServices from "hooks/UseServices";
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from "styles/layouts/admin/wrappers";
import ContainerWithTitle from "Components/Admin/ContainerWithTitle";
import { columns, downloadColumns } from "./columns";
import Table from "Components/Admin/Table";
import { toDateTime } from "helpers/formatHelper";
import Actions from "./Components/Actions";
import UpdateUserForm from "./Components/UpdateForm";

const Users = () => {
  const { api } = UseServices();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    getUsers();
    //eslint-disable-next-line
  }, []);

  const getUsers = () => {
    setLoading(true);
    api
      .get(`/users/admin`)
      .then((response) => {
        setUsers(response.data.users);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  const Content = () => {
    if (selectedUser)
      return (
        <UpdateUserForm
          refresh={getUsers}
          selecetedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      );
    else
      return (
        <Table
          data={users}
          columns={[
            ...columns,
            {
              name: "Ações",
              cell: (r) => (
                <Actions
                  setLoading={setLoading}
                  getUsers={getUsers}
                  setSelectedUser={setSelectedUser}
                  row={r}
                />
              ),
            },
          ]}
          loading={loading}
          customHeader
          downloadColumns={downloadColumns}
          dataTableProps={{ pagination: true }}
          fileName={`Usuários ${toDateTime(new Date())}`}
          refresh={getUsers}
        />
      );
  };

  return (
    <>
      <HeaderWrapper>
        <StatusTag
          icon="user"
          title="Relatório de usuários"
          infos={[{ label: "Total de usuários", value: users?.length }]}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>{Content()}</ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default Users;
