import { useContext } from "react";
import { ServicesContext } from "contexts/ServicesContext";

const UseServices = () => {
  const { api, socket, parsedJWT, profile, getProfile, loadingProfile } =
    useContext(ServicesContext);

  return {
    api,
    socket,
    parsedJWT,
    profile,
    getProfile,
    loadingProfile,
  };
};

export default UseServices;
