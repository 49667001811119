import styled from "styled-components/macro";

export const NoDataMessage = styled.div`
  padding: 40px 10px;
  display: grid;
  grid-template-rows: 1fr;

  place-items: center;
  position: relative;

  svg {
    width: 50px;
    height: 50px;
    position: relative;
    opacity: 0.9;
  }
`;
