import getIcon from "helpers/getIcon";
import * as S from "./styles";
import Button from "Components/Admin/Buttons/Button";
import { useState } from "react";
import { useEffect } from "react";
import UseServices from "hooks/UseServices";
import { HTTPErrorCallback } from "helpers/errorHelper";

const GenerateThumbnail = ({ video, submit, close, data }) => {
  const [startAt, setStartAt] = useState(0);
  const [duration, setDuration] = useState(0);
  const [thumbnail, setThumbnail] = useState(null);
  const [loading, setLoading] = useState(false);
  const { api } = UseServices();

  useEffect(() => {
    const source = document.getElementById("generate-thumbnail-source");
    source.innerHTML = "";
    const newSource = document.createElement("source");
    newSource.src = URL.createObjectURL(video);

    source.appendChild(newSource);
    source.load();
  }, [video]);

  const generate = () => {
    setThumbnail(null);
    const formData = new FormData();
    formData.append("video", video);
    formData.append("start_at", startAt);
    formData.append("duration_in_seconds", duration);

    setLoading(true);
    api
      .post("/videos/generate-thumbnail", formData)
      .then((response) => {
        setThumbnail(response.data.thumbnail);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <S.Background>
      <S.Wrapper>
        <S.Header>
          <h3>Você não selecionou uma Thumbnail</h3>
          <p>
            Geraremos uma thumbnail personalizada a partir do seu vídeo no
            formato de GIF, selecione abaixo o segundo do vídeo que a thumbnail
            começara e quantos segundos ela terá.
          </p>
          <S.Close onClick={close}>{getIcon("close", "#1d9d61")}</S.Close>
        </S.Header>

        <S.Body>
          <S.Inputs>
            <S.Input>
              <label>Segundo inicial:</label>
              <input
                type="number"
                min="0"
                placeholder="Segundo inicial"
                onChange={(e) => setStartAt(e.target.value)}
              />
            </S.Input>
            <S.Input>
              <label>Duração:</label>
              <input
                type="number"
                min="0"
                placeholder="Duração"
                onChange={(e) => setDuration(e.target.value)}
              />
            </S.Input>
            <Button type="button" onClick={generate} disabled={loading}>
              Gerar thumbnail
            </Button>
            {thumbnail && (
              <>
                <img src={thumbnail} alt="generated-thumbnail-gif" />
                <Button
                  type="button"
                  onClick={() =>
                    submit({ ...data, thumbnail_src: thumbnail, video })
                  }
                  disabled={loading}
                >
                  Salvar
                </Button>
              </>
            )}
          </S.Inputs>
          <video controls id="generate-thumbnail-source">
            {/* <source src={video}   /> */}
          </video>
        </S.Body>
      </S.Wrapper>
    </S.Background>
  );
};

export default GenerateThumbnail;
