import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    primary: "#FFFDF9",
    secondary: "#06B49A",
    dark: "#AFDBD2",
    light: "#36313D",
  },
  fonts: ["sans-serif", "Roboto"],
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em",
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
