import styled from "styled-components/macro";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: ${(props) => props.maxWidth};
  width: 100%;
  border: none;
  background-color: transparent;
  grid-area: ${(props) => props.gridarea};
  label {
    color: #323133;
    font-size: 12px;
    span {
      color: #dc4242;
    }
  }
  .css-2b097c-container {
    width: 100%;
    margin: 0;
  }

  .styled__control-is-focused {
    box-shadow: 0 0 0 1px #1d9d61 !important;
  }
  .css-1rhbuit-multiValue {
    background-color: #1d9d61;
  }

  .css-xb97g8 {
    background-color: #1d9d61;
    position: relative;

    &:hover {
      background-color: #ffc44c;
      svg {
        fill: #323133;
      }
    }
  }
  .css-1tobflw-control:hover,
  .css-fym7d8-control:hover {
    border-color: #1d9d61 !important;
  }
  .css-g1d714-ValueContainer {
    max-height: 80px;
    overflow-y: auto;
  }
  input {
    color: #323133;
    background-color: #fafafa;
    border: 1px solid #adadad;
    outline: none;
    padding: 8px 16px;
    border-radius: 8px;
    line-height: 24px;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    overflow-x: scroll;

    ${(props) => {
      if (props.large)
        return `
       padding: 10px 18px;
       font-size: 16px;
    line-height: 28px;

    `;
      else if (props.small)
        return `
        padding: 6px 14px;
        font-size: 12px;
        line-height: 20px;
    `;
    }}

    ${(props) => {
      switch (props.alertType) {
        case "warning":
          return `
        border-color: #FFC64C;
        
        `;

        case "danger":
          return `         
          border-color: #DC4242;
          `;

        case "success":
          return `
            border-color: #36B550;
                `;
        case "info":
          return `
            border-color: #3FBCD1;
                `;

        default:
          return `


                `;
      }
    }}
  }
`;
export const Hide = styled.button`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
export const Inner = styled.div`
  position: relative;
`;
export const Message = styled.div`
  font-size: 10px;
  line-height: 15px;
  margin: 6px 8px;
  ${(props) => {
    switch (props.alertType) {
      case "warning":
        return `
        color: #FFC64C;
        
        `;

      case "danger":
        return `         
          color: #DC4242;
          `;

      case "success":
        return `
            color: #36B550;
                `;
      case "info":
        return `
            color: #3FBCD1;
                `;

      default:
        return `


                `;
    }
  }}
`;
export const CharLimit = styled.div`
  position: absolute;
  right: 5px;
  bottom: -6px;
  padding: 2px 6px;
  background-color: #99b5bf;
  color: #556b73;
  border-radius: 4px;
  font-weight: 600;
  font-size: 9px;
  line-height: 15px;
  transform: translateY(100%);
`;

export const Selector = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  place-items: center start;

  svg {
    width: 19px;
  }
`;
