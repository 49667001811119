import * as S from "./styles";
import { useState } from "react";

const Workout = ({ workout }) => {
  const [openVideo, setOpenVideo] = useState(null);
  return (
    <>
      {openVideo && (
        <S.Background onClick={() => setOpenVideo(null)}>
          <S.Video controls poster={openVideo.thumbnail_src}>
            <source src={openVideo.src} />
          </S.Video>
        </S.Background>
      )}

      <S.Body>
        {workout.sequences.map((sequence) => (
          <S.Sequence>
            <S.SequenceName>{sequence.title}</S.SequenceName>
            <S.SequenceHeader>
              <p>Tempo de descanço</p>
              <p>{sequence.break_time}</p>
            </S.SequenceHeader>
            {sequence.exercise_workouts.map((exercise_workout) => (
              <>
                <S.VideoWrapper>
                  <S.Thumbnail
                    src={exercise_workout.exercise?.video?.thumbnail_src}
                  ></S.Thumbnail>
                  <S.Details
                    onClick={() =>
                      setOpenVideo(exercise_workout.exercise.video)
                    }
                  >
                    <S.Title>{exercise_workout.exercise.title}</S.Title>
                    <S.Repetitions>
                      {exercise_workout.series} x {exercise_workout.repetitions}{" "}
                      {exercise_workout.weight
                        ? `/ ${exercise_workout.weight}`
                        : ""}
                    </S.Repetitions>
                    <S.BreakTime>
                      {exercise_workout.break_time
                        ? exercise_workout.break_time
                        : "--"}
                    </S.BreakTime>
                  </S.Details>
                </S.VideoWrapper>
              </>
            ))}
          </S.Sequence>
        ))}
      </S.Body>
    </>
  );
};

export default Workout;
