import styled, { css } from "styled-components/macro";
export const Wrapper = styled.button`
  /* ESTILO GLOBAL */
  position: relative;
  margin-top: ${(props) => props.mt};

  width: ${(props) => props.width};

  font-size: 14px;
  grid-area: ${(props) => props.gridarea};
  font-weight: bold;
  height: 40px;
  max-height: 40px;

  text-transform: uppercase;

  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); */
  color: #fff;

  transition: ease 0.3s all;
  cursor: pointer;

  border: none;
  border-radius: 4px;

  /* MODELOS - default, secondary outline, other  */
  &:focus {
    box-shadow: 0px 0px 0 2px #323133;
    outline: none;
  }

  ${(props) => {
    switch (props.model) {
      case "outline":
        return css`
          color: #fff;
          background-color: #ffffff00 !important;
          &::before {
            top: 0;
            left: 0;
            border-radius: 8px;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            color: #1d9d61;
            border-color: #1d9d61;
            border: 3px solid;
          }
        `;

      case "seccondary":
        return css`
          border-color: #0069b4;
          background-color: #0069b4 !important;
        `;
      case "other":
        return `
                `;

      default:
        return css`
          background-color: #1d9d61 !important;
          border-color: #1d9d61 !important;
        `;
    }
  }}

  /* ESTILOS EM HOVER */

  &:hover, &:focus, &:focus-within {
    z-index: 1;
    ${(props) => {
      switch (props.model) {
        case "outline":
          return `
          color: #FFF;
          background-color: #1d9d61;
          border-color: #1d9d61;
          &::before {
         
            border-color: #1d9d61;
       
        }
                `;

        case "other":
          return `
                `;

        default:
          return `
          background-color: #1d9d61;
          border-color: #1d9d61;

                `;
      }
    }}
  }

  /* DISABLED */
  ${(props) => {
    if (props.disabled)
      return `
        opacity: 0.5;
        pointer-events:none;
    `;
  }}

  /* LAYOUTS */
  ${(props) => {
    if (props.dropdown)
      return `
        display: grid;
        grid-template-columns: auto 44px;    
        place-items: center; 
     

    `;
  }}

  @media (max-width: 768px) {
    width: 100%;
    grid-template-columns: 1fr 1fr;
    font-size: 10px;
  }
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ small }) => (small ? "6px 14px" : "6px 16px")};

  svg {
    width: 18px;
  }
  ${(props) => {
    if (props.leftIcon)
      return `
        display: grid;
        grid-template-columns: 28px auto;    
        grid-gap: 10px;
        place-items: center; 

    `;
    else if (props.rightIcon)
      return `
        display: grid;
        grid-template-columns: auto 28px;    
        grid-gap: 10px;
        place-items: center; 

    `;
  }}
`;
export const Dropdown = styled.button`
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
  border: none;
  background-color: transparent;
  border-left: 2px solid #323133;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: ease 0.3s all;
  animation: FadeInAnimation 1s linear 2s infinite alternate;
  width: 40px;

  /* ACTIVE */

  ${(props) => {
    if (props.active)
      return `
      background-color: #7B8BBA;
    `;
  }}

  /* HOVER */
  &:hover, &:focus {
    ${(props) => {
      switch (props.model) {
        case "outline":
          return `
        color: #FFF;
        &::before {
            top: 0;
            left: 0;
            border-radius: 8px;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            color: #FFF;
            border-color: #1d9d61;
            border: 3px solid;
        }
                `;

        case "seccondary":
          return `
          border-color: #0069B4;
          background-color: #0069B4;
                `;
        case "other":
          return `
                `;

        default:
          return `
        background-color: #1d9d61;
        border-color: #1d9d61;

                `;
      }
    }}
  }
`;
export const DropdownUl = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 70px;
  position: absolute;
  right: 0;
  z-index: 1;
  bottom: -5px;
  transform: translateY(100%);
  border-radius: 10px;
  border: 2px solid #b3b3b3;
`;

export const DropdownItem = styled.button`
  padding: 13px 20px;
  border: none;
  background-color: white;

  cursor: pointer;

  transition: ease 0.3s all;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  width: 100%;
  text-align: left;

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
export const Icon = styled.div`
  display: grid;
  place-items: center;
  height: 28px;
  width: 100%;
  border: none;

  background-color: transparent;
`;
