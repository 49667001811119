import tw, { styled } from "twin.macro";

export const VideosWrapper = styled.div`
  ${tw`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4`}
`;

export const Wrapper = styled.div`
  /* ${tw`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4`} */
`;

export const Searchbar = styled.input`
  ${tw`border rounded-lg py-2 px-4 focus:outline-none focus:border-primary`}
  margin: 1rem auto 0.5rem 1rem;
  width: 30%;

  @media (max-width: 767px) {
    width: 100%;
  }
`;
export const VideoWrapper = styled.div`
  ${tw`bg-white rounded-lg shadow-md overflow-hidden flex flex-col justify-center`}
  /* min-height: 100%; */

  h3 {
    ${tw`text-sm p-4 `}
  }
`;
export const Video = styled.video`
  ${tw`bg-lightGray rounded-lg p-4 flex flex-col items-center justify-center text-center`}
`;

export const PaginationWrapper = styled.div`
  ${tw`flex justify-center items-center mt-4 items-center`}
  
`;

export const PageButton = styled.button`
  ${tw`bg-primary text-white hover:bg-primary/90 rounded-full px-3 py-2 mx-2`}
`;

export const CurrentPageIndicator = styled.span`
  ${tw`text-black mx-2`}
`;
