import React from "react";

const CreateExercise = ({ color }) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.2 8.66675H20.8V21.6667H18.2V15.8167H7.8V21.6667H5.2V8.66675H7.8V14.5167H18.2V8.66675ZM1.3 14.5167V10.6167H3.9V19.7167H1.3V15.8167H0V14.5167H1.3ZM26 15.8167H24.7V19.7167H22.1V10.6167H24.7V14.5167H26V15.8167Z"
        fill={color}
      />
      <path
        d="M16.2503 4.33333H13.0003V7.58333H11.917V4.33333H8.66699V3.25H11.917V0H13.0003V3.25H16.2503V4.33333Z"
        fill={color}
      />
    </svg>
  );
};
export default CreateExercise;
