import Button from "Components/Admin/Buttons/Button";
import Input from "Components/Admin/Inputs/Input";
import Select from "Components/Admin/Inputs/Select";
import { transformArrayIntoSelectOptions } from "helpers/util";
import * as S from "./styles.js";

const Sequence = ({
  changeSequenceValue,
  setExerciseToSequence,
  changeExerciseIntoSequence,
  removeExerciseFromSequence,
  addExerciseToSequence,
  removeSequence,
  index,
  sequence,
  exercises,
}) => {
  return (
    <S.SequenceWrapper>
      <S.SequenceDetails>
        <Input
          label="Nome"
          placeholder="Nome"
          name={`title_${sequence.id}`}
          key={`title_${sequence.id}`}
          value={sequence.title}
          onChange={(e) => changeSequenceValue("title", index, e.target.value)}
        />
        <Input
          label="Tempo de descanço"
          placeholder="Tempo de descanço"
          name={`break_time_${sequence.id}`}
          key={`break_time_${sequence.id}`}
          value={sequence.break_time}
          onChange={(e) =>
            changeSequenceValue("break_time", index, e.target.value)
          }
        />
        <Button type="button" onClick={() => removeSequence(index)}>
          Remover Sequencia
        </Button>
      </S.SequenceDetails>
      <S.SequenceExercises>
        {sequence.exercises?.map((e, ie) => (
          <S.ExerciseWrapper>
            <Select
              value={
                transformArrayIntoSelectOptions(
                  //eslint-disable-next-line
                  exercises.filter((se) => se.id == e.id),
                  (e) => e.title,
                  (e) => e.id
                )[0]
              }
              options={transformArrayIntoSelectOptions(
                exercises,
                (e) => e.title,
                (e) => e.id
              )}
              onChange={(e) => setExerciseToSequence(index, ie, e.value)}
              label="Exercício"
              name={`exercise_${sequence.id}_${e.fake_id}`}
            />
            <Input
              placeholder="Peso"
              label="Peso"
              name={`weight-${e.fake_id}`}
              value={e.weight}
              onChange={(e) =>
                changeExerciseIntoSequence("weight", index, ie, e.target.value)
              }
            />
            <Input
              placeholder="Series"
              label="Series"
              name={`series-${e.fake_id}`}
              value={e.series}
              onChange={(e) =>
                changeExerciseIntoSequence("series", index, ie, e.target.value)
              }
            />
            <Input
              placeholder="Repetições"
              label="Repetições"
              name={`repetitions-${e.fake_id}`}
              value={e.repetitions}
              onChange={(e) =>
                changeExerciseIntoSequence(
                  "repetitions",
                  index,
                  ie,
                  e.target.value
                )
              }
            />
            <Input
              placeholder="Observação"
              label="Observação"
              name={`break_time-${e.fake_id}`}
              value={e.break_time}
              onChange={(e) =>
                changeExerciseIntoSequence(
                  "break_time",
                  index,
                  ie,
                  e.target.value
                )
              }
            />
            <Button
              type="button"
              onClick={() => removeExerciseFromSequence(index, ie)}
            >
              Remover exercício
            </Button>
          </S.ExerciseWrapper>
        ))}
      </S.SequenceExercises>
      <Button type="button" onClick={() => addExerciseToSequence(index)}>
        Adicionar exercício
      </Button>
    </S.SequenceWrapper>
  );
};

export default Sequence;
