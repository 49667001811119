import { Form } from "@unform/web";
import Input from "Components/Admin/Inputs/Input";
import ContainerWithTitle from "Components/Admin/ContainerWithTitle";
import StatusTag from "Components/Admin/StatusTag";
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from "styles/layouts/admin/wrappers";
import Button from "Components/Admin/Buttons/Button";
import * as S from "./styles.js";
import { useEffect, useState } from "react";
import UseServices from "hooks/UseServices.js";
import { HTTPErrorCallback } from "helpers/errorHelper.js";
import { showNotification } from "helpers/notificationHelper.js";
import SectionLoading from "Components/Global/SectionLoading/index.js";
import Table from "Components/Admin/Table/index.js";
import { toDateTime } from "helpers/formatHelper.js";

const CreateFeedback = () => {
  const { api } = UseServices();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);

  useEffect(() => {
    getStudents();
    //eslint-disable-next-line
  }, []);

  const getStudents = async () => {
    try {
      setLoading(true);
      const responses = await Promise.all([api.get(`/users/admin/students`)]);
      const students = responses[0]?.data?.users;

      setStudents(students);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showNotification("danger", "Atenção", "Houve um erro ao buscar alunos");
    }
  };

  const create = (data, { reset }) => {
    if (!file)
      return showNotification("danger", "Atenção", "Selecione um arquivo!");

    setLoading(true);

    setFile(null);
    reset();

    for (const student of selectedStudents) {
      const submited_data = { ...data, file, studentId: student.id };

      const formData = new FormData();
      for (const key of Object.keys(submited_data))
        formData.append(key, submited_data[key]);

      api
        .post("/feedbacks/create", formData)
        .then(() => {
          showNotification(
            "success",
            "Sucesso!",
            `Arquivo criado com sucesso para ${student.full_name}!`
          );
          setLoading(false);
        })
        .catch((error) => {
          HTTPErrorCallback(error);
          setLoading(false);
        });
    }
  };

  const selectFile = (event) => {
    const fileChosed = event?.target?.files[0];

    if (!fileChosed) return;

    setFile(fileChosed);
  };

  return (
    <>
      {loading && <SectionLoading />}
      <HeaderWrapper>
        <StatusTag icon="star" title="Criar Arquivo" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <S.Wrapper>
              <Form onSubmit={create}>
                <Input label="Título" name="title" required />
                <S.Upload>
                  <input
                    type="file"
                    id="feedback_file"
                    onChange={(e) => selectFile(e)}
                  />
                  <label htmlFor="feedback_file">
                    {file?.name || "Selecione um Arquivo"}
                  </label>
                </S.Upload>

                <Button type="submit">Salvar</Button>
              </Form>
            </S.Wrapper>

            <S.Title>Selecione os estudantes</S.Title>
            <Table
              columns={[
                {
                  name: "Nome",
                  selector: (row) => row.full_name,
                  sortable: true,
                },
                {
                  name: "Email",
                  selector: (row) => row.email,
                  sortable: true,
                },
                {
                  name: "Professor",
                  selector: (row) => row.teacher?.full_name,
                  sortable: true,
                },
                {
                  name: "Último Login",
                  selector: (row) =>
                    row.last_login ? toDateTime(row.last_login) : "N/A",
                  sortable: true,
                },
                {
                  name: "Data de registro",
                  selector: (row) =>
                    row.createdAt ? toDateTime(row.createdAt) : "N/A",
                  sortable: true,
                },
                {
                  name: "Ativo",
                  selector: (row) => (row.active ? "Sim" : "Não"),
                  sortable: true,
                },
              ]}
              data={students}
              loading={loading}
              customHeader
              dataTableProps={{
                pagination: true,
                selectableRows: true,
                onSelectedRowsChange: (e) =>
                  setSelectedStudents(e.selectedRows),
              }}
              fileName={`Estudantes ${toDateTime(new Date())}`}
              refresh={getStudents}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default CreateFeedback;
