import React from "react";

const List = ({ color }) => {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.833496H5.33333V6.16683H0V0.833496ZM8 2.16683V4.8335H24V2.16683H8ZM0 8.8335H5.33333V14.1668H0V8.8335ZM8 10.1668V12.8335H24V10.1668H8ZM0 16.8335H5.33333V22.1668H0V16.8335ZM8 18.1668V20.8335H24V18.1668H8Z"
        fill={color}
      />
    </svg>
  );
};
export default List;
