import React from "react";

const Dashboard = ({ color }) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 2V4H12.5V2H16.5ZM6.5 2V8H2.5V2H6.5ZM16.5 10V16H12.5V10H16.5ZM6.5 14V16H2.5V14H6.5ZM18.5 0H10.5V6H18.5V0ZM8.5 0H0.5V10H8.5V0ZM18.5 8H10.5V18H18.5V8ZM8.5 12H0.5V18H8.5V12Z"
        fill={color}
      />
    </svg>
  );
};
export default Dashboard;
