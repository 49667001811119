import { css } from "@emotion/react";
import tw, { styled } from "twin.macro";

export const Wrapper = styled.form`
  ${tw`grid gap-4 p-6 text-center`}
  ${css`
    h1 {
      font-size: 2rem;
      text-align: center;
      font-weight: bold;
    }
  `}
`;
