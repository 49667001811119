import styled from "styled-components/macro";

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;

  button:first-child {
    margin-right: auto;
  }

  & ~ div {
      margin: 0 auto;
      
      form {
        max-width: 400px !important;
        gap: 20px;
    }
  }
`;

export const Students = styled.section`
    margin-top: 50px !important;
    text-align: center;
    font-weight: 800;
    font-size: 18px;
`
