import * as S from "./styles.js";

const Video = ({ video, thumbnail, title, description }) => {
  return (
    <S.VideoWrapper>
        <S.Video controls id='video_source' poster={thumbnail}>
          <source src={video} />
        </S.Video>
      <S.Details>
        <p>{title}</p>
        <small>{description}</small>
      </S.Details>
    </S.VideoWrapper>
  );
};

export default Video;
