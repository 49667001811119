import React from "react";

const Hamburguer = ({ color }) => {
  return (
    <svg
      width="25"
      height="17"
      viewBox="0 0 25 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.34082 0.5H24.3408V3.16667H0.34082V0.5ZM0.34082 7.16667H24.3408V9.83333H0.34082V7.16667ZM0.34082 13.8333H24.3408V16.5H0.34082V13.8333Z"
        fill={color}
      />
    </svg>
  );
};
export default Hamburguer;
