import { useTable } from "react-table";
import * as S from "./styles";

const Table = ({ columns, data, onRowClick = () => { } }) => {
  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <S.Wrapper>
      <S.Table {...getTableProps()}>
        <S.Thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <S.ThHead {...column.getHeaderProps()}>
                  {column.render("Header")}
                </S.ThHead>
              ))}
            </tr>
          ))}
        </S.Thead>
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <S.TrPair {...row.getRowProps()} onClick={() => onRowClick(row)}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <S.Td {...cell.getCellProps()}>
                          {
                            // Render the cell contents
                            cell.render("Cell")
                          }
                        </S.Td>
                      );
                    })
                  }
                </S.TrPair>
              );
            })
          }
        </tbody>
      </S.Table>
    </S.Wrapper>
  );
};

export default Table;
