import * as S from "../../styles";

const Info = ({ number, labelComponent }) => {
  return (
    <S.Info>
      <span> {number}</span>
      <label>{labelComponent}</label>
    </S.Info>
  );
};

export default Info;
