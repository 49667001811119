import { css } from "@emotion/react";
import tw, { styled } from "twin.macro";

export const Text = styled.span``;
export const Wrapper = styled.button`
  ${tw`w-full p-3.5 bg-gradient-to-r font-bold from-secondary to-primary rounded-full h-full grid text-white drop-shadow cursor-pointer`}
  ${({ icon }) =>
    icon &&
    css`
      grid-template-columns: auto 1fr;
      grid-gap: 10px;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `}

  ${({ outline }) =>
    outline &&
    css`
      background-color: transparent;
      background-image: none;
      border: 2px solid #0069B4;
      color: #0069B4;
    `}
`;

export const Icon = styled.div`
  ${tw`w-6 h-6 bg-black`}
`;
