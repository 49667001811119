import { Form } from "@unform/web";
import media from "styled-media-query";

import styled from "styled-components";

export const SequenceWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  /* border: 1px solid black; */
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(65, 64, 66, 0.25);

  ${media.lessThan("768px")`
    max-width: unset;
    width: 100%;
  `}
`;

export const SequenceDetails = styled.div`
  display: grid;
  grid-template-columns: 280px 280px 200px;
  align-items: center;
  grid-gap: 10px;

  button {
    margin-top: auto;
  }

  ${media.lessThan("768px")`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;    
  `}
`;
export const SequenceExercises = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 15px;
  align-items: center;
  gap: 20px;
  width: 100%;

  ${media.lessThan("768px")`

    span,
    p,
    input,
    div,
    .custom-select__single-value {
      font-size: 10px !important;
    }
    
  `}
`;

export const ExerciseWrapper = styled.div`
  max-width: 90%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  place-items: end;
  gap: 10px;
  box-shadow: 0px 0px 5px rgba(65, 64, 66, 0.25);
  padding: 10px;
  border-radius: 16px;
`;
