import React from "react";

const Chat = ({ color }) => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 0.75C6.125 0.75 0.5 5.225 0.5 10.75C0.531386 12.0784 0.855783 13.3834 1.45001 14.5719C2.04424 15.7603 2.89364 16.8029 3.9375 17.625C3.9375 18.375 3.4125 20.3375 0.5 23.25C3.4625 23.1125 6.3 22 8.5875 20.125C10.0125 20.5375 11.5125 20.75 13 20.75C19.875 20.75 25.5 16.275 25.5 10.75C25.5 5.225 19.875 0.75 13 0.75ZM13 18.25C7.475 18.25 3 14.8875 3 10.75C3 6.6125 7.475 3.25 13 3.25C18.525 3.25 23 6.6125 23 10.75C23 14.8875 18.525 18.25 13 18.25ZM19.25 12V9.5H16.75V12H19.25ZM14.25 12V9.5H11.75V12H14.25ZM9.25 12V9.5H6.75V12H9.25Z"
        fill={color}
      />
    </svg>
  );
};
export default Chat;
