import getIcon from "helpers/getIcon";

const { default: Button } = require("Components/Admin/Buttons/Button");
const { HTTPErrorCallback } = require("helpers/errorHelper");
const { default: UseServices } = require("hooks/UseServices");

const Actions = ({ getExercises, setLoading, id, setSelectedExercise }) => {
  const { api } = UseServices();

  const removeExercise = (video_id) => {
    setLoading(true);
    api
      .delete(`/exercises/${video_id}`)
      .then(() => {
        getExercises();
      })
      .catch((error) => {
        HTTPErrorCallback(error);
      });
  };

  return (
    <>
    <Button onClick={() => setSelectedExercise(id)} model="other">
      {getIcon("edit")}
    </Button>
    <Button onClick={() => removeExercise(id)} model="other">
      {getIcon("trash")}
    </Button>
    </>
  );
};

export default Actions;
