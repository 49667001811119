import { toDateTime } from "helpers/formatHelper";

export const columns = [
  {
    name: "Nome",
    selector: (row) => row.full_name,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
  },
  {
    name: "Último Login",
    selector: (row) => (row.last_login ? toDateTime(row.last_login) : "N/A"),
    sortable: true,
  },
  {
    name: "Data de registro",
    selector: (row) => (row.createdAt ? toDateTime(row.createdAt) : "N/A"),
    sortable: true,
  },
  {
    name: "Ativo",
    selector: (row) => (row.active ? "Sim" : "Não"),
    sortable: true,
  },
];

export const downloadColumns = columns;
