import React from "react";
import * as S from "./styles";

function Loading(props) {
  return (
    <S.Circle>
      <S.CircularLoader className="circular-loader" viewBox="25 25 50 50">
        <circle
          className="loader-path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke={"#06B49A"}
          strokeWidth="3"
        />
      </S.CircularLoader>
      <svg className="circular-bg" viewBox="25 25 50 50">
        <circle
          className="loader-path2"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke="#00000020"
          strokeWidth="3"
        />
      </svg>
    </S.Circle>
  );
}

export default Loading;
