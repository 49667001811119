import React from "react";

const Star = ({ color }) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1245:8878)">
        <path
          d="M10.4588 0.985859L11.7279 0.175167C12.0839 -0.050596 12.5585 0.0725472 12.7494 0.447108L13.4407 1.78116C13.6213 2.13006 13.9927 2.34043 14.3848 2.32504L15.8913 2.25321C16.3143 2.23268 16.66 2.57646 16.6394 2.9972L16.5671 4.49544C16.5465 4.89053 16.7632 5.25482 17.114 5.43441L18.4553 6.12196C18.832 6.31693 18.9558 6.78385 18.7288 7.13789L17.9136 8.40011C17.7021 8.73362 17.7021 9.15436 17.9136 9.48787L18.7288 10.7501C18.9558 11.1041 18.832 11.5762 18.4553 11.766L17.114 12.4536C16.7632 12.6332 16.5516 13.0026 16.5671 13.3925L16.6394 14.8908C16.66 15.3115 16.3143 15.6553 15.8913 15.6348L14.3848 15.5629C13.9876 15.5424 13.6213 15.7579 13.4407 16.1068L12.7494 17.4409C12.5534 17.8154 12.0839 17.9386 11.7279 17.7128L10.4588 16.9021C10.1234 16.6917 9.70038 16.6917 9.36504 16.9021L8.09591 17.7128C7.73993 17.9386 7.26529 17.8154 7.07441 17.4409L6.38309 16.1068C6.20252 15.7579 5.83107 15.5475 5.43898 15.5629L3.92221 15.6604C3.49916 15.6809 3.1535 15.3372 3.17414 14.9164L3.24637 13.4182C3.267 13.0231 3.05032 12.6588 2.6995 12.4792L1.35814 11.7917C0.981527 11.5967 0.857709 11.1298 1.08471 10.7757L1.89468 9.50326C2.10621 9.16975 2.10621 8.74901 1.89468 8.4155L1.07955 7.15328C0.852549 6.79924 0.976368 6.3272 1.35298 6.13735L2.69434 5.4498C3.04516 5.27022 3.25668 4.90079 3.24121 4.51083L3.17414 3.00233C3.1535 2.58159 3.49916 2.23782 3.92221 2.25834L5.42866 2.33017C5.82591 2.3507 6.1922 2.1352 6.37277 1.78629L7.06409 0.452239C7.26013 0.0776782 7.72961 -0.045465 8.08559 0.180298L9.35472 0.99099C9.69522 1.19623 10.1234 1.19623 10.4588 0.985859Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1245:8878">
          <rect
            width="17.8866"
            height="17.7891"
            fill="white"
            transform="translate(0.966309 0.0625)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Star;
