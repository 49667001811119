import { BrowserRouter, Switch, Route } from "react-router-dom";
import NotFoundComponent from "Pages/Shared/NotFound";
import AdminRouter from "Routes/Components/Admin";
import AdminRoutes from "Routes/Components/Admin/routes";
import CommonRoutes from "Routes/Components/Common/routes";
import CommonRouter from "Routes/Components/Common";
import NoAuthRouter from "Routes/Components/NoAuth";
import NoAuthRoutes from "Routes/Components/NoAuth/routes";
import GlobalRouter from "Routes/Components/GlobalRoutes";
import GlobalRoutes from "Routes/Components/GlobalRoutes/routes";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        {Object.entries(NoAuthRoutes).map(([key, value]) => (
          <NoAuthRouter {...value} />
        ))}

        {Object.entries(CommonRoutes).map(([key, value]) => (
          <CommonRouter {...value} />
        ))}

        {Object.entries(AdminRoutes).map(([key, value]) => (
          <AdminRouter {...value} />
        ))}

        {Object.entries(GlobalRoutes).map(([key, value]) => (
          <GlobalRouter {...value} />
        ))}

        <Route path="*" component={NotFoundComponent} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
