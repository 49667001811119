import styled from "styled-components/macro";

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Infos = styled.p`
  display: flex;
  flex-wrap: wrap;
  gap: 50px 20px;
`;

export const Info = styled.p`
  border-bottom: 1px solid #F7F8F840;

  background: #F7F8F810;
  border-radius: 4px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content;
  place-items: center start;
  grid-gap: 10px;
  margin: 10px 0px;
  padding: 20px 20px;
  label {
    display: grid;
    font-size: 1rem;
    grid-gap: 2px;
    text-transform: uppercase;
    color: #5C5C5C;
    span {
      font-size: 16px;
      color: #323133;
      font-weight: 700;
    }
  }
  span {
    font-size: 3rem;
    font-weight: 700;
  }
  color: black;
`;
