import Loading from "Components/Global/Loading";
import * as S from "./styles";

const SectionLoading = ({ global }) => {
  return (
    <S.LoadingWrapper global={global}>
      <Loading />
    </S.LoadingWrapper>
  );
};

export default SectionLoading;
