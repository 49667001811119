import React from "react";

const LeftArrow = ({ color }) => {
  return (
    <svg
      width="39"
      height="62"
      viewBox="0 0 39 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.6699 7.285L14.9549 31L38.6699 54.715L31.3333 62L0.333256 31L31.3333 0L38.6699 7.285Z"
        fill={color}
      />
    </svg>
  );
};
export default LeftArrow;
