import Button from "Components/Button";
import SectionLoading from "Components/Global/SectionLoading";
import { HTTPErrorCallback } from "helpers/errorHelper";
import UseServices from "hooks/UseServices";
import { useEffect, useState } from "react";
import * as S from "./styles";
import { toDateTime, toDateTimeNoSeconds } from "helpers/formatHelper";
import { ContentInner, ContentWrapper } from "styles/layouts/admin/wrappers";
import ContainerWithTitle from "Components/Admin/ContainerWithTitle";
import { columns } from "./columns";
import Table from "Components/Admin/Table";

const Feedbacks = () => {
  const { api, parsedJWT } = UseServices();
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getFeedbacks();
    //eslint-disable-next-line
  }, []);

  const getFeedbacks = () => {
    setLoading(true);
    api
      .get("/feedbacks/list")
      .then((response) => {
        setFeedbacks(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  return (
    <>
      {loading && <SectionLoading />}

      {parsedJWT.user_type_id === 2 ? (
        <ContentWrapper>
          <ContentInner withDetails>
            <ContainerWithTitle>
              <Table
                data={feedbacks}
                columns={[
                  ...columns,
                  // {
                  //   name: "Ações",
                  //   cell: (r) => (
                  //     <Actions
                  //       setLoading={setLoading}
                  //       getWorkouts={getWorkouts}
                  //       id={r.id}
                  //     />
                  //   ),
                  // },
                ]}
                loading={loading}
                customHeader
                downloadColumns={columns}
                dataTableProps={{ pagination: true }}
                fileName={`Treinos ${toDateTime(new Date())}`}
                refresh={getFeedbacks}
              />
            </ContainerWithTitle>
          </ContentInner>
        </ContentWrapper>
      ) : (
        <S.Wrapper>
          {feedbacks.map((f) => (
            <S.FeedbackWrapper key={f.id}>
              <div>
                <S.Title>{f.title}</S.Title>
                <S.DateText>{toDateTimeNoSeconds(f.createdAt)}</S.DateText>
              </div>
              <Button onClick={() => window.open(f.file_src, "_blank")}>
                {" "}
                Baixar{" "}
              </Button>
            </S.FeedbackWrapper>
          ))}
        </S.Wrapper>
      )}
    </>
  );
};

export default Feedbacks;
