import { toDateTime } from "helpers/formatHelper";

export const columns = [
  {
    name: "Criador",
    selector: (row) => row.creator.full_name,
    sortable: true,
  },
  {
    name: "Título",
    selector: (row) => row.title,
    sortable: true,
  },
  {
    name: "Arquivo",
    selector: (row) => row.file_src,
    cell: (row) => <a href={row.file_src}>Ver Arquivo</a>,
  },
  {
    name: "Estudante",
    selector: (row) => row.student?.full_name || "N/A",
    sortable: true,
  },
  {
    name: "Data de registro",
    selector: (row) => (row.createdAt ? toDateTime(row.createdAt) : "N/A"),
    sortable: true,
  },
];

export const downloadColumns = columns;
