import { store } from "react-notifications-component";

export const showNotification = (
  type = "default",
  title,
  message,
  onRemove,
  duration = 3000
) => {
  /*
    NOTIFICATION TYPES
    -success
    -danger
    -info
    -default
    -warning
  */

  return store.addNotification({
    title,
    message: message || "",
    type: type || "success",
    insert: "right",
    container: "top-left",
    animationIn: ["animate__animated", "animate__fadeInRight"],
    dismiss: {
      duration,
      onScreen: true,
      pauseOnHover: true,
    },
    onRemoval: () => onRemove && onRemove(),
  });
};
