import { useEffect, useState } from "react";
import StatusTag from "Components/Admin/StatusTag";
import { HTTPErrorCallback } from "helpers/errorHelper";
import UseServices from "hooks/UseServices";
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from "styles/layouts/admin/wrappers";
import ContainerWithTitle from "Components/Admin/ContainerWithTitle";
import { columns, downloadColumns } from "./columns";
import Table from "Components/Admin/Table";
import { toDateTime } from "helpers/formatHelper";
import ShowStudent from "./ShowStudent";

const ListStudents = () => {
  const { api } = UseServices();
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStudent, setSelectedStudent] = useState(null)

  useEffect(() => {
    getStudents();
    //eslint-disable-next-line
  }, []);

  const getStudents = () => {
    setLoading(true);
    api
      .get(`/users/admin/students`)
      .then((response) => {
        setStudents(response.data.users);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  console.log(selectedStudent)
  return (
    <>
      <HeaderWrapper>
        <StatusTag
          icon="user"
          title="Relatório de Estudantes"
          infos={[{ label: "Total de Estudantes", value: students?.length }]}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            {selectedStudent ? (
              <ShowStudent id={selectedStudent} setSelectedStudent={setSelectedStudent} />
            ) : (
              <Table
                data={students}
                columns={columns}
                loading={loading}
                customHeader
                downloadColumns={downloadColumns}
                dataTableProps={{ pagination: true, onRowClicked: (r) => setSelectedStudent(r.id) }}
                fileName={`Estudantes ${toDateTime(new Date())}`}
                refresh={getStudents}
              />
            )}
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default ListStudents;
