import tw, { styled, css } from "twin.macro";


export const FileInputWrapper = styled.div`
  ${tw`flex flex-col gap-4`}
  ${css``}
`;

export const InputWrapper = styled.div`
  ${tw`relative inline-block overflow-hidden bg-primary border-2 border-primary rounded-md text-primary transition-colors duration-300 cursor-pointer`}
  ${css``}
`;

export const Label = styled.div`
  ${tw`px-4 py-2 bg-primary text-white rounded-md cursor-pointer transition-colors duration-300 hover:bg-primary focus:bg-primary`}
  ${css``}
`;

export const Input = styled.input`
    ${tw`absolute inset-0 w-full h-full opacity-0 cursor-pointer`}
    ${css``}
`;

export const Form = styled.form`
    ${tw`flex gap-4  flex-col mb-4 p-5`}
    ${css``}
    
    h2 {
      ${tw`text-lg font-bold`}

    }

    small {
      ${tw`text-black`}
      ${css``}
    }
`;
