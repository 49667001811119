import tw, { styled, css } from "twin.macro";

export const Wrapper = styled.div`
    ${tw`relative overflow-x-auto sm:rounded-lg`}
    ${css``}
`

export const Table = styled.table`
    ${tw`w-full text-sm text-left text-black dark:text-black`}
    ${css``}
`

export const Thead = styled.thead`
    ${tw`text-xs text-black uppercase dark:bg-black dark:text-black`}
    ${css``}
`

export const ThHead = styled.th`
    ${tw`px-6 py-3 text-white`}
    ${css``}
`

export const TrPair = styled.tr`
    ${tw`bg-white border-b dark:bg-white dark:border-white`}
    ${css``}
`

export const TrOdd = styled.tr`
    ${tw`border-b bg-lightGray dark:bg-lightGray dark:border-lightGray`}
    ${css``}
`

export const ThBody = styled.th`
    ${tw`px-6 py-4 font-medium text-black whitespace-nowrap dark:text-black`}
    ${css``}
    `

export const Td = styled.td`
    ${tw`px-6 py-4`}
    ${css``}
`
