import React from "react";

const NoCam = ({ color }) => {
  return (
    <svg
      width="53"
      height="50"
      viewBox="0 0 53 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.240234 6.175L3.49023 3L47.2402 46.8L44.0652 50L39.0652 45H7.24023C5.91415 45 4.64238 44.4732 3.7047 43.5355C2.76702 42.5979 2.24023 41.3261 2.24023 40V10C2.24023 9.45 2.34023 8.925 2.49023 8.425L0.240234 6.175ZM14.7402 5L19.7402 0H34.7402L39.7402 5H47.2402C48.5663 5 49.8381 5.52678 50.7758 6.46447C51.7134 7.40215 52.2402 8.67392 52.2402 10V40C52.2402 41.5 51.5902 42.825 50.5402 43.75L38.0652 31.25C39.1402 29.425 39.7402 27.275 39.7402 25C39.7402 21.6848 38.4233 18.5054 36.0791 16.1612C33.7349 13.817 30.5554 12.5 27.2402 12.5C24.9652 12.5 22.8152 13.1 20.9902 14.175L11.7902 5H14.7402ZM14.7402 25C14.7402 28.3152 16.0572 31.4946 18.4014 33.8388C20.7456 36.183 23.925 37.5 27.2402 37.5C28.4902 37.5 29.8152 37.3 30.9902 36.925L26.5402 32.5C24.8006 32.3135 23.1773 31.5372 21.9402 30.3001C20.703 29.0629 19.9267 27.4396 19.7402 25.7L15.3152 21.25C14.9402 22.425 14.7402 23.75 14.7402 25ZM27.2402 17.5C29.2294 17.5 31.137 18.2902 32.5435 19.6967C33.9501 21.1032 34.7402 23.0109 34.7402 25C34.7415 25.8514 34.5978 26.6968 34.3152 27.5L24.7402 17.925C25.5434 17.6425 26.3888 17.4987 27.2402 17.5Z"
        fill={color}
      />
    </svg>
  );
};
export default NoCam;
