import { toDateTime } from "helpers/formatHelper";

export const columns = [
  {
    name: "Título",
    selector: (row) => row.title,
    sortable: true,
  },
  {
    name: "Descrição",
    selector: (row) => row.description,
    sortable: true,
  },
  {
    name: "Categoria",
    selector: (row) => row.category,
    sortable: true,
  },
  {
    name: "Criador",
    selector: (row) => row.user.full_name,
    sortable: true,
  },
  {
    name: "Video",
    selector: (row) => row.video?.src,
    cell: (row) => <a href={row.video?.src}>Ver vídeo</a>,
  },
  {
    name: "Data de registro",
    selector: (row) => (row.createdAt ? toDateTime(row.createdAt) : "N/A"),
    sortable: true,
  },
];

export const downloadColumns = columns;
