import React from "react";

const Instagram = ({ color }) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.50011 6.87579C7.7798 6.87579 6.37589 8.2797 6.37589 10C6.37589 11.7203 7.7798 13.1242 9.50011 13.1242C11.2204 13.1242 12.6243 11.7203 12.6243 10C12.6243 8.2797 11.2204 6.87579 9.50011 6.87579ZM18.8704 10C18.8704 8.70626 18.8821 7.42423 18.8095 6.13283C18.7368 4.63283 18.3946 3.30158 17.2978 2.2047C16.1985 1.10548 14.8696 0.765639 13.3696 0.692982C12.0759 0.620326 10.7939 0.632045 9.50245 0.632045C8.2087 0.632045 6.92667 0.620326 5.63527 0.692982C4.13527 0.765639 2.80402 1.10783 1.70714 2.2047C0.607924 3.30392 0.26808 4.63283 0.195424 6.13283C0.122767 7.42658 0.134486 8.70861 0.134486 10C0.134486 11.2914 0.122767 12.5758 0.195424 13.8672C0.26808 15.3672 0.610267 16.6985 1.70714 17.7953C2.80636 18.8945 4.13527 19.2344 5.63527 19.307C6.92902 19.3797 8.21105 19.368 9.50245 19.368C10.7962 19.368 12.0782 19.3797 13.3696 19.307C14.8696 19.2344 16.2009 18.8922 17.2978 17.7953C18.397 16.6961 18.7368 15.3672 18.8095 13.8672C18.8845 12.5758 18.8704 11.2938 18.8704 10ZM9.50011 14.807C6.83995 14.807 4.69308 12.6602 4.69308 10C4.69308 7.33986 6.83995 5.19298 9.50011 5.19298C12.1603 5.19298 14.3071 7.33986 14.3071 10C14.3071 12.6602 12.1603 14.807 9.50011 14.807ZM14.504 6.11876C13.8829 6.11876 13.3814 5.6172 13.3814 4.99611C13.3814 4.37501 13.8829 3.87345 14.504 3.87345C15.1251 3.87345 15.6267 4.37501 15.6267 4.99611C15.6269 5.14359 15.5979 5.28966 15.5416 5.42595C15.4852 5.56224 15.4026 5.68607 15.2983 5.79036C15.194 5.89464 15.0701 5.97733 14.9339 6.03368C14.7976 6.09004 14.6515 6.11895 14.504 6.11876Z"
        fill={color}
      />
    </svg>
  );
};
export default Instagram;
