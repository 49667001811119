import Button from "Components/Admin/Buttons/Button";
import getIcon from "helpers/getIcon";

const Actions = ({ setSelectedUser, row }) => {
  return (
    <>
      <Button
        title={"Editar"}
        type="button"
        model="other"
        onClick={() => setSelectedUser(row)}
      >
        {getIcon("edit")}
      </Button>
    </>
  );
};
export default Actions;
