import * as S from "./styles";
import "twin.macro";
import { useEffect } from "react";
import { useState } from "react";
import UseServices from "hooks/UseServices";
import { HTTPErrorCallback } from "helpers/errorHelper";
import Workout from "./Workout";

const StudentHome = () => {
  const { api } = UseServices();
  const [loading, setLoading] = useState(true);
  const [workouts, setWorkouts] = useState([]);
  const [selectedWorkout, setSelectedWorkout] = useState(null);

  useEffect(() => {
    getVideos();
    //eslint-disable-next-line
  }, []);

  const getVideos = () => {
    setLoading(true);
    api
      .get(`/workouts/list`)
      .then((response) => {
        setWorkouts(response.data);
        setSelectedWorkout(response.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  return (
    <>
      <S.Content>
        {loading ? <h1>Carregando...</h1> : <></>}
        <S.Workouts>
          <S.TilesWrapper>
            {workouts.length
              ? workouts.map((workout) => (
                  <S.WorkoutWrapper
                    onClick={() => setSelectedWorkout(workout)}
                    active={selectedWorkout?.id === workout.id}
                  >
                    {workout.title}
                  </S.WorkoutWrapper>
                ))
              : "Ops... Parece que seu professor ainda não cadastrou nenhum treino."}
          </S.TilesWrapper>
        </S.Workouts>
        {selectedWorkout && <Workout workout={selectedWorkout} />}
      </S.Content>
    </>
  );
};

export default StudentHome;
