import styled from "styled-components/macro";

export const Wrapper = styled.div`
  display: grid;
  padding: 22px 22px;
  grid-gap: 20px;
  grid-template-columns: ${({ icon }) =>
    icon ? `minmax(20px, 30px) minmax(0, 2px) minmax(150px, auto)` : "auto"};
  border-radius: 9px;
  box-shadow: 0px 0px 10px rgba(65, 64, 66, 0.25);

  ${(props) => {
    switch (props.type) {
      case "approved":
        return `
        background-color: $#1d9d61;
        
        `;

      case "sended":
        return `         
          background-color: #99B5BF;
          `;

      case "archived":
        return `
            background-color: #7B8BBA;
                `;
      case "sketch":
        return `
            background-color: #ADADAD;
                `;

      default:
        return `
        background-color: #EBEBEB;
      `;
    }
  }}

  @media (max-width: 768px) {
    grid-gap: 10px;
    padding: 12px;
    border-radius: 0;
  }
`;

export const Line = styled.div`
  ${(props) => {
    switch (props.type) {
      case "approved":
        return `
        background-color: #FEDE9C;
        
        `;

      case "sended":
        return `         
          background-color: #0069B4;
          `;

      case "archived":
        return `
            background-color: #95A5D7;
                `;
      case "sketch":
        return `
            background-color: #F7F8F8;
                `;

      default:
        return `
        background-color: #FAFAFA;


                `;
    }
  }}
`;
export const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #323133;
`;
export const Infos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
`;
export const CreationInfo = styled.div`
  margin-right: 20px;

  //styleName: Text-desktop;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;

  overflow: hidden;

  /* cursor: url("/icons/copy.png"), auto; */
  span {
    display: inline;
    font-weight: 700;
    min-width: 0;
    white-space: nowrap;
    text-overflow: ellipsis;

    overflow: hidden;
  }
  &:hover {
    span {
      text-overflow: inherit;
      white-space: normal;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  //styleName: paragraph-bold-desktop;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  /* cursor: url("/icons/copy.png"), auto; */
  width: 100%;
  p {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    width: 100%;

    /* -ms-text-overflow:ellipsis; */
    /* text-overflow:ellipsis; */
  }
  &:hover {
    p {
      text-overflow: inherit;
      white-space: normal;
    }
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
export const Icon = styled.div`
  display: grid;
  place-items: center;
  svg {
    width: 30px;
  }
`;
