import tw, { styled, css } from "twin.macro";

export const Wrapper = styled.div`
  ${tw`w-full p-4 h-full py-10 overflow-auto flex flex-col items-center`}
    ${css`
        gap: 24px;
    `}
`;
export const FeedbackWrapper = styled.div`
  ${tw`p-4 bg-white rounded-lg shadow-md flex items-center justify-center`}
  ${css`
    height: fit-content;
    width: 50%;
    
    
    @media screen and (max-width: 670px) {
      width: 100%;
      
      div {
        width: 100%;
      }
    }

    button {
        width: 30%;
        height: fit-content;
    }
  `}
`;
export const Title = styled.h3`
  ${tw`text-lg font-bold mb-2`}
`;

export const DateText = styled.p`
  ${tw`text-xs`}
`;
