import Button from "Components/Button";
import Input from "Components/Input";
import { HTTPErrorCallback } from "helpers/errorHelper";
import { showNotification } from "helpers/notificationHelper";
import UseServices from "hooks/UseServices";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as S from "./styles";

const CreateStudent = ({ afterCreate = () => {} }) => {
  const { register, handleSubmit } = useForm();

  const { api } = UseServices();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ full_name: "", email: "", password: "" });

  const create = (data) => {
    setLoading(true);

    api
      .post("/users/create/student", data)
      .then(() => {
        showNotification("success", "Sucesso!", "Aluno criado com sucesso, aguarte até o Administrador aprovar essa ação!");
        afterCreate();
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <>
      <S.Form onSubmit={handleSubmit(create)} className="flex gap-4">
        <h2> Cadastrar Aluno</h2>
        <Input
          register={register}
          label="Nome"
          placeholder="Nome"
          name="full_name"
          required
          onChange={(e) => setData({ ...data, full_name: e.target.value })}
        />
        <Input
          register={register}
          label="E-Mail"
          placeholder="E-Mail"
          name="email"
          required
          type="email"
          onChange={(e) => setData({ ...data, email: e.target.value })}
        />
        <Input
          register={register}
          label="Senha"
          placeholder="Senha"
          name="password"
          required
          type="password"
          onChange={(e) => setData({ ...data, password: e.target.value })}
        />
        <small>Guarde a senha que inseriu, apenas você terá acesso a ela!</small>
        <Button type="submit" disabled={loading}>
          {loading ? "Carregando..." : "Salvar"}
        </Button>
      </S.Form>
    </>
  );
};

export default CreateStudent;
