import React from "react";

const ArrowDown = ({ color }) => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.763508 0.5637C0.932283 0.394976 1.16116 0.300192 1.39981 0.300192C1.63846 0.300192 1.86733 0.394976 2.03611 0.5637L4.99981 3.5274L7.96351 0.5637C8.04653 0.477741 8.14584 0.409177 8.25564 0.362009C8.36545 0.314841 8.48355 0.290013 8.60305 0.288974C8.72255 0.287936 8.84106 0.310707 8.95167 0.35596C9.06227 0.401213 9.16276 0.468041 9.24726 0.552544C9.33177 0.637048 9.39859 0.737534 9.44385 0.848141C9.4891 0.958748 9.51187 1.07726 9.51083 1.19676C9.5098 1.31626 9.48497 1.43436 9.4378 1.54416C9.39063 1.65397 9.32207 1.75328 9.23611 1.8363L5.63611 5.4363C5.46733 5.60502 5.23846 5.69981 4.99981 5.69981C4.76116 5.69981 4.53228 5.60502 4.36351 5.4363L0.763508 1.8363C0.594784 1.66752 0.5 1.43865 0.5 1.2C0.5 0.961352 0.594784 0.732475 0.763508 0.5637Z"
        fill={color}
      />
    </svg>
  );
};
export default ArrowDown;
