import * as S from "./styles";
import useServices from "../../hooks/UseServices";
import routes, {
  TEACHER_ROUTES,
  STUDENT_ROUTES,
} from "Routes/Components/Common/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const NavHeader = ({ setOpenedMenu, openedMenu }) => {
  const { profile } = useServices();
  const history = useHistory();
  const current_path = window.location.pathname;

  const routesArr =
    profile.user_type_id === 2 ? TEACHER_ROUTES : STUDENT_ROUTES;

  const title = routesArr.find((r) => r.path === current_path)?.getTitle ? routesArr.find((r) => r.path === current_path)?.getTitle(profile.user_type_id) : routesArr.find((r) => r.path === current_path)?.title;

  return (
    <>
      <S.Wrapper>
        <S.Hamburguer onClick={() => setOpenedMenu(!openedMenu)}>
          <span />
          <span />
          <span />
        </S.Hamburguer>
        <S.PageTitle>{title}</S.PageTitle>
        <S.Logo src={process.env.PUBLIC_URL + "/images/logo.png"}
            alt="Viva Mais" />
        <S.User>
          <S.UserImage src={profile.image_src} alt="" onClick={() => history.push(routes.Profile.path)} />
          <S.Edit
            src={process.env.PUBLIC_URL + "/images/pencil.svg"}
            onClick={() => history.push(routes.Profile.path)}
          />
          <S.UserCredentials>
            <h2>{profile.full_name}</h2>
            <p>{profile.user_type_display_name}</p>
            <p
              onClick={() => {
                localStorage.removeItem("userJWTToken");
                window.location.reload();
              }}
            >
              Sair
            </p>
          </S.UserCredentials>
        </S.User>
      </S.Wrapper>
    </>
  );
};
export default NavHeader;
