import { useEffect, useState } from "react";
import StatusTag from "Components/Admin/StatusTag";
import { HTTPErrorCallback } from "helpers/errorHelper";
import UseServices from "hooks/UseServices";
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from "styles/layouts/admin/wrappers";
import ContainerWithTitle from "Components/Admin/ContainerWithTitle";
import { columns, downloadColumns } from "./columns";
import Table from "Components/Admin/Table";
import { toDateTime } from "helpers/formatHelper";
import Actions from "./Actions";
import UpdateWorkouts from "../Update";

const Workouts = () => {
  const { api } = UseServices();
  const [workouts, setWorkouts] = useState([]);
  const [selectedWorkouts, setSelectedWorkouts] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!selectedWorkouts) getWorkouts();
    //eslint-disable-next-line
  }, [selectedWorkouts]);

  const getWorkouts = () => {
    setLoading(true);
    api
      .get(`/workouts/list`)
      .then((response) => {
        setWorkouts(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  return (
    <>
      <HeaderWrapper>
        <StatusTag
          icon="exercise"
          title="Relatório de Treinos"
          infos={[{ label: "Total de Treinos", value: workouts?.length }]}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            {selectedWorkouts ? (
              <UpdateWorkouts
                id={selectedWorkouts}
                setSelectedWorkouts={setSelectedWorkouts}
              />
            ) : (
              <Table
                data={workouts}
                columns={[
                  ...columns,
                  {
                    name: "Ações",
                    cell: (r) => (
                      <Actions
                        setLoading={setLoading}
                        getWorkouts={getWorkouts}
                        setSelectedWorkouts={setSelectedWorkouts}
                        id={r.id}
                      />
                    ),
                  },
                ]}
                loading={loading}
                customHeader
                downloadColumns={downloadColumns}
                dataTableProps={{ pagination: true }}
                fileName={`Treinos ${toDateTime(new Date())}`}
                refresh={getWorkouts}
              />
            )}
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default Workouts;
