import moment from "moment";
import * as S from "./styles";
import { useState } from "react";
import getIcon from "helpers/getIcon";

const Workout = ({ workout }) => {
  const [openVideo, setOpenVideo] = useState(null);
  return (
    <>
      {openVideo && (
        <S.Background>
          <S.Close onClick={() => setOpenVideo(null)}>
            {getIcon("close", "#FFF")}
          </S.Close>
          <S.Video controls poster={openVideo.thumbnail_src}>
            <source src={openVideo.src} />
          </S.Video>
        </S.Background>
      )}

      <S.Body>
        <S.WorkoutData>
          <p>
            Data da criação:{" "}
            {moment(workout.createdAt).format("DD/MM/YYYY HH:mm")}
          </p>
          <p>Descrição: {workout.description}</p>
        </S.WorkoutData>
        {workout.sequences.map((sequence) => (
          <S.Sequence>
            <S.SequenceHeader>
              <h2>{sequence.title}</h2>
            </S.SequenceHeader>
            {sequence.exercise_workouts.map((exercise_workout) => (
              <>
                <S.VideoWrapper>
                  <S.Thumbnail
                    src={exercise_workout.exercise?.video?.thumbnail_src}
                  ></S.Thumbnail>
                  <S.Details
                    onClick={() =>
                      setOpenVideo(exercise_workout.exercise.video)
                    }
                  >
                    <S.Title>{exercise_workout.exercise.title}</S.Title>
                    <S.Repetitions>
                      {exercise_workout.series} x {exercise_workout.repetitions}{" "}
                      {exercise_workout.weight
                        ? `/ ${exercise_workout.weight}`
                        : ""}
                    </S.Repetitions>
                  </S.Details>
                </S.VideoWrapper>
                <S.BreakTime>
                  {exercise_workout.break_time
                    ? exercise_workout.break_time
                    : "--"}
                </S.BreakTime>
              </>
            ))}
            <S.SequenceHeader restTime>
              <p>Tempo de descanço</p>
              <p>{sequence.break_time}</p>
            </S.SequenceHeader>
          </S.Sequence>
        ))}
      </S.Body>
    </>
  );
};

export default Workout;
