import ContainerWithTitle from "Components/Admin/ContainerWithTitle";
import StatusTag from "Components/Admin/StatusTag";
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from "styles/layouts/admin/wrappers";
import CreateUserForm from "./Components/Form";

const CreateUser = () => {
  return (
    <>
      <HeaderWrapper>
        <StatusTag icon="user" title="Criar usuário" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <CreateUserForm />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default CreateUser;
