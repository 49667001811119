export const decidePluralByNumber = (number, singular, plural) => {
  if (number > 1) return plural;

  return singular;
};
export const removeItemValue = (querySelector = "") => {
  document.querySelector(querySelector).value = null;
};

export const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const stripHTML = (html) => {
  let tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};

export const get = (object, key) => {
  var keys = key.split(".");
  for (var i = 0; i < keys.length; i++) {
    if (!object.hasOwnProperty(keys[i])) {
      return null;
    }
    object = object[keys[i]];
  }
  return object;
};

export const sortArray = (array, key) => {
  const newArray = array.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });

  return newArray;
};

export const calculateTotalFromProject = (project) => {
  const totals = {
    bv: 0,
    receipt: 0,
    rawCost: 0,
    totalCost: 0,
  };

  const { actions } = project;

  actions.forEach((action) => (totals.rawCost += Number(action.total_value)));

  totals.totalCost =
    totals.rawCost +
    (totals.rawCost * ((0.33 * project.payment_deadline) / 100)) /
      ((100 - project.bv - project.receipt) / 100);

  totals.bv = (totals.rawCost * project.bv || 0) / 100;
  totals.receipt = (totals.rawCost * project.receipt || 0) / 100;

  return totals;
};

export const calculateTotalFromAction = (
  action,
  payment_deadline,
  bv,
  receipt
) => {
  const totals = {
    services: 0,
    devices: 0,
    supports: 0,
    logistics: 0,
    bv: 0,
    receipt: 0,
    rawCost: 0,
    totalCost: 0,
  };

  const { services, devices, supports, logistics } = action;

  services.forEach((service) => (totals.services += Number(service.result)));

  devices.forEach((device) => (totals.devices += Number(device.result)));

  supports.forEach((support) => (totals.supports += Number(support.result)));

  logistics.forEach(
    (logistic) => (totals.logistics += Number(logistic.result))
  );

  totals.rawCost =
    totals.services + totals.devices + totals.supports + totals.logistics;

  totals.totalCost =
    totals.rawCost +
    (totals.rawCost * ((0.33 * payment_deadline) / 100)) /
      ((100 - bv - receipt) / 100);

  totals.bv = (totals.rawCost * bv || 0) / 100;
  totals.receipt = (totals.rawCost * receipt || 0) / 100;

  return totals;
};

export const getObjectInArray = (array, valueToFind, selectorFunc) => {
  let result = false;
  for (let i = 0; i < array.length; i++) {
    const item = array[i];

    //eslint-disable-next-line
    if (selectorFunc(item) == valueToFind) {
      result = { index: i, item };
      break;
    }
  }

  return result;
};

export const formatToMoney = (string) => {
  return string.toLocaleString("pt-br", { minimumFractionDigits: 2 });
};

export const transformObjectOfPeriodInSelectOptions = (
  periods = {},
  labelToMerge
) => {
  const finalList = [];
  const keys = Object.keys(periods);

  keys.forEach((key) => {
    const object = {};
    object["label"] = `${key} ${labelToMerge}`;
    object["value"] = periods[key];

    finalList.push(object);
  });

  return finalList;
};
export const isObjectEmpty = (object = {}) => {
  return Object.keys(object).length;
};
export const formatFilterOptionsInQueryString = (options) => {
  return Object.keys(options)
    .map((key) => `${key}=${options[key]}`)
    .join("&");
};
export const cleanPhone = (phone) => {
  return phone
    .replaceAll("-", "")
    .replaceAll("_", "")
    .replace("(", "")
    .replace(")", "")
    .replace(" ", "");
};

export const getPhoneMask = (phone) => {
  const cleanPhone = phone
    .replaceAll("-", "")
    .replaceAll("_", "")
    .replace("(", "")
    .replace(")", "")
    .replace(" ", "");

  if (cleanPhone.length < 10) return "(99) 9999-9999";

  return "(99) 99999-9999";
};

export const includeZeroInNumber = (number, space) => {
  return String(number).padStart(space || 2, "0");
};

export const stringContrinsSpecialCharacter = (string) => {
  //eslint-disable-next-line
  const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  return format.test(string);
};

export const getPasswordSecurityVerifications = (password) => {
  const verifications = {};

  if (password.length >= 8) verifications.hasEnoughLength = true;

  if (/\d/.test(password)) verifications.hasNumber = true;

  if (/[A-Z]/.test(password)) verifications.hasUppercase = true;

  if (stringContrinsSpecialCharacter(password))
    verifications.hasSpecialCharacters = true;

  return verifications;
};

export const scrollToEndOfElement = (element) => {
  if (!element) return;
  element.scrollTo(0, element.scrollHeight);
};

export const transformArrayIntoSelectOptions = (
  array,
  labelSelector,
  valueSelector
) => {
  const options = [];
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    const option = {
      label: labelSelector(item),
      value: valueSelector(item),
    };
    options.push(option);
  }
  return options;
};

export const appendObjectInFormData = (formData, object) => {
  for (const key of Object.keys(object)) {
    formData.append(key, object[key]);
  }

  return formData;
};
