import { Form } from "@unform/web";
import Button from "Components/Admin/Buttons/Button";
import Input from "Components/Admin/Inputs/Input";
import { HTTPErrorCallback } from "helpers/errorHelper";
import { showNotification } from "helpers/notificationHelper";
import UseServices from "hooks/UseServices";
import Select from "Components/Admin/Inputs/Select";
import { useEffect, useState } from "react";
import { transformArrayIntoSelectOptions } from "helpers/util";
import FormWrapper from "Components/Admin/FormWrapper";

const CreateUserForm = () => {
  const { api } = UseServices();
  const [isStudent, setIsStudent] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [teacher, setTeacher] = useState(null);
  const [userType, setUserType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [nameOrEmail, setNameOrEmail] = useState("");
  const [userTypeOptions, setuserTypeOptions] = useState([]);

  useEffect(() => {
    getUserTypes();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    api
      .get(`/users/admin/teachers_by_name?name_or_email=${nameOrEmail}`)
      .then((response) => {
        setTeachers(
          transformArrayIntoSelectOptions(
            response.data,
            (u) => u.full_name,
            (u) => u.id
          )
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  }, [nameOrEmail, api]);

  const getUserTypes = () => {
    api
      .get("/user_types")
      .then((response) => {
        const options = transformArrayIntoSelectOptions(
          response.data,
          (item) => item.display_name,
          (item) => item.id
        );
        setuserTypeOptions(options);
      })
      .catch((error) => HTTPErrorCallback(error));
  };

  const create = (data, { reset }) => {
    data.user_type_id = userType;
    data.teacher_user_id = teacher;

    if (!data.user_type_id)
      return showNotification(
        "danger",
        "Atenção",
        "Informe um tipo de usuário!"
      );
      
    api
      .post(`/users/create${data.user_type_id === 3 ? "/student" : ""}`, {
        ...data,
        active: true,
        opt_in: true,
      })
      .then(() => {
        showNotification("success", "Sucesso!", "Usuário criado!");
        reset();
      })
      .catch((error) => HTTPErrorCallback(error));
  };

  return (
    <FormWrapper columns="1fr" maxWidth="25%">
      <Form onSubmit={create}>
        <Input
          name="full_name"
          placeholder="Nome do usuário"
          label="Nome completo"
          required
          mt="10px"
        />
        <Input
          name="email"
          type="email"
          placeholder="Email do usuário"
          label="Email do usuário"
          required
          mt="10px"
        />
        <Select
          name="user_type_id"
          label="Tipo de usuário"
          options={userTypeOptions}
          onChange={(e) => {
            setIsStudent(e.value === 3);
            setUserType(e.value);
          }}
          placeholder="Tipo de usuário"
          required
          isRequired
        />
        {isStudent && (
          <Select
            name="teacher_id"
            label="Professor Responsável"
            options={teachers}
            placeholder="Escreva um nome ou email"
            onInputChange={(e) => setNameOrEmail(e)}
            onChange={(e) => setTeacher(e.value)}
            isRequired
            required
            isLoading={loading}
          />
        )}

        <Input
          label="Senha:"
          name="password"
          type="password"
          placeholder="Senha"
        />
        <Button type="submit" mt="20px">
          Criar
        </Button>
      </Form>
    </FormWrapper>
  );
};

export default CreateUserForm;
