import getIcon from "helpers/getIcon";
import * as S from "./styles";

const NoDataMessage = (
  <S.NoDataMessage>
    {getIcon("noData")}
    <span>Ainda não há registros.</span>
  </S.NoDataMessage>
);

export default NoDataMessage;
