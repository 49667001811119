import styled from "styled-components";
import media from "styled-media-query";

export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  background: #00000090;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 9999;
`;

export const Wrapper = styled.div`
  background: white;
  padding: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  overflow: auto;
  max-height: 100vh;

  h3 {
    font-size: 18px;
    font-weight: bold;
  }

  p {
    font-size: 15px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const Close = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

export const Body = styled.div`
  display: flex;
  gap: 25px;
  width: 100%;
  justify-content: space-evenly;

  video {
    width: 400px;
    height: 400px;
    object-fit: contain;
    border-radius: 28px 28px 0px 0px;
  }

  ${media.lessThan("medium")`
    flex-direction: column;

    video {
        margin: 0 auto;
    }
  `}
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 40%;

  button {
    margin-top: 10px;
  }

  ${media.lessThan("medium")`
    justify-content: center;
    align-items: center;
    width: 100%;
  `}
`;

export const Input = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  place-items: start;
  width: 100%;
  border: none;
  gap: 3px;
  label {
    color: #323133;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 6px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }

  input {
    &::placeholder {
      color: #c2c2c2;
    }
    color: #323133;

    border: 1px solid #adadad;
    transition: ease 0.3s all;

    padding: 8px 12px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 24px;
    width: 100%;
    height: 40px;

    outline-color: #1d9d61;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`;
