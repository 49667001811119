import Icon from "Components/Icon";
import * as S from "./styles";

const Input = ({
  icon,
  label,
  placeholder,
  name,
  type,
  register,
  required,
  ...rest
}) => {
  return (
    <>
      <S.Wrapper icon={icon}>
        {icon && (
          <S.Icon>
            <Icon icon={icon} />
          </S.Icon>
        )}
        <S.Input
          label={label}
          placeholder={placeholder}
          name={name}
          type={type}
          {...register(name, { required: required, ...rest })}
          {...rest}
        />
      </S.Wrapper>
    </>
  );
};
export default Input;
