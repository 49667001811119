import React from "react";

const Upload = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.54 2.23C17.83 2.57 18 3 18 3.5V16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18H2C1.46957 18 0.960859 17.7893 0.585786 17.4142C0.210714 17.0391 0 16.5304 0 16V3.5C0 3 0.17 2.57 0.46 2.23L1.84 0.55C2.12 0.21 2.53 0 3 0H15C15.47 0 15.88 0.21 16.15 0.55L17.54 2.23ZM2.12 2H15.87L14.93 1H2.93L2.12 2ZM9 6.5L3.5 12H7V14H11V12H14.5L9 6.5Z"
        fill={color}
      />
    </svg>
  );
};
export default Upload;
