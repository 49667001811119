import { css } from "@emotion/react";
import tw, { styled } from "twin.macro";

export const Wrapper = styled.div`
  ${tw`w-full h-full overflow-hidden bg-white shadow-viva`}
  ${css`
    grid-area: sidebar;
    transition: .5s ease all;
    @media screen and (max-width: 670px) {
      position: absolute;
      right: 100%;
      margin-top: 90px;
      z-index: 1000;
    }
  `}
  ${({ opened }) =>
    opened &&
    css`
      @media screen and (max-width: 670px) {
        right: 0;
        height: calc(100vh - 90px);
      }
    `}
`;
export const SidebarButtons = styled.div`
  ${tw`grid w-full gap-1 py-10 overflow-hidden`}
  ${css``}
  ${({ opened }) =>
    opened &&
    tw`

    `}
`;
export const LogoWrapper = styled.div`
  ${tw`py-4`}
  ${css`
    height: 150px;
  `}
  ${({ opened }) =>
    opened &&
    css`
      padding: 0;
      height: 150px;

    `}
  @media screen and (max-width: 670px) {
    height: 90px;
    display: none;
  }
`;
export const Logo = styled.img`
  ${tw`w-full h-full object-contain `}
  
`;
