import { HTTPErrorCallback } from "helpers/errorHelper"
import UseServices from "hooks/UseServices"
import * as S from './styles'
import { useEffect, useState } from 'react'
import Workout from "./Workout";
import Button from "Components/Button";

const ShowStudent = ({ id, setSelectedStudent }) => {
    const { api } = UseServices()
    const [loading, setLoading] = useState(true)
    const [workouts, setWorkouts] = useState([]);
    const [selectedWorkout, setSelectedWorkout] = useState(null);

    useEffect(() => {
        getWorkouts()
        //eslint-disable-next-line
    }, [])

    const getWorkouts = () => {
        setLoading(true);
        api.get(`/workouts/list?student_id=${id}`).then(response => {
            setWorkouts(response.data)
            setSelectedWorkout(response.data[0])
            setLoading(false);
        }).catch(error => {
            HTTPErrorCallback(error)
        })
    }

    return (
        <S.Content>
            {loading ? <h1>Carregando...</h1> : <></>}
            <Button onClick={() => setSelectedStudent(null)}>Voltar</Button>
            <S.Workouts>
                {workouts.length
                    ? workouts.map((workout) => (
                        <S.WorkoutWrapper onClick={() => setSelectedWorkout(workout)} active={selectedWorkout?.id === workout.id}>
                            {workout.title}
                        </S.WorkoutWrapper>
                    ))
                    : "Ops... Não há nenhum treino disponível"}
            </S.Workouts>
            {selectedWorkout && <Workout workout={selectedWorkout} />}
        </S.Content>
    )
}

export default ShowStudent