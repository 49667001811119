import { useEffect, useState } from "react";
import StatusTag from "Components/Admin/StatusTag";
import { HTTPErrorCallback } from "helpers/errorHelper";
import UseServices from "hooks/UseServices";
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from "styles/layouts/admin/wrappers";
import ContainerWithTitle from "Components/Admin/ContainerWithTitle";
import { columns, downloadColumns } from "./columns";
import Table from "Components/Admin/Table";
import { toDateTime } from "helpers/formatHelper";

const ListTeachers = () => {
  const { api } = UseServices();
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getTeachers();
    //eslint-disable-next-line
  }, []);

  const getTeachers = () => {
    setLoading(true);
    api
      .get(`/users/admin/teachers`)
      .then((response) => {
        setTeachers(response.data.users);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  return (
    <>
      <HeaderWrapper>
        <StatusTag
          icon="user"
          title="Relatório de Professores"
          infos={[{ label: "Total de Professores", value: teachers?.length }]}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
          <Table
          data={teachers}
          columns={columns}
          loading={loading}
          customHeader
          downloadColumns={downloadColumns}
          dataTableProps={{ pagination: true }}
          fileName={`Professores ${toDateTime(new Date())}`}
          refresh={getTeachers}
        />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default ListTeachers;
