import tw, { styled, css } from "twin.macro";

export const Content = styled.div`
  ${tw`w-full p-4 h-full py-10 overflow-auto`}
  ${css`
    grid-area: content;
  `}
`;
export const ContentHeader = styled.div`
  ${tw`p-6 mb-4`}
  ${css``}
`;
export const ContentInner = styled.div`
  ${tw`grid w-full h-full gap-6 px-6`}
  ${css`
    grid-template-columns: 1fr 400px;
    grid-auto-flow: column;
  `}
`;

export const ContentArea = styled.div`
  ${tw`bg-white rounded-[20px] shadow-viva`}
  ${css``}
`;
