import moment from "moment";

export const toTimeNoSeconds = (value) => {
  return moment(value).format("HH:mm");
};

export const toDate = (value) => {
  return moment(value).format("DD/MM/YYYY");
};

export const toDateTime = (value) => {
  return moment(value).format("DD/MM/YYYY HH:mm:ss");
};

export const toDateTimeNoSeconds = (value) => {
  return moment(value).format("DD/MM/YYYY HH:mm");
};

export const toTimeNoMinuteNoSecondsWithH = (value) => {
  return moment(value).format("HH") + "h";
};

export const longMonthBR = (value) => {
  var month = moment(value).format("MMMM");

  if (month === "January") return "Janeiro";
  if (month === "February") return "Fevereiro";
  if (month === "March") return "Março";
  if (month === "April") return "Abril";
  if (month === "May") return "Maio";
  if (month === "June") return "Junho";
  if (month === "July") return "Julho";
  if (month === "August") return "Agosto";
  if (month === "September") return "Setembro";
  if (month === "October") return "Outubro";
  if (month === "November") return "Novembro";
  if (month === "December") return "Dezembro";

  return "NOT RECOGNIZED MONTH";
};

export const toDateBR = (value) => {
  return moment(value).format("DD/MM/YYYY");
};

export const toDateUS = (value) => {
  return moment(value).format("YYYY-MM-DD");
};

export const onlyDayNumber = (value) => {
  return moment(value).format("DD");
};

export const limitCharactersOfString = (string, limit) => {
  if (string) {
    let result = string;

    if (string.length > limit) {
      const resultSliced = result.slice(0, limit);
      const resultWithDots = `${resultSliced}...`;
      result = resultWithDots;
    }

    return result;
  }
  return string;
};

function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

export const getTime = (value) => {
  var d = new Date(value);
  var h = addZero(d.getHours());
  var m = addZero(d.getMinutes());
  var s = addZero(d.getSeconds());

  return `${h}:${m}:${s}`;
};

export const toBRL = (value) => {
  const format = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
  return `${format}`;
};

export const cpfcnpjMask = (value) => {
  if (!value) return "";

  let result;

  if (value.length < 14 || (value.length <= 14 && value.includes("."))) {
    result = value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  } else {
    result = value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  }

  return result;
};
