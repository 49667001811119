import React, { useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";

import * as S from "./styles";
import getIcon from "helpers/getIcon";

const Input = ({
  name,
  maxLength,
  alertType,
  alertMessage,
  password,
  label,
  gridarea,
  small,
  large,
  type,
  placeholder,
  onChange,
  showIncrement,
  hideLimit,
  required,
  disabled,
  cardToggle,
  width,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, registerField } = useField(name);
  const [charCount, setCharCount] = useState(0);
  const [hide, setHide] = useState(true);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <S.Wrapper
      cardToggle={cardToggle}
      gridarea={gridarea}
      small={small}
      large={large}
      disabled={disabled}
      alertType={alertType}
      width={width}
    >
      <label>
        {label}
        <span>{required && "*"}</span>
      </label>
      <S.Inner showIncrement={showIncrement} {...rest}>
        <input
          type={hide ? type : "text"}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={(e) => {
            onChange && onChange(e);
            setCharCount(e.target.value.length);
          }}
          required={required}
          ref={inputRef}
          {...rest}
        />
        {maxLength && !hideLimit && (
          <S.CharLimit>
            {charCount}/{maxLength}
          </S.CharLimit>
        )}
        {type === "password" && (
          <S.Hide tabIndex="-1" type="button" onClick={() => setHide(!hide)}>
            {hide ? getIcon("hideOn") : getIcon("hideOff")}
          </S.Hide>
        )}
      </S.Inner>
      {alertMessage && (
        <S.Message alertType={alertType}>{alertMessage}</S.Message>
      )}
    </S.Wrapper>
  );
};

export default Input;
