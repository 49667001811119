import styled, { css } from "styled-components/macro";
import media from "styled-media-query";

export const Wrapper = styled.div`
  grid-area: sidebar;
  position: relative;
  height: 100%;
  background-color: #fff;
  width: ${(props) => (props.opened || props.hoverOpened ? "230px" : "70px")};
  border-top-right-radius: 9px;
  box-shadow: 0px 0px 10px rgb(120 130 140 / 13%);
  transition: ease 0.3s all;
  z-index: 1;
  will-change: auto;
  * {
    will-change: auto;
  }
  .version {
    color: #fafafa;
    font-size: 10px;
  }

  @media (max-width: 768px) {
    width: 100%;
    position: absolute;
    transform: 0.5s ease all;
    right: 100%;
    height: calc(100vh - 80px);
    bottom: 0;
    z-index: 10;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 32px;
    max-width: 220px;

    ${({ opened }) =>
      opened &&
      css`
        left: 0;
      `}
  }
`;

export const Navbar = styled.nav`
  padding: 20px;
  padding-left: ${(props) =>
    props.opened || props.hoverOpened ? "16px" : "14px"};
  display: grid;

  grid-gap: 16px;

  max-height: calc(var(--app-height) - 240px);
  overflow: hidden;

  overflow-y: scroll;
  overflow-y: overlay;
  scrollbar-color: green;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 2px;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    width: 100%;
  }
`;
export const Header = styled.div`
  position: relative;
  width: 100%;
  transition: ease 0.3s all;

  display: grid;
  padding: ${(props) =>
    props.opened || props.hoverOpened ? "30px 26.5px" : "30px 10px"};
  z-index: 10;

  grid-template-columns: ${(props) =>
    props.opened || props.hoverOpened ? "50px 1fr" : "1fr"};
  grid-gap: 6px;
  place-items: ${(props) =>
    props.opened || props.hoverOpened ? "center start" : "center"};

  ${media.lessThan("768px")`
    //display: none;
    padding: 0 20px 20px 20px;
  `}

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    transition: ease 0.3s all;
    width: ${(props) =>
      props.opened || props.hoverOpened
        ? "calc(100% - 50px)"
        : "calc(100% - 20px)"};
    height: 1px;
    background-color: #1d9d61;
  }
`;
export const ProfilePhoto = styled.img`
  width: 50px;

  ${media.lessThan("768px")`
    width: 40px;
    border: 1px solid #1d9d61;
  `}
`;

export const Credentials = styled.div`
  animation: ease 0.4s fadeInRight;
  will-change: auto;
  white-space: nowrap;
  display: ${(props) => (props.opened || props.hoverOpened ? "grid" : "none")};

  color: #1d9d61;

  /*
  @media (max-width: 768px) {
    display: none;
  }
  */
`;
export const Name = styled.div`
  //styleName: H6-desktop;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  min-width: 0;

  ${media.lessThan("768px")`
    font-size: 14px;
    line-height: 21px;
  `}
`;
export const Email = styled.div`
  //styleName: Support-desktop;

  font-size: 12px;
  font-style: normal;
  max-width: 100%;

  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  min-width: 0;

  ${media.lessThan("768px")`
    font-size: 10px;
    line-height: 12.19px;
  `}
`;
