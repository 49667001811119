import { toDateTime } from "helpers/formatHelper";

export const columns = [
  {
    name: "Título",
    selector: (row) => row.title,
    sortable: true,
  },
  {
    name: "Descrição",
    selector: (row) => row.description,
    sortable: true,
  },
  {
    name: "Dificuldade",
    selector: (row) => row.dificulty,
    sortable: true,
  },
  {
    name: "Meta",
    selector: (row) => row.goal,
    sortable: true,
  },
  {
    name: "Criador",
    selector: (row) => row.creator?.full_name,
    sortable: true,
  },
  {
    name: "Estudante",
    selector: (row) => row.student?.full_name,
    sortable: true,
  },
  {
    name: "Começa em",
    selector: (row) => toDateTime(row.start_at),
    sortable: true,
  },
  {
    name: "Termina em",
    selector: (row) => toDateTime(row.finish_at),
    sortable: true,
  },
  {
    name: "Data de registro",
    selector: (row) => (row.createdAt ? toDateTime(row.createdAt) : "N/A"),
    sortable: true,
  },
];

export const downloadColumns = columns;
