import React from "react";

const Close = ({ color }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.97902 19.4711L16.9238 1.66052L14.9449 -1.22126e-05L6.75333e-05 17.8105L1.97902 19.4711Z"
        fill={color}
      />
      <path
        d="M0.660658 1.71571L15.6055 19.5262L17.5844 17.8657L2.63961 0.0551774L0.660658 1.71571Z"
        fill={color}
      />
    </svg>
  );
};
export default Close;
