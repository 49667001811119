import React from "react";

const Duplicate = ({ color }) => {
  return (
    <svg
      width="24"
      height="12"
      viewBox="0 0 24 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.8 0H19.2V12H16.8V6.6H7.2V12H4.8V0H7.2V5.4H16.8V0ZM1.2 5.4V1.8H3.6V10.2H1.2V6.6H0V5.4H1.2ZM24 6.6H22.8V10.2H20.4V1.8H22.8V5.4H24V6.6Z"
        fill={color}
      />
    </svg>
  );
};
export default Duplicate;
