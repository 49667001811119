import React from "react";

const RightArrow = ({ color }) => {
  return (
    <svg
      width="39"
      height="62"
      viewBox="0 0 39 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.329956 54.715L24.045 31L0.329956 7.285L7.66662 0L38.6666 31L7.66662 62L0.329956 54.715Z"
        fill={color}
      />
    </svg>
  );
};
export default RightArrow;
