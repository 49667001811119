import React from "react";

const Info = ({ color }) => {
  return (
    <svg
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.3 36.3H29.7V16.5H36.3V36.3ZM36.3 49.5H29.7V42.9H36.3V49.5ZM33 0C28.6664 0 24.3752 0.85357 20.3714 2.51198C16.3677 4.17038 12.7298 6.60114 9.66548 9.66548C3.47678 15.8542 0 24.2479 0 33C0 41.7521 3.47678 50.1458 9.66548 56.3345C12.7298 59.3989 16.3677 61.8296 20.3714 63.488C24.3752 65.1464 28.6664 66 33 66C41.7521 66 50.1458 62.5232 56.3345 56.3345C62.5232 50.1458 66 41.7521 66 33C66 28.6664 65.1464 24.3752 63.488 20.3714C61.8296 16.3677 59.3989 12.7298 56.3345 9.66548C53.2702 6.60114 49.6323 4.17038 45.6286 2.51198C41.6248 0.85357 37.3336 0 33 0V0Z"
        fill={color}
      />
    </svg>
  );
};
export default Info;
