import React from "react";

const DownloadCloud = ({ color }) => {
  return (
    <svg
      width="30"
      height="23"
      viewBox="0 0 30 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6666 22.1666H7.66658C5.63992 22.1666 3.91103 21.4689 2.47992 20.0733C1.04881 18.6689 0.333252 16.9578 0.333252 14.94C0.333252 13.2066 0.853252 11.66 1.89325 10.3C2.94214 8.93998 4.31103 8.07331 5.99992 7.69998C6.55992 5.65553 7.67103 4.0022 9.33325 2.73998C11.0044 1.46887 12.8933 0.833313 14.9999 0.833313C17.6044 0.833313 19.8088 1.73998 21.6132 3.55331C23.4266 5.35776 24.3333 7.5622 24.3333 10.1666C25.871 10.3444 27.1421 11.0111 28.1466 12.1666C29.1599 13.3044 29.6666 14.6378 29.6666 16.1666C29.6666 17.8378 29.0844 19.2555 27.9199 20.42C26.7555 21.5844 25.3377 22.1666 23.6666 22.1666H16.3333V12.6333L18.4666 14.7L20.3333 12.8333L14.9999 7.49998L9.66658 12.8333L11.5333 14.7L13.6666 12.6333V22.1666Z"
        fill={color}
      />
    </svg>
  );
};
export default DownloadCloud;
