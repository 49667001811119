import { Form } from "@unform/web";
import Button from "Components/Admin/Buttons/Button";
import Input from "Components/Admin/Inputs/Input";
import { HTTPErrorCallback } from "helpers/errorHelper";
import { showNotification } from "helpers/notificationHelper";
import UseServices from "hooks/UseServices";
import Select from "Components/Admin/Inputs/Select";
import { useEffect, useRef, useState } from "react";
import { transformArrayIntoSelectOptions } from "helpers/util";
import FormWrapper from "Components/Admin/FormWrapper";
import passwordGenerator from "generate-password";
import * as S from "./styles";
import Students from "../Students";
import AddStudent from "../ReplaceTeacher";

const UpdateUserForm = ({ selecetedUser, setSelectedUser, refresh }) => {
  const isTeacher = selecetedUser?.user_type?.id === 2;
  const { api } = UseServices();
  const [userTypeOptions, setuserTypeOptions] = useState([]);
  const [addStudent, setAddStudent] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    getUserTypes();
    //eslint-disable-next-line
  }, []);

  const getUserTypes = () => {
    api
      .get("/user_types")
      .then((response) => {
        const options = transformArrayIntoSelectOptions(
          response.data,
          (item) => item.display_name,
          (item) => item.id
        );
        setuserTypeOptions(options);
      })
      .catch((error) => HTTPErrorCallback(error));
  };

  const update = (data, { reset }) => {
    if (!data.password) delete data.password;
    api
      .put(`/users/admin/${selecetedUser.id}`, data)
      .then(() => {
        setSelectedUser(null);
        showNotification("success", "Sucesso!", "Usuário atualizado!");
        refresh();
        reset();
      })
      .catch((error) => HTTPErrorCallback(error));
  };

  const sendRecoveryEmail = () => {
    const email = selecetedUser.email;
    api
      .post("/sessions/request_password_token", { email })
      .then(() => {
        showNotification("success", "Sucesso!", "Email enviado com sucesso!");
      })
      .catch((error) => HTTPErrorCallback(error));
  };

  const generateSafePassword = () => {
    const password = passwordGenerator.generate({
      length: 10,
      numbers: true,
      uppercase: true,
      symbols: true,
    });

    formRef.current.setData({ password });
    showNotification(
      "success",
      "Senha Gerada!",
      "Segure-se de salva-la antes de atualizar o usuário!"
    );
  };

  return (
    <>
      <S.Buttons>
        <Button
          leftIcon
          iconColor={"#FFF"}
          icon="backArrow"
          type="button"
          onClick={() => setSelectedUser(null)}
        >
          Voltar
        </Button>

        <Button
          leftIcon
          icon="mail"
          iconColor={"#FFF"}
          onClick={() => sendRecoveryEmail()}
          type="button"
        >
          Enviar email de recuperação
        </Button>
        <Button
          leftIcon
          iconColor={"#FFF"}
          icon="password"
          onClick={() => generateSafePassword()}
          type="button"
        >
          Gerar Senha Segura
        </Button>
        <Button
          leftIcon
          iconColor={"#FFF"}
          icon={selecetedUser.active ? "close" : "check"}
          onClick={() =>
            update({ active: !selecetedUser.active }, { reset: () => {} })
          }
          type="button"
        >
          {selecetedUser.active ? "Desativar" : "Ativar"}
        </Button>
        {isTeacher && (
          <Button
            leftIcon
            iconColor={"#FFF"}
            icon="add"
            onClick={() => setAddStudent(!addStudent)}
            type="button"
          >
            Adicionar Aluno
          </Button>
        )}
      </S.Buttons>
      <FormWrapper>
        <Form onSubmit={update} ref={formRef}>
          <Input
            defaultValue={selecetedUser.full_name}
            name="full_name"
            placeholder="Nome do usuário"
            label="Nome completo"
            required
            mt="10px"
          />
          <Input
            defaultValue={selecetedUser.email}
            name="email"
            placeholder="Email do usuário"
            label="Email do usuário"
            type="email"
            required
            mt="10px"
          />
          <Select
            name="user_type_id"
            label="Tipo de usuário"
            options={userTypeOptions}
            placeholder="Tipo de usuário"
            defaultValue={{
              label: selecetedUser.user_type.display_name,
              value: selecetedUser.user_type.id,
            }}
            required
          />

          <Input
            label="Senha:"
            name="password"
            type="password"
            placeholder="Senha"
          />

          <Button type="submit" mt="20px">
            Atualizar
          </Button>
        </Form>
      </FormWrapper>

      {isTeacher ? (
        <>
          <AddStudent
            open={addStudent}
            setOpen={setAddStudent}
            teacher_id={selecetedUser.id}
          />
          <S.Students>
            <p>Estudantes</p>
            <Students
              teacher_id={selecetedUser.id}
              teacher_name={selecetedUser.full_name}
            />
          </S.Students>
        </>
      ) : null}
    </>
  );
};

export default UpdateUserForm;
