import { Form } from "@unform/web";
import Input from "Components/Admin/Inputs/Input";
import Button from "Components/Admin/Buttons/Button";
import * as S from "./styles.js";
import { useState } from "react";
import UseServices from "hooks/UseServices.js";
import { HTTPErrorCallback } from "helpers/errorHelper.js";
import { showNotification } from "helpers/notificationHelper.js";
import SectionLoading from "Components/Global/SectionLoading/index.js";
import { useEffect } from "react";
import { transformArrayIntoSelectOptions } from "helpers/util.js";
import Select from "Components/Admin/Inputs/Select/index.js";
import Video from "Pages/Admin/Videos/Components/Video/index.js";
import { useRef } from "react";

const UpdateWorkouts = ({ id, setSelectedWorkouts }) => {
  const { api } = UseServices();
  const formRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    title: "",
    description: "",
    category: "",
    videoId: "",
  });
  const [videos, setVideos] = useState([]);
  const [videoOptions, setVideoOptions] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const video = videos.find((v) => v.id === data.videoId);

  if (video) video.video = video.src;

  useEffect(() => {
    getVideos();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const video = videos.find((v) => v.id === data.videoId);
    const source = document.querySelector("#video_source source");

    console.log(source.src, video?.src);

    if (!data.videoId || (source && source.src === video?.src)) return;

    const videoElement = document.getElementById("video_source");
    videoElement.innerHTML = "";
    const newSource = document.createElement("source");
    newSource.src = video.src;
    videoElement.appendChild(newSource);
    videoElement.load();
  }, [videos, data, selectedVideo]);

  const getVideos = () => {
    setLoading(true);
    api
      .get(`/videos`)
      .then((response) => {
        setVideoOptions(
          transformArrayIntoSelectOptions(
            response.data.videos,
            (v) => v.title,
            (v) => v.id
          )
        );
        setVideos(response.data.videos);
        getExercise();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };
  const getExercise = () => {
    setLoading(true);
    api
      .get(`/exercises/list/${id}`)
      .then((response) => {
        const exercise = response.data;
        const exerciseData = {
          title: exercise.title,
          description: exercise.description,
          category: exercise.category,
        };

        setData({ ...exerciseData, videoId: exercise.video_id });
        setSelectedVideo({
          label: exercise.video?.title,
          value: exercise.video?.title,
        });
        formRef.current.setData(exerciseData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  const update = (submited_data) => {
    setLoading(true);

    api
      .put(`/exercises/${id}`, { ...submited_data, ...data })
      .then(() => {
        showNotification(
          "success",
          "Sucesso!",
          "Exercício criado com sucesso!"
        );
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <SectionLoading />}
      <Button type="button" onClick={() => setSelectedWorkouts(null)}>
        Voltar
      </Button>
      <S.Wrapper>
        <Form onSubmit={update} ref={formRef}>
          <Input
            label="Título"
            name="title"
            required
            onChange={(e) => setData({ ...data, title: e.target.value })}
          />
          <Input
            label="Descrição"
            name="description"
            required
            onChange={(e) => setData({ ...data, description: e.target.value })}
          />
          <Input
            label="Categoria"
            name="category"
            required
            onChange={(e) => setData({ ...data, category: e.target.value })}
          />
          <Select
            name="videoId"
            options={videoOptions}
            label="Selecione um vídeo"
            placeholder="Selecione um vídeo"
            value={selectedVideo}
            onChange={(e) => {
              setSelectedVideo(e);
              setData({ ...data, videoId: e.value });
            }}
          />

          <Button type="submit">Salvar</Button>
        </Form>
        <Video {...video} thumbnail={video?.thumbnail_src} />
      </S.Wrapper>
    </>
  );
};

export default UpdateWorkouts;
