import { useEffect } from "react";
import { useHistory } from "react-router";
import { HTTPErrorCallback } from "helpers/errorHelper";
import commonRoutes from "Routes/Components/Common/routes";
import noAuthRoutes from "Routes/Components/Common/routes";
import UseServices from "hooks/UseServices";
import { showNotification } from "helpers/notificationHelper";
import Input from "Components/Input";
import Button from "Components/Button";
import LoginForm from "elements/LoginForm";
import { useForm } from "react-hook-form";

import * as S from "./styles";
import "twin.macro";

const Login = () => {
  const { register, handleSubmit } = useForm();

  const history = useHistory();
  const { api } = UseServices();

  useEffect(() => {
    if (localStorage.getItem("userJWTToken"))
      history.push(commonRoutes.Home.path);

    //eslint-disable-next-line
  }, []);

  const sendLogin = (data) => {
    if (!data.email)
      return showNotification("warning", "Atenção!", "Informe seu E-mail!");
    if (!data.password)
      return showNotification("warning", "Atenção!", "Informe sua senha!");

    api
      .post("/sessions/admin_login", data)
      .then((response) => {
        localStorage.setItem("userJWTToken", response.data.token);
        window.location.reload();
      })
      .catch((error) => HTTPErrorCallback(error));
  };

  return (
    <>
      <S.LoginLayout>
        <S.LeftWrapper>
          <S.Logo src={process.env.PUBLIC_URL + "/images/logo.png"} />
        </S.LeftWrapper>
        <S.RightWrapper>
          <S.HeaderImage
            src={process.env.PUBLIC_URL + "/images/login-header.png"}
          />
          <LoginForm onSubmit={handleSubmit(sendLogin)}>
            <div>
              <h1>Área Administrativa</h1>
              <p>Bem vindo a área administrativa</p>
            </div>
            <Input
              label="E-mail"
              placeholder="Login"
              name="email"
              type="email"
              required
              icon="email"
              register={register}
            />
            <Input
              label="Senha"
              placeholder="Senha"
              name="password"
              type="password"
              required
              icon="password"
              register={register}
            />
            <Button type="submit">Entrar</Button>
            <S.UnderlineButton href={noAuthRoutes.Home.path}>
              Área Comum
            </S.UnderlineButton>
          </LoginForm>
        </S.RightWrapper>
      </S.LoginLayout>
    </>
  );
};

export default Login;
