import React from "react";
import getIcon from "helpers/getIcon";

import * as S from "./styles";

const StatusTag = ({ type, icon, title, component, infos = [] }) => {
  return (
    <S.Wrapper type={type} icon={icon}>
      {icon && (
        <>
          <S.Icon>{getIcon(icon || "status-" + type)}</S.Icon>
          <S.Line type={type} />
        </>
      )}
      <S.RightWrapper>
        {component ? (
          component
        ) : (
          <S.Title>
            <p>{title}</p>
          </S.Title>
        )}

        <S.Infos>
          {infos.map((info) => (
            <S.CreationInfo
              key={info.label}
              onClick={() => {
                navigator.clipboard.writeText(info.value);
              }}
            >
              {info.label}: <span>{info.value}</span>
            </S.CreationInfo>
          ))}
        </S.Infos>
      </S.RightWrapper>
    </S.Wrapper>
  );
};

export default StatusTag;
