import React from "react";
import { useHistory } from "react-router";

import adminRoutes from "Routes/Components/Admin/routes";
import UseServices from "hooks/UseServices";
import SidebarButton from "Components/Admin/Buttons/SidebarButton";

import * as S from "./styles";
import { isMobile } from "react-device-detect";

const SideBar = ({ opened, hoverOpened, setOpened }) => {
  const history = useHistory();

  const { parsedJWT } = UseServices();

  const logout = () => {
    localStorage.removeItem("userJWTToken");
    window.location.reload();
  };

  const navItems = [
    {
      icon: "dashboard",
      label: "Dashboard",
      active: window.location.pathname === adminRoutes.Home.path,
      onClick: () => history.push(adminRoutes.Home.path),
    },
    {
      icon: "user",
      label: "Usuários",
      active: window.location.pathname === adminRoutes.ListUsers.path,
      onClick: () => history.push(adminRoutes.ListUsers.path),
      subItems: [
        {
          label: "Criar",
          onClick: () => history.push(adminRoutes.CreateUser.path),
        },
        {
          label: "Estudantes",
          onClick: () => history.push(adminRoutes.ListStudents.path),
        },
        {
          label: "Professores",
          onClick: () => history.push(adminRoutes.ListTeachers.path),
        },
      ],
    },
    {
      icon: "video",
      label: "Vídeos",
      active: window.location.pathname === adminRoutes.ListVideos.path,
      onClick: () => history.push(adminRoutes.ListVideos.path),
      subItems: [
        {
          label: "Criar",
          onClick: () => history.push(adminRoutes.CreateVideo.path),
        },
      ],
    },
    {
      icon: "menweight",
      label: "Exercícios",
      active: window.location.pathname === adminRoutes.ListExercises.path,
      onClick: () => history.push(adminRoutes.ListExercises.path),
      subItems: [
        {
          label: "Criar",
          onClick: () => history.push(adminRoutes.CreateExercise.path),
        },
      ],
    },
    {
      icon: "exercise",
      label: "Treinos",
      active: window.location.pathname === adminRoutes.ListWorkouts.path,
      onClick: () => history.push(adminRoutes.ListWorkouts.path),
      subItems: [
        {
          label: "Criar",
          onClick: () => history.push(adminRoutes.CreateWorkout.path),
        },
      ],
    },
    {
      icon: "star",
      label: "Arquivos",
      active: window.location.pathname === adminRoutes.ListFeedbacks.path,
      onClick: () => history.push(adminRoutes.ListFeedbacks.path),
      subItems: [
        {
          label: "Criar",
          onClick: () => history.push(adminRoutes.CreateFeedback.path),
        },
      ],
    },
  ];

  return (
    <S.Wrapper hoverOpened={hoverOpened} opened={opened}>
      <S.Header hoverOpened={hoverOpened} opened={opened}>
        <S.ProfilePhoto
          src={process.env.PUBLIC_URL + "/images/logo.png"}
          alt="Profile Picture"
        />
        <S.Credentials hoverOpened={hoverOpened} opened={opened}>
          <S.Name title={parsedJWT.full_name}>{parsedJWT.full_name}</S.Name>
          <S.Email title={parsedJWT.email}>{parsedJWT.email}</S.Email>
        </S.Credentials>
      </S.Header>
      <S.Navbar hoverOpened={hoverOpened} opened={opened}>
        {navItems.map((navItem, i) => (
          <SidebarButton
            key={`${navItem.label}${i}`}
            subItems={navItem.subItems}
            active={navItem.active}
            activeDropdown={navItem.activeDropdown}
            icon={navItem.icon}
            label={navItem.label}
            onClick={() => {
              navItem.onClick();
              isMobile && setOpened(!opened);
            }}
            setOpened={setOpened}
            opened={opened}
            hoverOpened={hoverOpened}
          />
        ))}
      </S.Navbar>
      <S.Navbar hoverOpened={hoverOpened} opened={opened}>
        <SidebarButton
          onClick={() => logout()}
          icon="logout"
          label="Sair"
          hoverOpened={hoverOpened}
          opened={opened}
        />
      </S.Navbar>
    </S.Wrapper>
  );
};

export default SideBar;
