import styled from "styled-components/macro";

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  overflow: hidden;
  background-color: #f7f8f8;
  height: 100%;
  overflow-y: auto;
  z-index: 0;

  ${(props) => {
    if (props.profile)
      return `
          &::before {
            content: '';
            width: 100%;
            height: 30px;
            position: absolute;
            background-color: black;
          }
      `;
  }};
`;
export const ContentInner = styled.div`
  padding: 0px 2.4rem;
  padding-top: ${(props) => (props.withDetails ? "40px" : "70px")};

  position: relative;
  z-index: 1;
  max-height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  overflow-y: overlay;
  height: var(--app-height);
  padding-bottom: 20px;

  @media (max-width: 768px) {
    padding: 0 10px;
  }

  ${(props) => {
    if (props.profile)
      return `

padding-top: 0px;

z-index: 2;

`;
  }}
`;

export const FakeHeaderWrapper = styled.div`
  height: 30px;
`;
export const HeaderWrapper = styled.div`
  display: grid;
  position: relative;
  grid-gap: 20px;
  grid-template-columns: ${(props) =>
    props.columns ? props.columns : "repeat(auto-fit, minmax(100px, 1fr))"};

  /* height: 80px; */
  margin: 0 40px;
  margin-top: 25px;

  z-index: 2;
  &::after {
    content: "";
    position: absolute;
    left: -64px;
    height: 50px;
    bottom: -10px;
    width: calc(100% + 75px);
    background: ${({ theme }) =>
      `linear-gradient(0deg, rgba(0,0,0,0) 0%,  #F7F8F8 50%)`};
    z-index: -1;
  }

  margin-bottom: -40px;

  @media (max-width: 768px) {
    grid-template-columns: auto;
    margin: 0;
    &::after {
      display: none;
    }
  }
`;

export const InlineWithGridArea = styled.div`
  display: grid;
  grid-area: ${(props) => props.gridarea};
  grid-template-columns: ${(props) => props.columns};
  grid-gap: ${(props) => props.gap || "4px"};
`;

export const PriceWrapper = styled.div`
  margin-top: ${(props) => (props.mt ? props.mt : "30px")};
  width: 100%;
`;
export const ProfileWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  border-radius: 8px;
  overflow: hidden;
`;
