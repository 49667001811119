import React from "react";

const Time = ({ color }) => {
  return (
    <svg
      width="21"
      height="25"
      viewBox="0 0 21 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 22.3327C8.33407 22.3327 6.25684 21.4723 4.72529 19.9407C3.19375 18.4092 2.33333 16.332 2.33333 14.166C2.33333 12.0001 3.19375 9.92286 4.72529 8.39131C6.25684 6.85976 8.33407 5.99935 10.5 5.99935C12.6659 5.99935 14.7432 6.85976 16.2747 8.39131C17.8063 9.92286 18.6667 12.0001 18.6667 14.166C18.6667 16.332 17.8063 18.4092 16.2747 19.9407C14.7432 21.4723 12.6659 22.3327 10.5 22.3327ZM18.7017 7.62102L20.3583 5.96435C19.8333 5.36935 19.3083 4.83268 18.7133 4.31935L17.0567 5.99935C15.2483 4.52935 12.9733 3.66602 10.5 3.66602C7.71523 3.66602 5.04451 4.77226 3.07538 6.74139C1.10625 8.71053 0 11.3812 0 14.166C0 16.9508 1.10625 19.6215 3.07538 21.5906C5.04451 23.5598 7.71523 24.666 10.5 24.666C16.3333 24.666 21 19.9643 21 14.166C21 11.6927 20.1367 9.41768 18.7017 7.62102ZM9.33333 15.3327H11.6667V8.33268H9.33333V15.3327ZM14 0.166016H7V2.49935H14V0.166016Z"
        fill={color}
      />
    </svg>
  );
};
export default Time;
