import DataTable from "react-data-table-component";
import * as S from "./styles";
import NoDataMessage from "../NoDataMessage";
import { useEffect, useState } from "react";
import Header from "./Components/Header";

const Table = ({
  data,
  mt = "20px",
  columns,
  downloadColumns,
  pagination,
  loading,
  dataTableProps,
  customHeader,
  fileName,
  refresh,
  onSubmitFilter,
  filters,
  onFullData,
}) => {
  const customStyles = {};
  const [searchedData, setSearchedData] = useState(data);

  useEffect(() => {
    setSearchedData(data);
  }, [data]);

  return (
    <S.Wrapper mt={mt}>
      {customHeader && (
        <Header
          filters={filters}
          mb="20px"
          onSubmitFilter={onSubmitFilter}
          rawData={data}
          searchedData={searchedData}
          setSearchedData={setSearchedData}
          downloadColumns={downloadColumns}
          columns={columns}
          fileName={fileName}
          refresh={refresh}
          onFullData={onFullData}
        />
      )}
      <DataTable
        data={searchedData}
        noDataComponent={NoDataMessage}
        columns={columns}
        pagination={pagination}
        progressPending={loading}
        customStyles={customStyles}
        responsive
        {...dataTableProps}
      />
    </S.Wrapper>
  );
};

export default Table;
