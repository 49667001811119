import { styled } from "twin.macro";

export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background: #00000080;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
`;

export const Body = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  width: 100%;
`;

export const Sequence = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;
export const SequenceName = styled.div`
  color: #1d9d61;
  font-weight: bold;
  font-size: 25px;
  margin-top: 20px;
`;
export const SequenceHeader = styled.div`
  padding: 25px;
  border-radius: 16px;
  background-color: #1d9d6190;
  width: 80%;
  text-align: center;
  color: rgb(29 22 23 / var(--tw-text-opacity));
`;

export const VideoWrapper = styled.div`
  width: 650px;
  border-radius: 20px;
  border: 1.5px solid #1d9d61;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 670px) {
    width: auto;
  }
`;

export const Video = styled.video`
  background-color: transparent;
  object-fit: contain;
  border-radius: 28px 28px 0px 0px;
  border-radius: 16px;
  height: 60vh;
`;

export const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const Details = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 5px;
  background-color: transparent;
  border-radius: 0 0 28px 28px;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding-right: 20px;
  cursor: pointer;
`;

export const Title = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2px 10px 2px 20px;
  border-radius: 0 10px 0 0;
  background-color: #00000050;
  color: white;
`;

export const Repetitions = styled.div`
  background: white;
  height: fit-content;
  padding: 5px;
  border-radius: 0px 0px 10px 10px;
  background-color: #ffffff;
`;
export const BreakTime = styled.div`
  background: white;
  height: fit-content;
  padding: 5px;
  border-radius: 0px 0px 10px 10px;
  background-color: #ffffff;
`;
