import Dashboard from "Pages/Admin/Dashboard";
import CreateUser from "Pages/Admin/Users/Create";
import CreateVideo from "Pages/Admin/Videos/Create";
import Users from "Pages/Admin/Users/List";
import Videos from "Pages/Admin/Videos/List";
import ListStudents from "Pages/Admin/Users/ListStudents";
import ListTeachers from "Pages/Admin/Users/ListTeachers";
import Exercises from "Pages/Admin/Exercises/List";
import UpdateExercise from "Pages/Admin/Exercises/Create";
import Workouts from "Pages/Admin/Workouts/List";
import CreateWorkout from "Pages/Admin/Workouts/Create";
import CreateFeedback from "Pages/Admin/Feedbacks/Create";
import Feedbacks from "Pages/Admin/Feedbacks/List";

const routes = {
  Home: {
    path: "/admin/dashboard",
    exact: true,
    component: Dashboard,
  },
  ListUsers: {
    path: "/admin/users",
    exact: true,
    component: Users,
  },
  ListStudents: {
    path: "/admin/users/sudents",
    exact: true,
    component: ListStudents,
  },
  ListTeachers: {
    path: "/admin/users/teachers",
    exact: true,
    component: ListTeachers,
  },
  CreateUser: {
    path: "/admin/users/create",
    exact: true,
    component: CreateUser,
  },
  ListVideos: {
    path: "/admin/videos",
    exact: true,
    component: Videos,
  },
  ListExercises: {
    path: "/admin/exercises",
    exact: true,
    component: Exercises,
  },
  CreateExercise: {
    path: "/admin/exercises/create",
    exact: true,
    component: UpdateExercise,
  },
  ListWorkouts: {
    path: "/admin/workouts",
    exact: true,
    component: Workouts,
  },
  CreateWorkout: {
    path: "/admin/workouts/create",
    exact: true,
    component: CreateWorkout,
  },
  ListFeedbacks: {
    path: "/admin/feedbacks",
    exact: true,
    component: Feedbacks,
  },
  CreateFeedback: {
    path: "/admin/feedbacks/create",
    exact: true,
    component: CreateFeedback,
  },
  CreateVideo: {
    path: "/admin/videos/create",
    exact: true,
    component: CreateVideo,
  },
};

export default routes;
