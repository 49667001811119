import React from "react";

const Site = ({ color }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.86 12C14.94 11.34 15 10.68 15 10C15 9.32 14.94 8.66 14.86 8H18.24C18.4 8.64 18.5 9.31 18.5 10C18.5 10.69 18.4 11.36 18.24 12M13.09 17.56C13.69 16.45 14.15 15.25 14.47 14H17.42C16.4512 15.6683 14.9141 16.932 13.09 17.56ZM12.84 12H8.16C8.06 11.34 8 10.68 8 10C8 9.32 8.06 8.65 8.16 8H12.84C12.93 8.65 13 9.32 13 10C13 10.68 12.93 11.34 12.84 12ZM10.5 17.96C9.67 16.76 9 15.43 8.59 14H12.41C12 15.43 11.33 16.76 10.5 17.96ZM6.5 6H3.58C4.53886 4.32721 6.0748 3.06149 7.9 2.44C7.3 3.55 6.85 4.75 6.5 6ZM3.58 14H6.5C6.85 15.25 7.3 16.45 7.9 17.56C6.07862 16.9317 4.54485 15.6677 3.58 14ZM2.76 12C2.6 11.36 2.5 10.69 2.5 10C2.5 9.31 2.6 8.64 2.76 8H6.14C6.06 8.66 6 9.32 6 10C6 10.68 6.06 11.34 6.14 12M10.5 2.03C11.33 3.23 12 4.57 12.41 6H8.59C9 4.57 9.67 3.23 10.5 2.03ZM17.42 6H14.47C14.157 4.76146 13.6936 3.5659 13.09 2.44C14.93 3.07 16.46 4.34 17.42 6ZM10.5 0C4.97 0 0.5 4.5 0.5 10C0.5 12.6522 1.55357 15.1957 3.42893 17.0711C4.35752 17.9997 5.45991 18.7362 6.67317 19.2388C7.88642 19.7413 9.18678 20 10.5 20C13.1522 20 15.6957 18.9464 17.5711 17.0711C19.4464 15.1957 20.5 12.6522 20.5 10C20.5 8.68678 20.2413 7.38642 19.7388 6.17317C19.2362 4.95991 18.4997 3.85752 17.5711 2.92893C16.6425 2.00035 15.5401 1.26375 14.3268 0.761205C13.1136 0.258658 11.8132 0 10.5 0Z"
        fill={color}
      />
    </svg>
  );
};
export default Site;
