import * as S from "./styles";
import "twin.macro";
import Table from "Components/Common/Table";
import { toDateTime } from "helpers/formatHelper";
import { useEffect } from "react";
import { useState } from "react";
import UseServices from "hooks/UseServices";
import { HTTPErrorCallback } from "helpers/errorHelper";
import CreateStudent from "./CreateStudent";
import ShowStudent from "./ShowStudent";

const Students = () => {
  const { api } = UseServices();
  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null)

  useEffect(() => {
    getStudents();
    //eslint-disable-next-line
  }, []);

  const getStudents = () => {
    setLoading(true);
    api
      .get(`/users/own_students`)
      .then((response) => {
        const { users } = response.data;
        setStudents(users);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  return (
    <S.Content>
      {selectedStudent ? (
        <ShowStudent id={selectedStudent} setSelectedStudent={setSelectedStudent} />
      ) : (
        <S.ContentInner>
          <S.ContentArea>
            <Table
              loading={loading}
              columns={[
                {
                  Header: "Nome",
                  accessor: "full_name",
                },
                {
                  Header: "E-mail",
                  accessor: "email",
                },
                {
                  Header: "Último Login",
                  accessor: "last_login",
                  Cell: ({ value }) => (value ? toDateTime(value) : "N/A"),
                },
                {
                  Header: "Ativo",
                  accessor: "active",
                  Cell: ({ value }) => (value ? "SIM" : "NÃO"),
                },
                {
                  Header: "Criado em",
                  accessor: "createdAt",
                  Cell: ({ value }) => (value ? toDateTime(value) : "N/A"),
                },
              ]}
              data={students}
              onRowClick={(r) => setSelectedStudent(r.original.id)}
            />
          </S.ContentArea>
          <S.ContentArea>
            <CreateStudent afterCreate={getStudents} />
          </S.ContentArea>
        </S.ContentInner>
      )}
    </S.Content>
  );
};

export default Students;
