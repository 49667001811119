import { Form } from "@unform/web";
import Input from "Components/Admin/Inputs/Input";
import ContainerWithTitle from "Components/Admin/ContainerWithTitle";
import StatusTag from "Components/Admin/StatusTag";
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from "styles/layouts/admin/wrappers";
import Button from "Components/Admin/Buttons/Button";
import * as S from "./styles.js";
import { useState } from "react";
import Video from "../Components/Video";
import UseServices from "hooks/UseServices.js";
import { HTTPErrorCallback } from "helpers/errorHelper.js";
import { showNotification } from "helpers/notificationHelper.js";
import SectionLoading from "Components/Global/SectionLoading/index.js";
import GenerateThumbnail from "Components/Global/GenerateThumbnail/index.jsx";

const CreateVideo = () => {
  const { api } = UseServices();
  const [loading, setLoading] = useState(false);
  const [showGenerateThumbnail, setShowGenerateThumbnail] = useState(false);
  const [data, setData] = useState({ title: "", description: "" });
  const [files, setFiles] = useState({
    videoFileName: null,
    videoPreviewFile: null,
    videoFile: null,
    thumbnailFileName: null,
    thumbnailPreviewFile: null,
    thumbnailFile: null,
  });

  const create = (submited_data) => {
    if (!files.thumbnailFile) {
      return setShowGenerateThumbnail(true);
    }
    submited_data.video = files.videoFile;
    submited_data.thumbnail = files.thumbnailFile;

    createRequest(submited_data);
  };

  const createRequest = (submited_data) => {
    setLoading(true);

    const formData = new FormData();
    for (const key of Object.keys(submited_data))
      formData.append(key, submited_data[key]);

    api
      .post("/videos/create", formData)
      .then(() => {
        showNotification("success", "Sucesso!", "Video criado com sucesso!");
        setLoading(false);
        setShowGenerateThumbnail(false)
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const selectFile = (event, fileNameKey, filePreviewKey, fileKey) => {
    const fileChosed = event?.target?.files[0];

    if (!fileChosed) return;

    setFiles((prev) => {
      return { ...prev, [fileNameKey]: fileChosed.name };
    });

    setFiles((prev) => {
      return { ...prev, [fileKey]: fileChosed };
    });

    if (fileNameKey.includes("video")) {
      const source = document.getElementById("video_source");
      source.innerHTML = "";
      const newSource = document.createElement("source");
      newSource.src = URL.createObjectURL(fileChosed);
      source.appendChild(newSource);
      source.load();
    } else {
      const reader = new FileReader();
      reader.onload = (e) =>
        setFiles((prev) => {
          return { ...prev, [filePreviewKey]: e.target.result };
        });

      reader.readAsDataURL(fileChosed);
    }
  };

  return (
    <>
      {showGenerateThumbnail && (
        <GenerateThumbnail
          data={data}
          video={files.videoFile}
          submit={createRequest}
          close={() => setShowGenerateThumbnail(false)}
        />
      )}
      {loading && <SectionLoading />}
      <HeaderWrapper>
        <StatusTag icon="camera" title="Criar Vídeo" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <S.Wrapper>
              <Form onSubmit={create}>
                <Input
                  label="Título"
                  name="title"
                  required
                  onChange={(e) => setData({ ...data, title: e.target.value })}
                />
                <Input
                  label="Descrição"
                  name="description"
                  required
                  onChange={(e) =>
                    setData({ ...data, description: e.target.value })
                  }
                />
                <S.Upload>
                  <input
                    type="file"
                    id="video"
                    accept="video/mp4,video/x-m4v,video/mov,video/quicktime,video/x-quicktime,video/quicktime,video/3g2,video/3gp,video/3gp2,video/asf,video/asx,video/avi,video/m4v,video/mpe,video/mpg,video/*"
                    onChange={(e) =>
                      selectFile(
                        e,
                        "videoFileName",
                        "videoPreviewFile",
                        "videoFile"
                      )
                    }
                  />
                  <label htmlFor="video">
                    {files.videoFileName || "Selecione um vídeo"}
                  </label>
                </S.Upload>
                <S.Upload>
                  <input
                    type="file"
                    accept="image/*"
                    id="thumbnail"
                    onChange={(e) =>
                      selectFile(
                        e,
                        "thumbnailFileName",
                        "thumbnailPreviewFile",
                        "thumbnailFile"
                      )
                    }
                  />
                  <label htmlFor="thumbnail">
                    {files.thumbnailFileName || "Selecione uma thumbnail"}
                  </label>
                </S.Upload>
                <Button type="submit">Salvar</Button>
              </Form>

              <Video {...data} thumbnail={files.thumbnailPreviewFile} />
            </S.Wrapper>
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default CreateVideo;
