import "twin.macro";
import * as S from "../../styles";
import UseServices from "hooks/UseServices";
import { HTTPErrorCallback } from "helpers/errorHelper";
import Button from "Components/Button";
import { showNotification } from "helpers/notificationHelper";
import { useState } from "react";

const Image = ({ data, setLoading }) => {
  const { api, getProfile } = UseServices();
  const [profileFile, setProfileFile] = useState(null);
  const [profileImage, setProfileImage] = useState(null);

  const updatePassword = () => {
    const formData = new FormData();

    formData.append("image_src", profileFile);

    setLoading(true);
    api
      .put("/users", formData)
      .then(() => {
        showNotification("success", "Sucesso!", "Foto de perfil alterada!");
        setLoading(false);
        getProfile();
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const selectImage = (event) => {
    const file = event?.target?.files[0];

    if (!file) return setProfileImage(null);

    if (file.size > 25000000) {
      return showNotification(
        "danger",
        "Atenção",
        "A imagem deve conter no máximo 25mb"
      );
    }

    const reader = new FileReader();
    reader.onload = (e) => setProfileImage(e.target.result);
    setProfileImage(reader.readAsDataURL(file));
    setProfileFile(file);
  };

  return (
    <S.Wrapper>
      <h3>Imagem</h3>
      <S.Form>
        <S.Profile src={profileImage || data.image_src} />
        <input
          type="file"
          id="image_src"
          hidden
          onChange={selectImage}
          accept="image/*"
        />
        <S.Label htmlFor="image_src">
          {profileImage ? "Selecionar outra" : "Alterar imagem"}
        </S.Label>
        {profileImage && (
          <Button type="button" onClick={updatePassword}>
            Salvar imagem
          </Button>
        )}
      </S.Form>
    </S.Wrapper>
  );
};

export default Image;
