import styled from "styled-components";

export const VideoWrapper = styled.div`
  border-radius: 28px;
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  max-width: 400px;
`;

export const ThumbnailWrapper = styled.div`
  position: relative;
  height: 200px;
  aspect-ratio: 16/9;
`;

export const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Play = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  aspect-ratio: 1;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 28px 28px 0px 0px ;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`;
