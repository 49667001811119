import React from "react";

const Whats = ({ color }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5467 0.333321C6.17666 0.333321 0.984996 5.52499 0.984996 11.895C0.984996 13.9367 1.52166 15.92 2.525 17.67L0.891663 23.6667L7.01666 22.0567C8.70833 22.9783 10.61 23.4683 12.5467 23.4683C18.9167 23.4683 24.1083 18.2767 24.1083 11.9067C24.1083 8.81499 22.9067 5.90999 20.725 3.72832C19.6554 2.6479 18.3814 1.79127 16.9772 1.20838C15.5731 0.625495 14.067 0.328025 12.5467 0.333321V0.333321ZM12.5583 2.28166C15.125 2.28166 17.5283 3.28499 19.3483 5.10499C20.2415 5.9979 20.9497 7.05836 21.4322 8.22554C21.9147 9.39273 22.162 10.6437 22.16 11.9067C22.16 17.2033 17.8433 21.5083 12.5467 21.5083C10.82 21.5083 9.12833 21.0533 7.65833 20.1667L7.30833 19.9683L3.66833 20.925L4.63666 17.3783L4.40333 17.005C3.43959 15.4751 2.92984 13.7031 2.93333 11.895C2.945 6.59832 7.25 2.28166 12.5583 2.28166V2.28166ZM8.45166 6.55166C8.265 6.55166 7.95 6.62166 7.68166 6.91332C7.425 7.20499 6.66666 7.91666 6.66666 9.32832C6.66666 10.7517 7.705 12.1167 7.83333 12.315C7.99666 12.5133 9.88666 15.43 12.7917 16.6667C13.48 16.9817 14.0167 17.1567 14.4367 17.285C15.125 17.5067 15.755 17.4717 16.2567 17.4017C16.8167 17.32 17.96 16.7017 18.205 16.025C18.45 15.3483 18.45 14.7767 18.38 14.6483C18.2983 14.5317 18.1117 14.4617 17.82 14.3333C17.5283 14.17 16.105 13.47 15.8483 13.3767C15.58 13.2833 15.4167 13.2367 15.195 13.5167C15.0083 13.8083 14.4483 14.4617 14.285 14.6483C14.11 14.8467 13.9467 14.87 13.6667 14.73C13.3633 14.5783 12.43 14.275 11.3333 13.295C10.47 12.525 9.89833 11.58 9.72333 11.2883C9.58333 11.0083 9.71166 10.8333 9.85166 10.705C9.98 10.5767 10.1667 10.3667 10.2833 10.1917C10.435 10.0283 10.4817 9.89999 10.575 9.71332C10.6683 9.51499 10.6217 9.35166 10.5517 9.21166C10.4817 9.08332 9.89833 7.63666 9.65333 7.06499C9.42 6.50499 9.18666 6.57499 9 6.56332C8.83666 6.56332 8.65 6.55166 8.45166 6.55166V6.55166Z"
        fill={color}
      />
    </svg>
  );
};
export default Whats;
