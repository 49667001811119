import Table from "Components/Admin/Table";
import { HTTPErrorCallback } from "helpers/errorHelper";
import { toDateTime } from "helpers/formatHelper";
import UseServices from "hooks/UseServices";
import { useEffect, useState } from "react";
import { columns, downloadColumns } from "./columns";
import Button from "Components/Admin/Buttons/Button";
import getIcon from "helpers/getIcon";
import ReplaceTeacher from "../AddStudent";

export const selectedStudentTemplate = {
  student_id: null,
  teacher_student_id: null,
};

const Students = ({ teacher_id, teacher_name }) => {
  const { api } = UseServices();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(
    selectedStudentTemplate
  );
  const [changeTeacher, setChangeTeacher] = useState(false);

  useEffect(() => {
    getStudents();
    //eslint-disable-next-line
  }, []);

  const getStudents = () => {
    setLoading(true);
    api
      .get(`/users/admin/students/${teacher_id}`)
      .then((response) => {
        setUsers(response.data.users);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <>
      <Table
        data={users}
        columns={[
          ...columns,
          {
            name: "Ações",
            cell: (r) => (
              <Button
                title={"Remover"}
                type="button"
                model="other"
                onClick={() => {
                  setChangeTeacher(true);
                  setSelectedStudent({
                    student_id: r.id,
                    teacher_student_id: r.teacher_student_id,
                  });
                }}
              >
                {getIcon("trash", "red")}
              </Button>
            ),
          },
        ]}
        loading={loading}
        customHeader
        downloadColumns={downloadColumns}
        dataTableProps={{ pagination: true }}
        fileName={`Estudantes <${teacher_name}> ${toDateTime(new Date())}`}
        refresh={getStudents}
      />

      <ReplaceTeacher
        open={changeTeacher}
        teacherStudentId={selectedStudent.teacher_student_id}
        studentId={selectedStudent.student_id}
        currentTeacherId={teacher_id}
        teacherId={teacher_id}
        setOpen={(open) => {
          !open && setSelectedStudent(selectedStudentTemplate);
          setChangeTeacher(open);
          getStudents();
        }}
      />
    </>
  );
};

export default Students;
