import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as S from "./styles";
import getIcon from "helpers/getIcon";

const SidebarButton = ({
  title,
  setOpenedMenu,
  opened,
  path,
  icon,
  onClick = () => { },
}) => {

  
  const history = useHistory();
  const active = window.location.pathname === path;

  const handleClick = (e) => {
    setOpenedMenu(false);
    if (path)
      history.push(path);

    onClick(e);
  };

  return (
    <>
      <S.Wrapper onClick={handleClick}>
        <S.ButtonInner active={active}>
          <S.ButtonIcon>{getIcon(icon)}</S.ButtonIcon>
          <S.ButtonTitle>{title}</S.ButtonTitle>
        </S.ButtonInner>
        <S.ButtonGroup>
          <S.Button></S.Button>
        </S.ButtonGroup>
      </S.Wrapper>
    </>
  );
};
export default SidebarButton;
