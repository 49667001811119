import { useState } from "react";
import { Form } from "@unform/web";
import Select from "Components/Admin/Inputs/Select";
import { useEffect } from "react";
import UseServices from "hooks/UseServices";
import { transformArrayIntoSelectOptions } from "helpers/util";
import { HTTPErrorCallback } from "helpers/errorHelper";
import Button from "Components/Admin/Buttons/Button";
import { useRef } from "react";
import * as S from "./styles";
import { showNotification } from "helpers/notificationHelper";

const AddStudent = ({ open, setOpen, teacher_id }) => {
  const { api } = UseServices();
  const [nameOrEmail, setNameOrEmail] = useState("f");
  const [loading, setLoading] = useState(false);
  const [student, setStudent] = useState(null);
  const [options, setOptions] = useState([]);
  const dialogRef = useRef(null);

  useEffect(() => {
    if (!dialogRef?.current) return;

    if (open) dialogRef.current.showModal();
    else dialogRef.current.close();
  }, [open]);

  useEffect(() => {
    if (!nameOrEmail) return;

    api
      .get(`/users/admin/students_by_name?name_or_email=${nameOrEmail}`)
      .then((response) => {
        setOptions(
          transformArrayIntoSelectOptions(
            response.data,
            (u) => u.full_name,
            (u) => {
              return {
                user_id: u.id,
                teacher_student_id: u.teacher_student_id,
              };
            }
          )
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  }, [nameOrEmail, api]);

  const appendStudent = () => {
    console.log(student);
    api
      .put("/users/admin/change_student_teacher", {
        teacher_student_id: student.teacher_student_id,
        new_student_user_id: student.user_id,
        new_teacher_user_id: teacher_id,
      })
      .then((response) => {
        showNotification(
          "success",
          "Boa!",
          "Estudante anexado ao novo professor!"
        );
        setOpen(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
      });
  };

  return (
    <S.Dialog ref={dialogRef}>
      <h1>Escreva um nome ou e-mail para buscar um estudante</h1>
      <Form onSubmit={appendStudent}>
        <Select
          isLoading={loading}
          label="Nome Ou Email"
          name="student"
          onChange={(v) => setStudent(v.value)}
          onInputChange={(e) => setNameOrEmail(e)}
          options={options}
        />
        <Button type="submit">Anexar Estudante</Button>
        <Button type="button" onClick={() => setOpen(false)}>
          Cancelar
        </Button>
      </Form>
    </S.Dialog>
  );
};

export default AddStudent;
