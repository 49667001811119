import styled from "styled-components/macro";

export const Wrapper = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: ${({ haveHeader }) =>
    haveHeader ? "50px auto" : "auto"};
  border-radius: 8px;
  background-color:#FAFAFA;
  margin-top: ${(props) => (props.mt ? props.mt : "30px")};
`;

export const FullscreenButton = styled.div``;
export const Icon = styled.div``;
export const Title = styled.div`
  place-self: center start;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  color: #323133;
`;
export const Header = styled.div`
  padding: 10px 22px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto 1fr auto;
  place-items: center;
  border-bottom: 1px solid #ADADAD;
`;
export const Content = styled.div`
  padding: 20px 23px;
  overflow: hidden;

  @media (max-width: 768px) {
    overflow-x: scroll;
    padding: 5px 10px;
  }
`;
