import { css } from "@emotion/react";
import tw, { styled } from "twin.macro";

export const Wrapper = styled.div`
  ${tw`w-full h-full bg-white flex items-center py-4 px-8 gap-10 justify-between`}
  ${css`
    grid-area: header;

    @media screen and (max-width: 670px) {
      justify-content: space-between;
      padding: 10px 2rem;
    }
  `}
`;
export const PageTitle = styled.div`
  ${tw`w-full text-[28px] text-black font-bold`}
  ${css`
    @media screen and (max-width: 670px) {
      display: none;
    }
  `}
`;

export const Logo = styled.img`
  display: none;

  @media screen and (max-width: 670px) {
    display: block;
    height: 90px;
  }
`;
export const User = styled.div`
  ${tw`flex justify-center items-center gap-4 min-w-[300px] relative`}
  ${css`
    @media screen and (max-width: 670px) {
      width: auto;
      min-width: auto;
    }
  `}
`;

export const Edit = styled.img`
  ${css`
    position: absolute;
    top: 0%;
    right: 0%;
    cursor: pointer;

    @media screen and (max-width: 670px) {
      display: none;
    }
  `}
`;

export const UserImage = styled.img`
  ${tw`w-16 h-16 object-cover rounded-[16px]`}
`;
export const UserCredentials = styled.div`
  h2 {
    ${tw`font-bold`}
  }
  p {
    ${tw`text-sm`}

    &:last-child {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 670px) {
    display: none;
  }
`;

export const Hamburguer = styled.button`
  ${tw`grid gap-1`}
  ${css`
    span {
      ${tw`bg-black`}
      width: 30px;
      height: 4px;
      border-radius: 50px;
    }
  `}
  ${({ opened }) =>
    opened &&
    tw`

    `}
`;
