import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: ${({ center }) => center && "center"};

  form {
    flex: 1;
    display: grid;
    grid-template-columns: ${({ columns }) => (columns ? columns : "auto")};
    grid-template-rows: ${({ rows }) => (rows ? rows : "auto")};
    gap: ${({ rows, columns }) => (rows || columns ? "10px" : "0")};
    justify-items: ${({ center }) => center && "center"};
    place-items: ${({ placeItems }) => placeItems && placeItems};
    max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "100%")};
    margin: 0 auto;

    @media (max-width: 768px) {
      max-width: 100%;
    }

    #file_input {
      display: none;
    }

    label[for="file_input"] {
      padding: 6px 16px;
      display: grid;
      align-content: center;
      font-size: 14px;
      font-weight: bold;
      height: 40px;
      max-height: 40px;

      text-transform: uppercase;

      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
      color: #323133;

      transition: ease 0.3s all;
      cursor: pointer;

      border: none;
      border-radius: 4px;
      background-color: #1d9d61;
      border-color: #1d9d61;

      /* MODELOS - default, secondary outline, other  */
      &:focus {
        box-shadow: 0px 0px 0 2px #323133;
        outline: none;
      }
    }
  }
`;
