import { Global, css } from "@emotion/react";
import { createGlobalStyle } from "styled-components";
import { GlobalStyles as BaseStyles } from "twin.macro";

const FontStyles = createGlobalStyle`
   @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
`;
const customStyles = css({
  "html, body, #__next": {
    height: "100%",
    padding: 0,
    margin: 0,
    "font-family": `
      'Poppins',
      sans-serif
    `,
  },
  a: {
    color: "inherit",
    "text-decoration": "none",
  },
  "*": {
    "box-sizing": "border-box",
  },
});

const GlobalStyles = () => (
  <>
    <FontStyles />
    <BaseStyles />
    <Global styles={customStyles} />
  </>
);

export default GlobalStyles;
