import React from "react";

const NoData = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.78988 0.73999L0.379883 2.14999L3.11988 4.88999L2.99988 5.00999V16C2.99988 17.1 3.89988 18 4.99988 18H14.9999C15.3499 18 15.6799 17.9 15.9699 17.74L17.8499 19.62L19.2599 18.21L1.78988 0.73999ZM4.99988 16V6.76999L14.2299 16H4.99988Z"
        fill={color}
      />
      <path
        d="M8.8402 2H15.0002V11.11L17.0002 13.11V2C17.0002 0.9 16.1002 0 15.0002 0H8.0102L5.9502 2.06L7.3602 3.47L8.8402 2Z"
        fill={color}
      />
    </svg>
  );
};
export default NoData;
