import { css } from "@emotion/react";
import tw, { styled } from "twin.macro";

export const Input = styled.input`
  ${tw`w-full bg-transparent focus:outline-none p-3.5 `}
`;
export const Wrapper = styled.div`
  ${tw`w-full bg-lightGray rounded-xl h-full grid place-items-center`}
  ${({ icon }) =>
    icon &&
    css`
      grid-template-columns: 30px 1fr;
      grid-gap: 10px;
    `}
`;

export const Icon = styled.div`
  ${tw`p-3.5 bg-white w-12 rounded-full h-full grid place-items-center`}
`;
