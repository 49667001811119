import React from "react";

const Access = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25 4.5H6.75V6.75L8.025 6.75C8.85 4.95 10.725 3.75 12.75 3.75C15.675 3.75 18 6.075 18 9C18 11.925 15.675 14.25 12.75 14.25C10.725 14.25 8.85 13.05 8.025 11.25H0V6.75H2.25V4.5ZM5.25 6H3.75L3.75 8.25H1.5V9.75L9.075 9.75L9.225 10.275C9.75 11.775 11.175 12.75 12.75 12.75C14.85 12.75 16.5 11.1 16.5 9C16.5 6.9 14.85 5.25 12.75 5.25C11.175 5.25 9.75 6.225 9.225 7.725L9.075 8.25L5.25 8.25L5.25 6ZM12.75 6.75C14.025 6.75 15 7.725 15 9C15 10.275 14.025 11.25 12.75 11.25C11.475 11.25 10.5 10.275 10.5 9C10.5 7.725 11.475 6.75 12.75 6.75ZM12.75 9.75C13.2 9.75 13.5 9.45 13.5 9C13.5 8.55 13.2 8.25 12.75 8.25C12.3 8.25 12 8.55 12 9C12 9.45 12.3 9.75 12.75 9.75Z"
        fill={color}
      />
    </svg>
  );
};
export default Access;
