const Icon = (props) => {
  return (
    <>
      {(() => {
        switch (props.icon) {
          case "email":
            return (
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.427 5.63831L10.0945 8.34808C9.46492 8.84759 8.57908 8.84759 7.94946 8.34808L4.58887 5.63831"
                  stroke="#7B6F72"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.6816 14.75C14.9627 14.7563 16.5 12.8822 16.5 10.5788V5.42751C16.5 3.12412 14.9627 1.25 12.6816 1.25H5.31835C3.03734 1.25 1.5 3.12412 1.5 5.42751V10.5788C1.5 12.8822 3.03734 14.7563 5.31835 14.75H12.6816Z"
                  stroke="#7B6F72"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          case "name":
            return (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.833374 15C0.833374 13.9391 1.2548 12.9217 2.00495 12.1716C2.75509 11.4214 3.77251 11 4.83337 11H12.8334C13.8942 11 14.9117 11.4214 15.6618 12.1716C16.4119 12.9217 16.8334 13.9391 16.8334 15C16.8334 15.5304 16.6227 16.0391 16.2476 16.4142C15.8725 16.7893 15.3638 17 14.8334 17H2.83337C2.30294 17 1.79423 16.7893 1.41916 16.4142C1.04409 16.0391 0.833374 15.5304 0.833374 15Z"
                  stroke="#7B6F72"
                  strokeWidth="0.666667"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.83325 7C10.4901 7 11.8333 5.65685 11.8333 4C11.8333 2.34314 10.4901 1 8.83325 1C7.1764 1 5.83325 2.34314 5.83325 4C5.83325 5.65685 7.1764 7 8.83325 7Z"
                  stroke="#7B6F72"
                  strokeWidth="0.666667"
                />
              </svg>
            );
          case "password":
            return (
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3176 6.08579V4.47554C10.3176 2.59079 8.78906 1.06229 6.90431 1.06229C5.01956 1.05404 3.48506 2.57504 3.47681 4.46054V4.47554V6.08579"
                  stroke="#7B6F72"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.76249 14.9371H4.03174C2.46124 14.9371 1.18774 13.6643 1.18774 12.0931V8.87635C1.18774 7.3051 2.46124 6.03235 4.03174 6.03235H9.76249C11.333 6.03235 12.6065 7.3051 12.6065 8.87635V12.0931C12.6065 13.6643 11.333 14.9371 9.76249 14.9371Z"
                  stroke="#7B6F72"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.89722 9.65198V11.3177"
                  stroke="#7B6F72"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          default:
            return null;
        }
      })()}
    </>
  );
};
export default Icon;
