import styled from "styled-components";

export const Content = styled.div`
  display: grid;
  grid-auto-flow: column;

  grid-gap: 40px;
  grid-auto-columns: max-content;
  grid-template-columns: ${(props) => props.columns};
`;

export const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-gap: 20px;
  background-color: #c4c4c420;
  border-bottom: 1px solid #c4c4c4;
  padding: 20px;
  border-radius: 12px;
  flex: 1;
`;

export const Title = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  place-items: center;
`;

export const Header = styled.div`
  border-bottom: 1px solid #c4c4c460;
  padding-bottom: 10px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr auto;
  width: 100%;
  grid-gap: 20px;
  place-items: center start;
  label {
    font-size: 1.5rem;
    font-weight: 700;
  }
  input,
  form {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 190px;
    grid-gap: 20px;
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-gap: 30px;
  width: 100%;
  .link {
    font-size: 13px;
    text-decoration: underline;
    color: #858585;
    cursor: pointer;
  }
`;
