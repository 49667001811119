import { Form } from "@unform/web";
import Input from "Components/Admin/Inputs/Input";
import Button from "Components/Admin/Buttons/Button";
import * as S from "./styles.js";
import { useEffect, useState } from "react";
import UseServices from "hooks/UseServices.js";
import { HTTPErrorCallback } from "helpers/errorHelper.js";
import { showNotification } from "helpers/notificationHelper.js";
import SectionLoading from "Components/Global/SectionLoading/index.js";
import { transformArrayIntoSelectOptions } from "helpers/util.js";
import Select from "Components/Admin/Inputs/Select/index.js";

const UpdateFeedback = ({ feedback }) => {
  const { api } = UseServices();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);

  useEffect(() => {
    const file_name =
      feedback.file_src.split("/")[feedback.file_src.split("/").length - 1];
    const selectedStudent = students.find(
      (s) => s.value === feedback.student_id
    );
    setSelectedStudent(selectedStudent);
    setFile({ name: file_name });
  }, [feedback, students]);

  useEffect(() => {
    getStudents();
    //eslint-disable-next-line
  }, []);

  const getStudents = async () => {
    try {
      setLoading(true);
      const responses = await Promise.all([api.get(`/users/admin/students`)]);
      const students = responses[0]?.data?.users;

      setStudents(
        transformArrayIntoSelectOptions(
          students,
          (a) => a.full_name,
          (a) => a.id
        )
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showNotification("danger", "Atenção", "Houve um erro ao buscar alunos");
    }
  };

  const update = (data) => {
    setLoading(true);
    setFile(null);

    const submited_data = { ...data, file, studentId: selectedStudent.value };

    const formData = new FormData();
    for (const key of Object.keys(submited_data))
      formData.append(key, submited_data[key]);

    api
      .put(`/feedbacks/${feedback.id}`, formData)
      .then(() => {
        showNotification(
          "success",
          "Sucesso!",
          `Arquivo alterado com sucesso para ${selectedStudent.full_name}!`
        );
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const selectFile = (event) => {
    const fileChosed = event?.target?.files[0];

    if (!fileChosed) return;

    setFile(fileChosed);
  };

  return (
    <>
      {loading && <SectionLoading />}
      <S.Wrapper>
        <Form onSubmit={update}>
          <Input
            label="Título"
            name="title"
            required
            defaultValue={feedback.title}
          />
          <S.Upload>
            <input
              type="file"
              id="feedback_file"
              onChange={(e) => selectFile(e)}
            />
            <label htmlFor="feedback_file">
              {file?.name || "Selecione um Arquivo"}
            </label>
          </S.Upload>

          {students.length ? (
            <Select
              options={students}
              value={selectedStudent}
              onChange={(e) => setSelectedStudent(e)}
              name="student"
            />
          ) : (
            <></>
          )}

          <Button type="submit">Salvar</Button>
        </Form>
      </S.Wrapper>
    </>
  );
};

export default UpdateFeedback;
