import tw, { styled, css } from "twin.macro";

export const Content = styled.div`
  ${tw`w-full p-4 h-full py-10 overflow-auto`}
  ${css`
    grid-area: content;
  `}
`;

export const Wrapper = styled.div`
  ${tw`bg-white w-full p-5 h-full rounded-[16px]`}
  ${css`
    h3 {
      font-size: 17px;
      font-weight: bold;
      text-decoration: underline;
      text-align: center;
      color: rgb(29, 22, 23);
      margin-bottom: 12px;
    }
    label {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  `}
`;

export const ProfileWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;

  @media screen and (max-width: 670px) {
      display: flex;
      flex-direction: column;
    }
`;

export const Form = styled.form`
  ${css`
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: center;
    padding: 12px;

    label,
    button {
      width: 80%;
      margin-top: 12px;
      text-align: center;
    }
  `}
`;

export const Label = styled.label`
  ${tw`p-3.5 bg-gradient-to-r font-bold from-secondary to-primary rounded-full h-full grid text-white drop-shadow cursor-pointer`}
`;

export const Profile = styled.img`
  height: 120px;
  width: 120px;
  border-radius: 16px;
  object-fit: cover;
`;

export const ColorFulSpan = styled.span`
  color: ${(props) => (props.valid ? "green" : "red")};
`;
export const SafePassword = styled.ol`
  margin-top: 10px;
  list-style-position: inside;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

  li {
    display: table-row;
    &::before {
      display: table-cell; /* aha! */
      text-align: right;
    }
  }
`;
