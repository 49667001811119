import tw, { styled } from "twin.macro";

export const LeftWrapper = styled.div`
  ${tw`w-full bg-black h-full bg-white hidden md:grid place-items-center`}
`;
export const HeaderImage = styled.img`
  ${tw`top-0 select-none`}
`;

export const Logo = styled.img`
  ${tw`relative z-1`}
`;

export const RightWrapper = styled.div`
  ${tw`w-full relative bg-white h-full drop-shadow-2xl`}
`;

export const LoginLayout = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  height: 100vh;
  max-height: 100vh;
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 420px;
  }
`;

export const UnderlineButton = styled.a`
  ${tw`underline text-sm`}
`;
