import React from "react";

const Download = ({ color }) => {
  return (
    <svg
      width="19"
      height="23"
      viewBox="0 0 19 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.166748 22.6667H18.8334V20H0.166748V22.6667ZM18.8334 8H13.5001V0H5.50008V8H0.166748L9.50008 17.3333L18.8334 8Z"
        fill={color}
      />
    </svg>
  );
};
export default Download;
