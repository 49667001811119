import * as S from "./styles";

const Button = (props) => {
  return (
    <>
      <S.Wrapper icon={props.icon} {...props}>
        {props.icon && <S.Icon />}
        <S.Text>{props.children}</S.Text>
      </S.Wrapper>
    </>
  );
};
export default Button;
