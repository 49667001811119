import SearchBar from "../SearchBar";
import { PriceGrid } from "styles/layouts/admin/grids";
import Download from "../Download";
import getIcon from "helpers/getIcon";
import Button from "Components/Admin/Buttons/Button";

const Header = ({
  mb = "20px",
  rawData,
  searchedData,
  setSearchedData,
  columns,
  downloadColumns,
  fileName,
  refresh = () => {},
  onFullData,
}) => {
  return (
    <>
      <PriceGrid columns="300px 1fr 50px" mb={mb}>
        <SearchBar
          rawData={rawData}
          setSearchResults={setSearchedData}
          columns={columns}
        />
        <Download
          rawData={rawData}
          onFullData={onFullData}
          searchedData={searchedData}
          columns={downloadColumns ? downloadColumns : columns}
          fileName={fileName}
        />
        <Button
          small
          width="50px"
          style={{ placeSelf: "end" }}
          onClick={() => refresh()}
        >
          {getIcon("refresh", '#FFF')}
        </Button>
      </PriceGrid>
    </>
  );
};

export default Header;
