import styled from "styled-components/macro";

export const NoDataMessage = styled.div`
  padding: 20px 10px;
  display: grid;
  grid-template-rows: 1fr;
  place-items: center;

  img {
    width: 60px;
  }
`;

export const Wrapper = styled.div`
  margin-top: ${(props) => props.mt};
  .rdt_Table {
    margin-bottom: 1rem;
    text-align: center;
    background-color: transparent;
  }
  .rdt_TableRow {
    background-color: transparent;
    border-radius: 8px;

    border: 1.5px solid #999999;

    margin: 5px 0px;
    &:hover {
      background-color: #EBEBEB;
    }
  }
  .rdt_TableCol {
    display: flex;
    justify-content: center;
    color:#FAFAFA;
    transition: ease 0.3s all;

    &:hover {
      color: #D6D6D6;
      * {
        color: #FFECC5;
      }
    }
    text-transform: uppercase;

    text-align: center;

    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
  }
  .rdt_TableCol_Sortable {
  }
  .rdt_TableCell {
    color: #323133;
    justify-content: center;
    //styleName: Text-bold-wide;

    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;

    * {
      text-align: center;
    }
  }
  .rdt_TableHeader {
    background-color: transparent;
  }
  .rdt_TableFooter {
  }
  .rdt_TableHead {
  }
  .rdt_TableHeadRow {
    margin: 5px 0px;
    border-radius: 8px;
    background-color: #999999;
  }
  .rdt_TableBody {
  }
  .rdt_ExpanderRow {
  }
`;
