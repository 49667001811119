import { useEffect, useState } from "react";
import StatusTag from "Components/Admin/StatusTag";
import { HTTPErrorCallback } from "helpers/errorHelper";
import UseServices from "hooks/UseServices";
import { ContentWrapper, HeaderWrapper } from "styles/layouts/admin/wrappers";
import { ContentInner } from "styles/layouts/admin/wrappers";
import ContainerWithTitle from "Components/Admin/ContainerWithTitle";
import { Infos } from "./styles";
import InfosRow from "./Components/Row";
import Info from "./Components/Info";
import adminRoutes from "../../../Routes/Components/Admin/routes";
import SectionLoading from "Components/Global/SectionLoading";

const Dashboard = () => {
  const { api } = UseServices();
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDashboard();
    //eslint-disable-next-line
  }, []);

  const getDashboard = () => {
    setLoading(true);
    api
      .get("/users/admin/dashboard")
      .then((response) => {
        setDashboardData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <>
      <HeaderWrapper>
        <StatusTag icon="dashboard" title="Dashboard" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <Infos>
              <InfosRow
                label="Usuários"
                link={adminRoutes.ListUsers.path}
                icon="user"
              >
                {loading && <SectionLoading />}
                <Info
                  number={dashboardData?.total_students ?? 0}
                  labelComponent={
                    <>
                      Total de <span>estudantes</span>
                    </>
                  }
                />
                <Info
                  number={dashboardData?.total_teachers ?? 0}
                  labelComponent={
                    <>
                      Total de <span>professores</span>
                    </>
                  }
                />
              </InfosRow>
              <InfosRow
                label="Vídeos"
                link={adminRoutes.ListVideos.path}
                icon="camera"
              >
                {loading && <SectionLoading />}
                <Info
                  number={dashboardData?.total_videos ?? 0}
                  labelComponent={
                    <>
                      Total de <span>vídeos</span>
                    </>
                  }
                />
              </InfosRow>
            </Infos>
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default Dashboard;
