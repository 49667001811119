import React from "react";

const StatusApproved = ({ color }) => {
  return (
    <svg
      width="30"
      height="27"
      viewBox="0 0 30 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 6.83335L20 15.5L25 11L23.8333 16.8334H6.16667L5 11L10 15.5L15 6.83335ZM15 0.166687L9.16667 10.1667L0 1.83335L3.33333 20.1667H26.6667L30 1.83335L20.8333 10.1667L15 0.166687ZM26.6667 23.5H3.33333V25.1667C3.33333 26.1667 4 26.8334 5 26.8334H25C26 26.8334 26.6667 26.1667 26.6667 25.1667V23.5Z"
        fill={color}
      />
    </svg>
  );
};
export default StatusApproved;
