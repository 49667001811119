import getIcon from "helpers/getIcon";
import { useHistory } from "react-router-dom";
import * as S from "./styles";

const InfosRow = ({ icon, children, columns, label, link }) => {
  const history = useHistory();

  return (
    <>
      <S.Wrapper>
        <S.Header>
          <S.Title>
            {getIcon(icon)}
            <label>{label}</label>
          </S.Title>
        </S.Header>
        <S.ContentWrapper>
          <S.Content columns={columns}>{children}</S.Content>
          <span className="link" onClick={() => history.push(link)}>
            Ir para página de {label}
          </span>
        </S.ContentWrapper>
      </S.Wrapper>
    </>
  );
};
export default InfosRow;
