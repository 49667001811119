import React from "react";

const Survey = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 0H18C18.5304 0 19.0391 0.210714 19.4142 0.585786C19.7893 0.960859 20 1.46957 20 2V14C20 14.5304 19.7893 15.0391 19.4142 15.4142C19.0391 15.7893 18.5304 16 18 16H11.9L8.2 19.71C8 19.9 7.75 20 7.5 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0V0ZM10.19 3.5C9.3 3.5 8.59 3.68 8.05 4.04C7.5 4.4 7.22 5 7.27 5.69H9.24C9.24 5.41 9.34 5.2 9.5 5.06C9.7 4.92 9.92 4.85 10.19 4.85C10.5 4.85 10.77 4.93 10.95 5.11C11.13 5.28 11.22 5.5 11.22 5.8C11.22 6.08 11.14 6.33 11 6.54C10.83 6.76 10.62 6.94 10.36 7.08C9.84 7.4 9.5 7.68 9.29 7.92C9.1 8.16 9 8.5 9 9H11C11 8.72 11.05 8.5 11.14 8.32C11.23 8.15 11.4 8 11.66 7.85C12.12 7.64 12.5 7.36 12.79 7C13.08 6.63 13.23 6.24 13.23 5.8C13.23 5.1 12.96 4.54 12.42 4.12C11.88 3.71 11.13 3.5 10.19 3.5V3.5ZM9 10V12H11V10H9Z"
        fill={color}
      />
    </svg>
  );
};
export default Survey;
