import React from "react";

const Calculator = ({ color }) => {
  return (
    <svg
      width="13"
      height="19"
      viewBox="0 0 13 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.125 0.75H10.875C11.3391 0.75 11.7842 0.934374 12.1124 1.26256C12.4406 1.59075 12.625 2.03587 12.625 2.5V16.5C12.625 16.9641 12.4406 17.4092 12.1124 17.7374C11.7842 18.0656 11.3391 18.25 10.875 18.25H2.125C1.66087 18.25 1.21575 18.0656 0.887563 17.7374C0.559374 17.4092 0.375 16.9641 0.375 16.5V2.5C0.375 2.03587 0.559374 1.59075 0.887563 1.26256C1.21575 0.934374 1.66087 0.75 2.125 0.75ZM2.125 2.5V6H10.875V2.5H2.125ZM2.125 7.75V9.5H3.875V7.75H2.125ZM5.625 7.75V9.5H7.375V7.75H5.625ZM9.125 7.75V9.5H10.875V7.75H9.125ZM2.125 11.25V13H3.875V11.25H2.125ZM5.625 11.25V13H7.375V11.25H5.625ZM9.125 11.25V13H10.875V11.25H9.125ZM2.125 14.75V16.5H3.875V14.75H2.125ZM5.625 14.75V16.5H7.375V14.75H5.625ZM9.125 14.75V16.5H10.875V14.75H9.125Z"
        fill={color}
      />
    </svg>
  );
};
export default Calculator;
