import { Form } from "@unform/web";
import Input from "Components/Admin/Inputs/Input";
import ContainerWithTitle from "Components/Admin/ContainerWithTitle";
import Button from "Components/Admin/Buttons/Button";
import * as S from "./styles.js";
import { useState } from "react";
import UseServices from "hooks/UseServices.js";
import { showNotification } from "helpers/notificationHelper.js";
import SectionLoading from "Components/Global/SectionLoading/index.js";
import { useEffect } from "react";
import Table from "Components/Admin/Table/index.js";
import { toDateTime } from "helpers/formatHelper.js";
import Select from "Components/Admin/Inputs/Select/index.js";
import Sequence from "../Components/Sequence/index.js";
import { HTTPErrorCallback } from "helpers/errorHelper.js";
import moment from "moment";
import { groupBy } from "helpers/util.js";

const UpdateWorkout = ({ id, setSelectedWorkouts }) => {
  const { api } = UseServices();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    title: "",
    description: "",
    startAt: "",
    finishAt: "",
    dificulty: "",
    goal: "",
  });
  const [exercises, setExercises] = useState([]);
  const [sequences, setSequences] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);

  useEffect(() => {
    getWorkout();

    //eslint-disable-next-line
  }, []);

  const getWorkout = () => {
    setLoading(true);
    api
      .get(`/workouts/list/${id}`)
      .then((response) => {
        const workout = response.data;

        setData({
          title: workout.title,
          description: workout.description,
          startAt: moment(workout.start_at).format("YYYY-MM-DD"),
          finishAt: moment(workout.finish_at).format("YYYY-MM-DD"),
          dificulty: { label: workout.dificulty, value: workout.dificulty },
          goal: { label: workout.goal, value: workout.goal },
        });

        const groupedBySequence = groupBy(
          workout.exercise_workouts,
          "sequence_id"
        );

        const sequences = [];

        for (const key of Object.keys(groupedBySequence)) {
          const item = groupedBySequence[key];
          const newSequence = item[0].sequence;

          newSequence.exercises = [];

          for (const subitem of item) {
            newSequence.exercises.push({
              //eslint-disable-next-line
              ...item.find((i) => i.exercise_id == subitem.exercise.id),
              ...subitem.exercise,
            });
          }

          sequences.push(newSequence);
        }

        setSequences(sequences);

        getExercisesAndStudents();
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  const getExercisesAndStudents = async () => {
    try {
      setLoading(true);
      const responses = await Promise.all([
        api.get(`/exercises/list`),
        api.get(`/users/admin/students`),
      ]);
      const exercises = responses[0]?.data?.exercises;
      const students = responses[1]?.data?.users;

      setExercises(exercises);
      setStudents(students);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showNotification(
        "danger",
        "Atenção",
        "Houve um erro ao buscar exercícios e alunos"
      );
    }
  };

  const update = async () => {
    setLoading(true);

    console.log(sequences);

    if (selectedStudents.length > 1)
      showNotification(
        "success",
        "Iniciando",
        `Iniciando criação de treino para ${selectedStudents.length} estudantes.`
      );

    for (let index = 0; index < selectedStudents.length; index++) {
      const student = selectedStudents[index];

      try {
        await api.put(`/workouts/${id}`, {
          ...data,
          studentId: student.id,
          sequences: sequences.map((sequence) => ({
            title: sequence.title,
            break_time: sequence.break_time,
            exercises: sequence.exercises.map((exercise) => ({
              id: exercise.id,
              weight: exercise.weight,
              repetitions: exercise.repetitions,
              break_time: exercise.break_time,
              series: exercise.series,
              exercise_id: exercise.id,
            })),
          })),
        });
        showNotification(
          "success",
          "Sucesso!",
          `Treino alterado com sucesso para o estudante ${student.full_name}!`
        );
      } catch (error) {
        console.log(error);
        showNotification(
          "danger",
          "Atenção!",
          `Houve um erro alterar treino para o estudante ${student.full_name}!`
        );
      }
    }

    setLoading(false);
  };

  const validadeAndInserWeights = () => {
    if (!sequences.length) {
      setLoading(false);
      return showNotification(
        "danger",
        "Atenção!",
        `Crie pelo menos uma sequência`
      );
    }

    if (!selectedStudents.length) {
      setLoading(false);
      return showNotification(
        "danger",
        "Atenção!",
        `Selecione pelo menos 1 estudante`
      );
    }

    update();
  };

  const addSequence = () => {
    setSequences((prev) => [
      ...prev,
      { id: Math.round(Math.random() * 99999) },
    ]);
  };

  const removeSequence = (index) => {
    const result = [];
    for (let i = 0; i < sequences.length; i++)
      if (index !== i) result.push(sequences[i]);

    setSequences(result);
  };

  const changeSequenceValue = (field, index, value) => {
    const result = [];
    for (let i = 0; i < sequences.length; i++)
      if (index === i) result.push({ ...sequences[i], [field]: value });
      else result.push(sequences[i]);

    setSequences(result);
  };

  const setExerciseToSequence = (sequenceIndex, exerciseIndex, value) => {
    const result = [];
    for (let i = 0; i < sequences.length; i++)
      if (sequenceIndex === i) {
        const exercises = [];
        for (let ie = 0; ie < sequences[i].exercises.length; ie++)
          if (exerciseIndex === ie)
            exercises.push({ ...sequences[i].exercises[ie], id: value });
          else exercises.push(sequences[i].exercises[ie]);

        result.push({ ...sequences[i], exercises });
      } else result.push(sequences[i]);

    setSequences(result);
  };

  const changeExerciseIntoSequence = (
    field,
    sequenceIndex,
    exerciseIndex,
    value
  ) => {
    const result = [];
    for (let i = 0; i < sequences.length; i++)
      if (sequenceIndex === i) {
        const exercises = [];
        for (let ie = 0; ie < sequences[i].exercises.length; ie++)
          if (exerciseIndex === ie)
            exercises.push({ ...sequences[i].exercises[ie], [field]: value });
          else exercises.push(sequences[i].exercises[ie]);

        result.push({ ...sequences[i], exercises });
      } else result.push(sequences[i]);

    setSequences(result);
  };

  const addExerciseToSequence = (index) => {
    const result = [];
    for (let i = 0; i < sequences.length; i++)
      if (index === i)
        result.push({
          ...sequences[i],
          exercises: sequences[i].exercises?.length
            ? [
                ...sequences[i].exercises,
                { fake_id: Math.round(Math.random() * 99999) },
              ]
            : [{ fake_id: Math.round(Math.random() * 99999) }],
        });
      else result.push(sequences[i]);

    setSequences(result);
  };

  const removeExerciseFromSequence = (sequenceIndex, exerciseIndex) => {
    const result = [];
    for (let i = 0; i < sequences.length; i++)
      if (sequenceIndex === i)
        result.push({
          ...sequences[i],
          exercises: sequences[i].exercises.filter(
            (v, i) => i !== exerciseIndex
          ),
        });
      else result.push(sequences[i]);

    setSequences(result);
  };

  return (
    <>
      {loading && <SectionLoading />}
      <ContainerWithTitle>
        <S.Title>Informações básicas</S.Title>
        <S.Wrapper>
          <Form onSubmit={validadeAndInserWeights}>
            <Input
              label="Título"
              name="title"
              required
              value={data.title}
              onChange={(e) => setData({ ...data, title: e.target.value })}
            />
            <Input
              label="Descrição"
              name="description"
              required
              value={data.description}
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
            />
            <Input
              label="Começa em"
              name="startAt"
              required
              type="date"
              value={data.startAt}
              onChange={(e) => setData({ ...data, startAt: e.target.value })}
            />
            <Input
              label="Finaliza em "
              name="finishAt"
              type="date"
              required
              value={data.finishAt}
              onChange={(e) => setData({ ...data, finishAt: e.target.value })}
            />
            <Select
              label="Dificuldade"
              name="dificulty"
              required
              value={
                typeof data.dificulty === "string"
                  ? { label: data.dificulty, value: data.dificulty }
                  : data.dificulty
              }
              onChange={(e) => {
                setData({ ...data, dificulty: e.value });
              }}
              options={[
                { label: "Adaptação", value: "Adaptação" },
                { label: "Iniciante", value: "Iniciante" },
                { label: "Intermediário", value: "Intermediário" },
                { label: "Avançado", value: "Avançado" },
              ]}
            />
            <Select
              label="Meta"
              name="goal"
              required
              value={
                typeof data.goal === "string"
                  ? { label: data.goal, value: data.goal }
                  : data.goal
              }
              onChange={(e) => {
                setData({ ...data, goal: e.value });
              }}
              options={[
                { label: "Hipertrofia", value: "Hipertrofia" },
                {
                  label: "Redução de gordura",
                  value: "Redução de gordura",
                },
                {
                  label: "Redução de gordura / Hipertrofia",
                  value: "Redução de gordura / Hipertrofia",
                },
                {
                  label: "Definição Muscular",
                  value: "Definição Muscular",
                },
                {
                  label: "Condicinamento Físico",
                  value: "Condicinamento Físico",
                },
                { label: "Qualidade de vida", value: "Qualidade de vida" },
              ]}
            />
          </Form>
        </S.Wrapper>
        <S.Title>Criar Sequencias</S.Title>
        <S.Sequences>
          {sequences.map((s, i) => (
            <Sequence
              sequence={s}
              index={i}
              changeSequenceValue={changeSequenceValue}
              setExerciseToSequence={setExerciseToSequence}
              changeExerciseIntoSequence={changeExerciseIntoSequence}
              removeExerciseFromSequence={removeExerciseFromSequence}
              addExerciseToSequence={addExerciseToSequence}
              removeSequence={removeSequence}
              exercises={exercises}
            />
          ))}
          <Button type="Button" onClick={() => addSequence()}>
            Adicionar Sequencia
          </Button>
        </S.Sequences>

        <S.Title>Selecione os estudantes</S.Title>
        <Table
          columns={[
            {
              name: "Nome",
              selector: (row) => row.full_name,
              sortable: true,
            },
            {
              name: "Email",
              selector: (row) => row.email,
              sortable: true,
            },
            {
              name: "Professor",
              selector: (row) => row.teacher?.full_name,
              sortable: true,
            },
            {
              name: "Último Login",
              selector: (row) =>
                row.last_login ? toDateTime(row.last_login) : "N/A",
              sortable: true,
            },
            {
              name: "Data de registro",
              selector: (row) =>
                row.createdAt ? toDateTime(row.createdAt) : "N/A",
              sortable: true,
            },
            {
              name: "Ativo",
              selector: (row) => (row.active ? "Sim" : "Não"),
              sortable: true,
            },
          ]}
          data={students}
          loading={loading}
          customHeader
          dataTableProps={{
            pagination: true,
            selectableRows: true,
            onSelectedRowsChange: (e) => setSelectedStudents(e.selectedRows),
          }}
          fileName={`Estudantes ${toDateTime(new Date())}`}
          refresh={getExercisesAndStudents}
        />
        <Button
          style={{ marginLeft: "95%" }}
          type="submit"
          onClick={validadeAndInserWeights}
        >
          Salvar
        </Button>
      </ContainerWithTitle>
    </>
  );
};

export default UpdateWorkout;
