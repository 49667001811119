import { useEffect, useState } from "react";
import StatusTag from "Components/Admin/StatusTag";
import { HTTPErrorCallback } from "helpers/errorHelper";
import UseServices from "hooks/UseServices";
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from "styles/layouts/admin/wrappers";
import ContainerWithTitle from "Components/Admin/ContainerWithTitle";
import { columns, downloadColumns } from "./columns";
import Table from "Components/Admin/Table";
import { toDateTime } from "helpers/formatHelper";
import Actions from "./Actions";
import UpdateExercise from "../Update";

const Exercises = () => {
  const { api } = UseServices();
  const [exercises, setExercises] = useState([]);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!selectedExercise) getExercises();
    //eslint-disable-next-line
  }, [selectedExercise]);

  const getExercises = () => {
    setLoading(true);
    api
      .get(`/exercises/list`)
      .then((response) => {
        setExercises(response.data.exercises);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  return (
    <>
      <HeaderWrapper>
        <StatusTag
          icon="menweight"
          title="Relatório de Exercícios"
          infos={[{ label: "Total de Exercícios", value: exercises?.length }]}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            {selectedExercise ? (
              <UpdateExercise
                id={selectedExercise}
                setSelectedExercise={setSelectedExercise}
              />
            ) : (
              <Table
                data={exercises}
                columns={[
                  ...columns,
                  {
                    name: "Ações",
                    cell: (r) => (
                      <Actions
                        setLoading={setLoading}
                        getExercises={getExercises}
                        id={r.id}
                        setSelectedExercise={setSelectedExercise}
                      />
                    ),
                  },
                ]}
                loading={loading}
                customHeader
                downloadColumns={downloadColumns}
                dataTableProps={{ pagination: true }}
                fileName={`Exercícios ${toDateTime(new Date())}`}
                refresh={getExercises}
              />
            )}
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default Exercises;
