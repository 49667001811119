import styled from "styled-components/macro";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  place-items: start;
  max-width: ${(props) => props.maxWidth};
  width: ${(props) => (props.width ? props.width : "100%")};
  border: none;

  pointer-events: ${(props) => props.disabled && "none"};

  grid-area: ${(props) => props.gridarea};

  label {
    color: #323133;
    //styleName: paragraph-desktop;

    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 6px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;

    span {
      color: #dc4242;
    }
  }
  input {
    &::placeholder {
      color: #c2c2c2;
    }
    color: #323133;

    border: 1px solid #adadad;
    transition: ease 0.3s all;
    background-color: ${(props) => props.disabled && "#D6D6D6"};

    padding: 8px 12px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 24px;
    width: 100%;
    height: 40px;

    outline-color: #1d9d61;

    ${(props) => {
      if (props.large)
        return `
       padding: 10px 18px;
       font-size: 14px;
       line-height: 28px;

    `;
      else if (props.small)
        return `
        padding: 6px 14px;
        font-size: 12px;
        line-height: 20px;
    `;
      else if (props.cardToggle)
        return `
        padding: 6px 2px;
        max-width: 50px;
        line-height: 20px;
        text-align: center;
    `;
    }}

    ${(props) => {
      switch (props.alertType) {
        case "warning":
          return `
        border-color: #FFC64C;
        
        `;

        case "danger":
          return `         
          border-color: #DC4242;
          `;

        case "success":
          return `
            border-color: #36B550;
                `;
        case "info":
          return `
            border-color: #3FBCD1;
                `;

        default:
          return `


                `;
      }
    }}
  }
`;
export const Hide = styled.button`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
export const Input = styled.div`
  position: relative;
`;
export const Inner = styled.div`
  position: relative;
  display: ${(props) => props.add && "grid"};
  grid-template-columns: ${(props) => props.add && "auto auto"};
  grid-gap: 10px;
  input {
    padding-right: ${(props) => props.serverside && "40px"};
    &::placeholder {
      text-overflow: ellipsis;
    }
  }
  button {
    aspect-ratio: ${(props) => props.add && "1"};
    div {
      padding: ${(props) => props.add && "0"};
    }
  }

  width: 100%;
  ${(props) => {
    if (!props.showIncrement)
      return `
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    `;
  }}
`;
export const Message = styled.div`
  font-size: 12px;
  line-height: 15px;
  margin: 6px 8px;
  ${(props) => {
    switch (props.alertType) {
      case "warning":
        return `
        color:#FFC64C;
        
        `;

      case "danger":
        return `         
          color: #DC4242;
          `;

      case "success":
        return `
            color: #36B550;
                `;
      case "info":
        return `
            color: #3FBCD1;
                `;

      default:
        return `


                `;
    }
  }}
`;
export const CharLimit = styled.div`
  position: absolute;
  right: 5px;
  bottom: -6px;
  padding: 2px 6px;
  background-color: #99b5bf;
  color: #556b73;
  border-radius: 4px;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  transform: translateY(100%);
`;
export const ServerSideDropdown = styled.div`
  position: absolute;
  bottom: -10px;
  transform: translateY(calc(100% - 5px));
  background-color: white;
  display: grid;

  border-radius: 8px;

  width: 100%;
  overflow: hidden;

  max-height: 120px;
  overflow-y: auto;

  border: 1px solid ease 0.3s all;

  z-index: 1;
`;
export const ServerSideDropdownIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 7px;
`;
export const ServerSideDropdownItem = styled.button`
  padding: 8px 16px;

  width: 100%;
  text-align: left;
  transition: ease 0.3s all;
  line-height: auto;
  &:focus {
    outline: 0;
  }

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;

  &:hover,
  &:focus {
    background-color: #fede9c;
  }
`;
