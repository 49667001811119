import React from "react";

const NewProject = ({ color }) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 2.8125V7.1875H16.5V2.8125H9.5H2.5ZM12.6485 8.9375C13.292 8.65611 14.0028 8.5 14.75 8.5C16.0946 8.5 17.3212 9.00549 18.25 9.8368V2.08625C18.25 1.27922 17.5958 0.625 16.7888 0.625H15.3363H13.875H12.4138H10.9613H9.5H8.03875H6.58625H5.125H3.66375H2.21125C1.40422 0.625 0.75 1.27922 0.75 2.08625V14.625C0.75 15.0891 0.934374 15.5342 1.26256 15.8624C1.59075 16.1906 2.03587 16.375 2.5 16.375H10.2024C9.75567 15.6028 9.50001 14.7063 9.50001 13.75C9.50001 12.6071 9.86522 11.5495 10.4853 10.6875H10.375V8.9375H12.6485ZM2.5 14.1875H8.625V8.9375H2.5V14.1875Z"
        fill={color}
      />
      <path
        d="M15.625 10.25H13.875V12.875H11.25V14.625H13.875V17.25H15.625V14.625H18.25V12.875H15.625V10.25Z"
        fill={color}
      />
    </svg>
  );
};
export default NewProject;
