import React from "react";

const Emoji = ({ color }) => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5 3H18.5V0H17V3H14V4.5H17V7.5H18.5V4.5H21.5V3Z"
        fill={color}
      />
      <path
        d="M7.625 8.24997C7.25416 8.24997 6.89165 8.35994 6.58331 8.56596C6.27496 8.77199 6.03464 9.06483 5.89273 9.40744C5.75081 9.75005 5.71368 10.127 5.78603 10.4908C5.85838 10.8545 6.03695 11.1886 6.29918 11.4508C6.5614 11.713 6.89549 11.8916 7.25921 11.9639C7.62292 12.0363 7.99992 11.9992 8.34253 11.8572C8.68514 11.7153 8.97798 11.475 9.18401 11.1667C9.39003 10.8583 9.5 10.4958 9.5 10.125C9.502 9.87818 9.45486 9.63347 9.36135 9.40508C9.26783 9.17669 9.12979 8.9692 8.95528 8.79469C8.78077 8.62018 8.57328 8.48214 8.34489 8.38862C8.1165 8.2951 7.87179 8.24797 7.625 8.24997Z"
        fill={color}
      />
      <path
        d="M14.375 8.24997C14.0042 8.24997 13.6416 8.35994 13.3333 8.56596C13.025 8.77199 12.7846 9.06483 12.6427 9.40744C12.5008 9.75005 12.4637 10.127 12.536 10.4908C12.6084 10.8545 12.787 11.1886 13.0492 11.4508C13.3114 11.713 13.6455 11.8916 14.0092 11.9639C14.3729 12.0363 14.7499 11.9992 15.0925 11.8572C15.4351 11.7153 15.728 11.475 15.934 11.1667C16.14 10.8583 16.25 10.4958 16.25 10.125C16.252 9.87818 16.2049 9.63347 16.1113 9.40508C16.0178 9.17669 15.8798 8.9692 15.7053 8.79469C15.5308 8.62018 15.3233 8.48214 15.0949 8.38862C14.8665 8.2951 14.6218 8.24797 14.375 8.24997Z"
        fill={color}
      />
      <path
        d="M5.75 15C6.28913 15.9127 7.0569 16.6691 7.97755 17.1946C8.89821 17.72 9.93995 17.9964 11 17.9964C12.0601 17.9964 13.1018 17.72 14.0224 17.1946C14.9431 16.6691 15.7109 15.9127 16.25 15H5.75Z"
        fill={color}
      />
      <path
        d="M19.865 10.5C20.1724 12.3188 19.9136 14.1882 19.1234 15.8551C18.3333 17.5219 17.05 18.9057 15.4474 19.8192C13.8448 20.7326 12.0002 21.1315 10.1634 20.9619C8.32655 20.7923 6.58619 20.0623 5.17793 18.8709C3.76966 17.6795 2.7615 16.0841 2.28993 14.3008C1.81837 12.5174 1.90618 10.6322 2.54147 8.90042C3.17676 7.16862 4.32885 5.67385 5.84177 4.61849C7.35469 3.56312 9.15536 2.99813 11 3V1.5C8.9233 1.5 6.89323 2.11581 5.16652 3.26957C3.4398 4.42332 2.09399 6.0632 1.29927 7.98182C0.504549 9.90045 0.296614 12.0116 0.701759 14.0484C1.1069 16.0852 2.10693 17.9562 3.57538 19.4246C5.04383 20.8931 6.91476 21.8931 8.95156 22.2982C10.9884 22.7034 13.0996 22.4955 15.0182 21.7007C16.9368 20.906 18.5767 19.5602 19.7304 17.8335C20.8842 16.1068 21.5 14.0767 21.5 12C21.4942 11.4979 21.4541 10.9967 21.38 10.5H19.865Z"
        fill={color}
      />
    </svg>
  );
};
export default Emoji;
