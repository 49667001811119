import React from "react";

const Danger = ({ color }) => {
  return (
    <svg
      width="74"
      height="64"
      viewBox="0 0 74 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.3335 40.6666H33.6668V24H40.3335V40.6666ZM40.3335 54H33.6668V47.3333H40.3335V54ZM0.333496 64H73.6668L37.0002 0.666626L0.333496 64Z"
        fill={color}
      />
    </svg>
  );
};
export default Danger;
