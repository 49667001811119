import React from "react";
import Whats from "Components/Global/Icons/Whats";
import Chat from "Components/Global/Icons/Chat";
import Time from "Components/Global/Icons/Time";
import NoCam from "Components/Global/Icons/NoCam";
import Upload from "Components/Global/Icons/Upload";
import NewGuest from "Components/Global/Icons/NewGuest";
import Camera from "Components/Global/Icons/Camera";
import Star from "Components/Global/Icons/Star";
import FilledUser from "Components/Global/Icons/FilledUser";
import FilledHome from "Components/Global/Icons/FilledHome";
import BackArrow from "Components/Global/Icons/BackArrow";
import Url from "Components/Global/Icons/Url";
import Access from "Components/Global/Icons/Access";
import Add from "Components/Global/Icons/Add";
import Bv from "Components/Global/Icons/Bv";
import Note from "Components/Global/Icons/Note";
import CloseFullScreen from "Components/Global/Icons/CloseFullScreen";
import OpenFullScreen from "Components/Global/Icons/OpenFullScreen";
import Close from "Components/Global/Icons/Close";
import Actions from "Components/Global/Icons/Actions";
import Edit from "Components/Global/Icons/Edit";
import Duplicate from "Components/Global/Icons/Duplicate";
import Trash from "Components/Global/Icons/Trash";
import StatusSketch from "Components/Global/Icons/StatusSketch";
import StatusArchived from "Components/Global/Icons/StatusArchived";
import NewProject from "Components/Global/Icons/NewProject";
import Back from "Components/Global/Icons/Back";
import Save from "Components/Global/Icons/Save";
import Print from "Components/Global/Icons/Print";
import Price from "Components/Global/Icons/Price";
import Info from "Components/Global/Icons/Info";
import Success from "Components/Global/Icons/Success";
import Danger from "Components/Global/Icons/Danger";
import Warning from "Components/Global/Icons/Warning";
import StatusApproved from "Components/Global/Icons/StatusApproved";
import Config from "Components/Global/Icons/Config";
import Projects from "Components/Global/Icons/Projects";
import Logout from "Components/Global/Icons/Logout";
import Notification from "Components/Global/Icons/Notification";
import User from "Components/Global/Icons/User";
import Magnifier from "Components/Global/Icons/Magnifier";
import Home from "Components/Global/Icons/Home";
import Hamburguer from "Components/Global/Icons/Hamburguer";
import Arrow from "Components/Global/Icons/Arrow";
import Dashboard from "Components/Global/Icons/Dashboard";
import HideOn from "Components/Global/Icons/HideOn";
import HideOff from "Components/Global/Icons/HideOff";
import Calculator from "Components/Global/Icons/Calculator";
import Drag from "Components/Global/Icons/Drag";
import Refresh from "Components/Global/Icons/Refresh";
import Send from "Components/Global/Icons/Send";
import Emoji from "Components/Global/Icons/Emoji";
import VerticalDots from "Components/Global/Icons/VerticalDots";
import Agenda from "Components/Global/Icons/Agenda";
import Download from "Components/Global/Icons/Download";
import Disconnect from "Components/Global/Icons/Disconnect";
import NoData from "Components/Global/Icons/NoData";
import Interrogation from "Components/Global/Icons/Interrogation";
import Check from "Components/Global/Icons/Check";
import Survey from "Components/Global/Icons/Survey";
import LeftArrow from "Components/Global/Icons/LeftArrow";
import RightArrow from "Components/Global/Icons/RightArrow";
import ArrowDown from "Components/Global/Icons/ArrowDown";
import DownloadCloud from "Components/Global/Icons/DownloadCloud";
import Grid from "Components/Global/Icons/Grid";
import List from "Components/Global/Icons/List";
import Instagram from "Components/Global/Icons/Instagram";
import Site from "Components/Global/Icons/Site";
import LinkedIn from "Components/Global/Icons/LinkedIn";
import Mail from "Components/Global/Icons/Mail";
import Password from "Components/Global/Icons/Password";
import Exercise from "Components/Global/Icons/Exercise";
import Video from "Components/Global/Icons/Video";
import CreateExercise from "Components/Global/Icons/CreateExercise";
import MenWeight from "Components/Global/Icons/MenWeight";

const getIcon = (iconName, color, all) => {
  const fill =
    color ||
    (window.location.pathname.includes("admin") ? "#323133" : "#323133");

  const obj = {
    whats: <Whats color={fill} />,
    chat: <Chat color={fill} />,
    time: <Time color={fill} />,
    noCam: <NoCam color={fill} />,
    upload: <Upload color={fill} />,
    newGuest: <NewGuest color={fill} />,
    camera: <Camera color={fill} />,
    star: <Star color={fill} />,
    filedUser: <FilledUser color={fill} />,
    filedHome: <FilledHome color={fill} />,
    backArrow: <BackArrow color={fill} />,
    url: <Url color={fill} />,
    access: <Access color={fill} />,
    add: <Add color={fill} />,
    bv: <Bv color={fill} />,
    note: <Note color={fill} />,
    closeFullscreen: <CloseFullScreen color={fill} />,
    openFullscreen: <OpenFullScreen color={fill} />,
    close: <Close color={fill} />,
    actions: <Actions color={fill} />,
    edit: <Edit color={fill} />,
    duplicate: <Duplicate color={fill} />,
    trash: <Trash color={fill} />,
    statusSketch: <StatusSketch color={fill} />,
    statusArchived: <StatusArchived color={fill} />,
    newProject: <NewProject color={fill} />,
    back: <Back color={fill} />,
    save: <Save color={fill} />,
    print: <Print color={fill} />,
    price: <Price color={fill} />,
    info: <Info color={fill} />,
    success: <Success color={fill} />,
    danger: <Danger color={fill} />,
    warning: <Warning color={fill} />,
    statusApproved: <StatusApproved color={fill} />,
    config: <Config color={fill} />,
    projects: <Projects color={fill} />,
    logout: <Logout color={fill} />,
    notification: <Notification color={fill} />,
    user: <User color={fill} />,
    magnifier: <Magnifier color={fill} />,
    home: <Home color={fill} />,
    hamburguer: <Hamburguer color={fill} />,
    downArrow: <Arrow color={fill} />,
    dashboard: <Dashboard color={fill} />,
    hideOn: <HideOn color={fill} />,
    hideOff: <HideOff color={fill} />,
    calculator: <Calculator color={fill} />,
    drag: <Drag color={fill} />,
    refresh: <Refresh color={fill} />,
    send: <Send color={fill} />,
    emoji: <Emoji color={fill} />,
    verticalDots: <VerticalDots color={fill} />,
    agenda: <Agenda color={fill} />,
    download: <Download color={fill} />,
    disconnect: <Disconnect color={fill} />,
    noData: <NoData color={fill} />,
    interrogation: <Interrogation color={fill} />,
    check: <Check color={fill} />,
    survey: <Survey color={fill} />,
    leftArrow: <LeftArrow color={fill} />,
    rightArrow: <RightArrow color={fill} />,
    arrowDown: <ArrowDown color={fill} />,
    downloadCloud: <DownloadCloud color={fill} />,
    grid: <Grid color={fill} />,
    list: <List color={fill} />,
    instagram: <Instagram color={fill} />,
    site: <Site color={fill} />,
    linkedin: <LinkedIn color={fill} />,
    mail: <Mail color={fill} />,
    password: <Password color={fill} />,
    exercise: <Exercise color={fill} />,
    createexercise: <CreateExercise color={fill} />,
    video: <Video color={fill} />,
    menweight: <MenWeight color={fill} />,
  };

  return all ? obj : obj[iconName];
};

export default getIcon;
