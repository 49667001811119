import styled from "styled-components";

export const Dialog = styled.dialog`
  border-radius: 20px;
  width: 400px;

  &::backdrop {
    background-color: #00000080;
  }

  h1 {
    margin-bottom: 25px;
  }

  form {
    display: flex;
    gap: 20px;
    flex-direction: column;

    width: min;
  }
`;
