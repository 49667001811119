import Button from "Components/Admin/Buttons/Button";
import * as XLSX from "xlsx";

const Download = ({ rawData, searchedData, columns, fileName, onFullData }) => {
  const download = async (list, columns) => {
    if (onFullData) {
      list = await onFullData();
    }
    const dataToDownload = [];
    list.forEach((item) => {
      let finalObject = {};

      columns.forEach((column) => {
        finalObject[column.name] = column.selector(item);
      });
      return dataToDownload.push(finalObject);
    });

    const workSheet = XLSX.utils.json_to_sheet(dataToDownload);

    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet");

    XLSX.writeFile(workBook, `${fileName}.xlsx`);
  };

  return (
    <Button
      width="200px"
      style={{ placeSelf: "end" }}
      onClick={() => download(rawData, columns)}
    >
      Download
    </Button>
  );
};

export default Download;
