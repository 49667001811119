import { useState } from "react";
import SideBar from "Components/Admin/Sidebar";
import { ContentGrid, MainGrid } from "./grids";
import { isMobile } from "react-device-detect";

const Layout = ({ children }) => {
  const [opened, setOpened] = useState(!isMobile);
  const [hoverOpened, setHoverOpened] = useState(false);
  return (
    <>
      <MainGrid>
        <SideBar
          hoverOpened={hoverOpened}
          setHoverOpened={setHoverOpened}
          opened={opened}
          setOpened={setOpened}
        />
        <ContentGrid>{children}</ContentGrid>
      </MainGrid>
    </>
  );
};

export default Layout;
