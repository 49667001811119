import "twin.macro";
import UseServices from "hooks/UseServices";
import TeacherFeedbacks from "./Components/FeedbacksTeacher";
import StudentFeedbacks from "./Components/FeedbacksStudent";

const Feedbacks = () => {
  const { parsedJWT } = UseServices();

  const FeedbackComponent = (userType) => {
    switch (userType) {
      case 2:
      case "2":
        return <TeacherFeedbacks />;

      case 3:
      case "3":
        return <StudentFeedbacks />;

      default:
        return <h1>Carregando...</h1>;
    }
  };

  return FeedbackComponent(parsedJWT.user_type_id);
};

export default Feedbacks;
