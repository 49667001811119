import React from "react";

const VerticalDots = ({ color }) => {
  return (
    <svg
      width="6"
      height="20"
      viewBox="0 0 6 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.99984 14.6667C3.61868 14.6667 4.21217 14.9125 4.64975 15.3501C5.08734 15.7877 5.33317 16.3812 5.33317 17C5.33317 17.6188 5.08734 18.2123 4.64975 18.6499C4.21217 19.0875 3.61868 19.3333 2.99984 19.3333C2.381 19.3333 1.78751 19.0875 1.34992 18.6499C0.912337 18.2123 0.666504 17.6188 0.666504 17C0.666504 16.3812 0.912337 15.7877 1.34992 15.3501C1.78751 14.9125 2.381 14.6667 2.99984 14.6667ZM2.99984 7.66666C3.61868 7.66666 4.21217 7.9125 4.64975 8.35008C5.08734 8.78767 5.33317 9.38116 5.33317 10C5.33317 10.6188 5.08734 11.2123 4.64975 11.6499C4.21217 12.0875 3.61868 12.3333 2.99984 12.3333C2.381 12.3333 1.78751 12.0875 1.34992 11.6499C0.912337 11.2123 0.666504 10.6188 0.666504 10C0.666504 9.38116 0.912337 8.78767 1.34992 8.35008C1.78751 7.9125 2.381 7.66666 2.99984 7.66666ZM2.99984 0.666664C3.61868 0.666664 4.21217 0.912497 4.64975 1.35008C5.08734 1.78767 5.33317 2.38116 5.33317 3C5.33317 3.61884 5.08734 4.21233 4.64975 4.64991C4.21217 5.0875 3.61868 5.33333 2.99984 5.33333C2.381 5.33333 1.78751 5.0875 1.34992 4.64991C0.912337 4.21233 0.666504 3.61884 0.666504 3C0.666504 2.38116 0.912337 1.78767 1.34992 1.35008C1.78751 0.912497 2.381 0.666664 2.99984 0.666664Z"
        fill={color}
      />
    </svg>
  );
};
export default VerticalDots;
