import UseServices from "hooks/UseServices";
import SidebarButton from "./Button";
import * as S from "./styles";
import { PROFILES, TEACHER_ROUTES, STUDENT_ROUTES } from "Routes/Components/Common/routes";

const Sidebar = ({ setOpenedMenu, opened }) => {
  const { parsedJWT } = UseServices();

  const sidebarItems =
    parsedJWT.user_type_id === PROFILES.TEACHER
      ? TEACHER_ROUTES
      : STUDENT_ROUTES;

  return (
    <>
      <S.Wrapper opened={opened}>
        <S.LogoWrapper opened={opened}>
          <S.Logo
            src={process.env.PUBLIC_URL + "/images/logo.png"}
            alt="Viva Mais"
          />
        </S.LogoWrapper>
        <S.SidebarButtons>
          {sidebarItems.map((item) => (
            <SidebarButton
              setOpenedMenu={setOpenedMenu}
              opened={opened}
              path={item.path}
              title={item.getTitle ? item.getTitle(parsedJWT.user_type_id) :item.title}
              icon={item.getIcon ? item.getIcon(parsedJWT.user_type_id) :item.icon}
            />
          ))}
          <SidebarButton
            setOpenedMenu={setOpenedMenu}
            opened={opened}
            onClick={() => {
              localStorage.removeItem("userJWTToken");
              window.location.reload();
            }}
            title={"Sair"}
            icon={'logout'}
          />
        </S.SidebarButtons>
      </S.Wrapper>
    </>
  );
};
export default Sidebar;
