import "twin.macro";
import * as S from "../../styles";
import UseServices from "hooks/UseServices";
import { HTTPErrorCallback } from "helpers/errorHelper";
import { useForm } from "react-hook-form";
import Input from "Components/Input";
import Button from "Components/Button";
import { showNotification } from "helpers/notificationHelper";
import { useState } from "react";
import { getPasswordSecurityVerifications } from "helpers/util";

const Security = ({ data, setLoading }) => {
  const { api } = UseServices();
  const [passwordVerifications, setPasswordVerifications] = useState({});
  const { register, handleSubmit } = useForm();

  const updatePassword = (data) => {
    if (
      !passwordVerifications.hasEnoughLength ||
      !passwordVerifications.hasNumber ||
      !passwordVerifications.hasUppercase ||
      !passwordVerifications.hasSpecialCharacters
    ) {
      return showNotification(
        "danger",
        "Atenção!",
        'Para uma senha segura, certifique-se de ter pelo menos 8 caracteres, incluindo números, letra maiúscula, caracteres especiais como "@" ou "*".'
      );
    }

    if (data.password !== data.confirm_password)
      return showNotification("danger", "Atenção!", "As senhas não conferem.");
      
      setLoading(true);
      api
      .put("/users", data)
      .then((response) => {
        setLoading(false);
        showNotification("success", "Sucesso!", "Senha alterada com sucesso!");
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  console.log(passwordVerifications);

  return (
    <S.Wrapper>
      <h3>Segurança</h3>
      <S.Form onSubmit={handleSubmit(updatePassword)}>
        <Input
          label="Senha"
          placeholder="Senha"
          name="password"
          required
          icon="password"
          type="password"
          register={register}
          onChange={(e) => {
            console.log(e.target.value);
            setPasswordVerifications(
              getPasswordSecurityVerifications(e.target.value)
            );
          }}
        />
        <Input
          label="Confirmar senha"
          placeholder="Confirmar senha"
          name="confirm_password"
          required
          type="password"
          icon="password"
          register={register}
        />
        <Button type="submit">Alterar senha</Button>

        <S.SafePassword>
          <li>
            Para uma senha segura, certifique-se de ter{" "}
            <S.ColorFulSpan valid={passwordVerifications.hasEnoughLength}>
              pelo menos 8 caracteres
            </S.ColorFulSpan>
            ,{" "}
            <S.ColorFulSpan valid={passwordVerifications.hasNumber}>
              incluindo números
            </S.ColorFulSpan>
            ,{" "}
            <S.ColorFulSpan valid={passwordVerifications.hasUppercase}>
              letra maiúscula
            </S.ColorFulSpan>
            ,{" "}
            <S.ColorFulSpan valid={passwordVerifications.hasSpecialCharacters}>
              caracteres especiais como "@" ou "*"
            </S.ColorFulSpan>
            .
          </li>
        </S.SafePassword>
      </S.Form>
    </S.Wrapper>
  );
};

export default Security;
