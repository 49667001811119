import React from "react";

const LinkedIn = ({ color }) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 0C17.0304 0 17.5391 0.210714 17.9142 0.585786C18.2893 0.960859 18.5 1.46957 18.5 2V16C18.5 16.5304 18.2893 17.0391 17.9142 17.4142C17.5391 17.7893 17.0304 18 16.5 18H2.5C1.96957 18 1.46086 17.7893 1.08579 17.4142C0.710714 17.0391 0.5 16.5304 0.5 16V2C0.5 1.46957 0.710714 0.960859 1.08579 0.585786C1.46086 0.210714 1.96957 0 2.5 0H16.5ZM16 15.5V10.2C16 9.33539 15.6565 8.5062 15.0452 7.89483C14.4338 7.28346 13.6046 6.94 12.74 6.94C11.89 6.94 10.9 7.46 10.42 8.24V7.13H7.63V15.5H10.42V10.57C10.42 9.8 11.04 9.17 11.81 9.17C12.1813 9.17 12.5374 9.3175 12.7999 9.58005C13.0625 9.8426 13.21 10.1987 13.21 10.57V15.5H16ZM4.38 5.56C4.82556 5.56 5.25288 5.383 5.56794 5.06794C5.883 4.75288 6.06 4.32556 6.06 3.88C6.06 2.95 5.31 2.19 4.38 2.19C3.93178 2.19 3.50193 2.36805 3.18499 2.68499C2.86805 3.00193 2.69 3.43178 2.69 3.88C2.69 4.81 3.45 5.56 4.38 5.56ZM5.77 15.5V7.13H3V15.5H5.77Z"
        fill={color}
      />
    </svg>
  );
};
export default LinkedIn;
