import { Redirect, Route } from "react-router";
import UseServices from "hooks/UseServices";
import AdminLayout from "styles/layouts/admin";

const AdminRouter = ({ component: Component, ...rest }) => {
  const { parsedJWT } = UseServices();

  if (!Object.keys(parsedJWT).length) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }

  return (
    <AdminLayout pageName="Nome da Página">
      <Route {...rest} render={(props) => <Component {...props} />} />
    </AdminLayout>
  );
};

export default AdminRouter;
