import React from "react";

const Success = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.9998 0.666748C15.6665 0.666748 0.666504 15.6667 0.666504 34.0001C0.666504 52.3334 15.6665 67.3334 33.9998 67.3334C52.3332 67.3334 67.3332 52.3334 67.3332 34.0001C67.3332 15.6667 52.3332 0.666748 33.9998 0.666748ZM27.3332 50.6668L10.6665 34.0001L15.3665 29.3001L27.3332 41.2334L52.6332 15.9334L57.3332 20.6667L27.3332 50.6668Z"
        fill={color}
      />
    </svg>
  );
};
export default Success;
