import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";
import Theme from "./themes/vm-main-theme";
import GlobalStyles from "./themes/globalStyle";
import Routes from "Routes";
import ServicesContext from "contexts/ServicesContext";

const App = () => {
  return (
    <>
      <GlobalStyles />
      <Theme>
        <ReactNotification />
        <ServicesContext>
          <Routes />
        </ServicesContext>
      </Theme>
    </>
  );
};

export default App;
