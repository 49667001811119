import React from "react";

const Refresh = ({ color }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.995 7.51688e-06C7.49827 -0.0020439 6.03098 0.415829 4.7599 1.20613C3.48882 1.99643 2.46494 3.12747 1.80463 4.47067C1.14432 5.81388 0.874071 7.31539 1.02459 8.80454C1.17512 10.2937 1.74038 11.7107 2.65612 12.8946C3.57187 14.0785 4.80138 14.9818 6.20489 15.5018C7.60841 16.0217 9.12965 16.1375 10.5957 15.836C12.0617 15.5344 13.4138 14.8277 14.4982 13.796C15.5826 12.7644 16.3558 11.4492 16.73 10H14.649C14.2823 11.0372 13.6375 11.9536 12.7851 12.6491C11.9327 13.3445 10.9055 13.7922 9.81575 13.9432C8.72605 14.0942 7.61581 13.9427 6.6064 13.5053C5.59699 13.0679 4.72728 12.3613 4.09234 11.4629C3.45741 10.5646 3.08169 9.50889 3.00626 8.41136C2.93084 7.31384 3.15862 6.2167 3.66469 5.2399C4.17076 4.2631 4.93565 3.44424 5.87573 2.87283C6.8158 2.30143 7.89489 1.99948 8.995 2.00001C9.78188 2.00116 10.5606 2.15917 11.2857 2.4648C12.0108 2.77043 12.6677 3.21754 13.218 3.78001L10 7.00001H17V7.51688e-06L14.649 2.35001C13.9082 1.60469 13.0272 1.01333 12.0568 0.610007C11.0865 0.206684 10.0459 -0.000630955 8.995 7.51688e-06V7.51688e-06Z"
        fill={color}
      />
    </svg>
  );
};
export default Refresh;
