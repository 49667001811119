import React from "react";
import getIcon from "helpers/getIcon";
import * as S from "./styles";

const ContainerWithTitle = ({
  icon,
  title,
  mt,
  children,
  fullscreen,
  ...rest
}) => {
  const requestFullscreen = () => {
    const fullscreenArea = document.getElementById("fullscreen_area");
    fullscreenArea && fullscreenArea.requestFullscreen();
  };

  return (
    <S.Wrapper
      id="fullscreen_area"
      {...rest}
      mt={mt}
      haveHeader={icon || fullscreen || title}
    >
      {(icon || fullscreen || title) && (
        <S.Header>
          {icon && <S.Icon>{getIcon(icon)}</S.Icon>}
          <S.Title>{title}</S.Title>

          {fullscreen && (
            <S.FullscreenButton
              onClick={() => {
                requestFullscreen();
              }}
            >
              {getIcon("openFullscreen")}
            </S.FullscreenButton>
          )}
        </S.Header>
      )}

      <S.Content>{children}</S.Content>
    </S.Wrapper>
  );
};

export default ContainerWithTitle;
