import styled from "styled-components";

export const Wrapper = styled.div`
    width: 99%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;
