import { Redirect, Route } from "react-router";
import adminRoutes from "../Admin/routes";
import commonRoutes from "../Common/routes";
import UseServices from "hooks/UseServices";

const NoAuthRoute = ({ component: Component, ...rest }) => {
  const { parsedJWT } = UseServices();

  if (Object.keys(parsedJWT).length) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname:
                parsedJWT.user_type_id === 1
                  ? adminRoutes.Home.path
                  : commonRoutes.Home.path,
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default NoAuthRoute;
