import tw, { styled, css } from "twin.macro";

export const CommonLayout = styled.div`
  ${tw`grid w-screen bg-lightGray h-screen`}
  ${css`
    grid-template-columns: 100px 1fr;
    grid-template-rows: 120px 1fr;
    grid-template-areas: "sidebar header" "sidebar content";
    transition: ease all 300ms;
    `}
    ${({ opened }) =>
    opened &&
    css`
      grid-template-columns: 340px 1fr;
    `}
      
    @media screen and (max-width: 670px) {
      grid-template-areas: "header header" "content content";
      grid-template-columns: 1fr !important;
      grid-template-rows: 90px 1fr;
  }
`;
