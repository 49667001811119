import { toDateTime } from "helpers/formatHelper";

export const columns = [
  {
    name: "Criador",
    selector: (row) => row?.album?.user?.full_name,
    sortable: true,
  },
  {
    name: "Título",
    selector: (row) => row?.title,
    cell: (row) => <div>{row?.title}</div>,
    sortable: true,
  },
  {
    name: "Descrição",
    selector: (row) => row?.description,
    cell: (row) => <div>{row?.description}</div>,
    sortable: true,
  },
  {
    name: "Thumbnail",
    selector: (row) => row?.thumbnail_src,
    cell: (row) => <a href={row?.thumbnail_src}>Ver Thumbnail</a>,
  },
  {
    name: "Video",
    selector: (row) => row?.src,
    cell: (row) => <a href={row.src}>Ver vídeo</a>,
  },
  {
    name: "Data de registro",
    selector: (row) => (row.createdAt ? toDateTime(row.createdAt) : "N/A"),
    sortable: true,
  },
];

export const downloadColumns = columns;
