import "twin.macro";
import * as S from "../../styles";
import UseServices from "hooks/UseServices";
import { HTTPErrorCallback } from "helpers/errorHelper";
import { useForm } from "react-hook-form";
import Input from "Components/Input";
import Button from "Components/Button";
import { showNotification } from "helpers/notificationHelper";

const Personal = ({ data, setLoading }) => {
  const { api, getProfile } = UseServices();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      full_name: data?.full_name,
      email: data?.email,
    },
  });

  const updateProfile = (data) => {
    setLoading(true);
    api
      .put("/users", data)
      .then((response) => {
        setLoading(false);
        getProfile();
        showNotification("success", "Sucesso!", "Dados alterados com sucesso!");
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <S.Wrapper>
      <h3>Dados Pessoais</h3>
      <S.Form onSubmit={handleSubmit(updateProfile)}>
        <Input
          label="Nome Completo"
          placeholder="Nome Completo"
          name="full_name"
          required
          icon="name"
          register={register}
        />
        <Input
          label="E-mail"
          placeholder="E-mail"
          name="email"
          required
          icon="email"
          register={register}
        />
        <Button type="submit">Salvar dados</Button>
      </S.Form>
    </S.Wrapper>
  );
};

export default Personal;
