import React from "react";

const Logout = ({ color }) => {
  return (
    <svg
      width="25"
      height="27"
      viewBox="0 0 25 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6667 19.9583V16.0833H8.625V10.9167H17.6667V7.04168L24.125 13.5L17.6667 19.9583ZM15.0833 0.583344C15.7685 0.583344 16.4256 0.855515 16.91 1.33998C17.3945 1.82445 17.6667 2.48153 17.6667 3.16668V5.75001H15.0833V3.16668H3.45833V23.8333H15.0833V21.25H17.6667V23.8333C17.6667 24.5185 17.3945 25.1756 16.91 25.66C16.4256 26.1445 15.7685 26.4167 15.0833 26.4167H3.45833C2.77319 26.4167 2.11611 26.1445 1.63164 25.66C1.14717 25.1756 0.875 24.5185 0.875 23.8333V3.16668C0.875 2.48153 1.14717 1.82445 1.63164 1.33998C2.11611 0.855515 2.77319 0.583344 3.45833 0.583344H15.0833Z"
        fill={color}
      />
    </svg>
  );
};
export default Logout;
