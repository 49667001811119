import React from "react";

const Price = ({ color }) => {
  return (
    <svg
      width="46"
      height="30"
      viewBox="0 0 46 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 0H45.5V30H0.5V0ZM23 7.5C24.9891 7.5 26.8968 8.29018 28.3033 9.6967C29.7098 11.1032 30.5 13.0109 30.5 15C30.5 16.9891 29.7098 18.8968 28.3033 20.3033C26.8968 21.7098 24.9891 22.5 23 22.5C21.0109 22.5 19.1032 21.7098 17.6967 20.3033C16.2902 18.8968 15.5 16.9891 15.5 15C15.5 13.0109 16.2902 11.1032 17.6967 9.6967C19.1032 8.29018 21.0109 7.5 23 7.5V7.5ZM10.5 5C10.5 6.32608 9.97322 7.59785 9.03553 8.53553C8.09785 9.47321 6.82608 10 5.5 10V20C6.82608 20 8.09785 20.5268 9.03553 21.4645C9.97322 22.4021 10.5 23.6739 10.5 25H35.5C35.5 23.6739 36.0268 22.4021 36.9645 21.4645C37.9021 20.5268 39.1739 20 40.5 20V10C39.1739 10 37.9021 9.47321 36.9645 8.53553C36.0268 7.59785 35.5 6.32608 35.5 5H10.5Z"
        fill={color}
      />
    </svg>
  );
};
export default Price;
