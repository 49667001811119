import { useEffect, useState } from "react";
import StatusTag from "Components/Admin/StatusTag";
import { HTTPErrorCallback } from "helpers/errorHelper";
import UseServices from "hooks/UseServices";
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from "styles/layouts/admin/wrappers";
import ContainerWithTitle from "Components/Admin/ContainerWithTitle";
import { columns, downloadColumns } from "./columns";
import Table from "Components/Admin/Table";
import { toDateTime } from "helpers/formatHelper";
import Actions from "./Actions";
import UpdateFeedback from "../Update";

const Feedbacks = () => {
  const { api } = UseServices();
  const [feedbacks, setFeedbacks] = useState([]);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getFeedbacks();
    //eslint-disable-next-line
  }, []);

  const getFeedbacks = () => {
    setLoading(true);
    api
      .get(`/feedbacks/list`)
      .then((response) => {
        setFeedbacks(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  return (
    <>
      <HeaderWrapper>
        <StatusTag
          icon="star"
          title="Relatório de Arquivos"
          infos={[{ label: "Total de Arquivos", value: feedbacks?.length }]}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            {!selectedFeedback ? (
              <Table
                data={feedbacks}
                columns={[
                  ...columns,
                  {
                    name: "Ações",
                    cell: (r) => (
                      <Actions
                        setLoading={setLoading}
                        getFeedbacks={getFeedbacks}
                        setSelectedFeedback={setSelectedFeedback}
                        id={r.id}
                        row={r}
                      />
                    ),
                  },
                ]}
                loading={loading}
                customHeader
                downloadColumns={downloadColumns}
                dataTableProps={{ pagination: true }}
                fileName={`Arquivos ${toDateTime(new Date())}`}
                refresh={getFeedbacks}
              />
            ) : (
              <UpdateFeedback feedback={selectedFeedback} />
            )}
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default Feedbacks;
