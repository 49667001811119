import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import SectionLoading from "Components/Global/SectionLoading";

const apiURL =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:3333"
    : `https://api.${window.location.host}/api`;

export const ServicesContext = React.createContext({});

const ServiceProvider = ({ children }) => {
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [profile, setProfile] = useState({});
  const api = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userJWTToken")}`,
    },
  });

  const parseJwt = () => {
    if (!localStorage.getItem("userJWTToken")) return {};
    const base64Url = localStorage.getItem("userJWTToken").split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");

    return JSON.parse(window.atob(base64));
  };

  const parsedJWT = parseJwt();

  useEffect(() => {
    if (!localStorage.getItem("userJWTToken")) return;

    getProfile();
    //eslint-disable-next-line
  }, []);

  const getProfile = () => {
    setLoadingProfile(true);
    setProfile(parsedJWT);
    api
      .get("/users/profile")
      .then((response) => {
        setProfile({
          ...response.data,
          image_src:
            response.data.image_src ||
            process.env.PUBLIC_URL + "/images/blank_profile.webp",
        });
        setLoadingProfile(false);
      })
      .catch(() => {
        localStorage.removeItem("userJWTToken");
        window.location.reload();
      });
  };

  const contextValue = {
    api,
    parsedJWT,
    profile,
    getProfile,
    loadingProfile,
  };

  return (
    <>
      <ServicesContext.Provider value={contextValue}>
        {loadingProfile && <SectionLoading />}
        {api && children}
      </ServicesContext.Provider>
    </>
  );
};

export default ServiceProvider;
