import { Redirect, Route } from "react-router";
import UseServices from "hooks/UseServices";
import CommonLayout from "Layout/Common";

const CommonRoute = ({ component: Component, ...rest }) => {
  const { parsedJWT } = UseServices();

  const redirect = () => {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  };

  if (
    !Object.keys(parsedJWT).length ||
    (rest.userType && rest.userType !== parsedJWT.user_type_id)
  )
    return redirect();

  return (
    <Route
      {...rest}
      render={(props) => (
        <CommonLayout>
          <Component {...props} />
        </CommonLayout>
      )}
    />
  );
};

export default CommonRoute;
