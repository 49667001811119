import React from "react";

const Magnifier = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.10417 0.5C11.4027 0.5 13.6071 1.41309 15.2324 3.03841C16.8577 4.66372 17.7708 6.86812 17.7708 9.16667C17.7708 11.3133 16.9842 13.2867 15.6908 14.8067L16.0508 15.1667H17.1042L23.7708 21.8333L21.7708 23.8333L15.1042 17.1667V16.1133L14.7442 15.7533C13.1716 17.0957 11.1718 17.8332 9.10417 17.8333C6.80562 17.8333 4.60122 16.9202 2.97591 15.2949C1.35059 13.6696 0.4375 11.4652 0.4375 9.16667C0.4375 6.86812 1.35059 4.66372 2.97591 3.03841C4.60122 1.41309 6.80562 0.5 9.10417 0.5ZM9.10417 3.16667C5.77083 3.16667 3.10417 5.83333 3.10417 9.16667C3.10417 12.5 5.77083 15.1667 9.10417 15.1667C12.4375 15.1667 15.1042 12.5 15.1042 9.16667C15.1042 5.83333 12.4375 3.16667 9.10417 3.16667Z"
        fill={color}
      />
    </svg>
  );
};
export default Magnifier;
