import { styled } from "twin.macro";

export const Content = styled.div`
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  align-items: flex-start;
  height: fit-content;
  padding: 25px;
  max-height: calc(100vh - 140px);
  overflow: auto;
  max-width: 99%;

  @media screen and (max-width: 670px) {
    max-height: auto;
    height: auto;
  }
`;

export const WorkoutWrapper = styled.div`
  border-radius: 10px;
  padding: 10px;
  width: fit-content;
  white-space: nowrap;
  cursor: pointer;
  background-color: ${({ active }) =>
    active ? "rgb(0, 182, 144)" : "#1d9d6150"};
  color: rgb(29, 22, 23);
  transition: 0.4s ease all;
  text-align: center;
  font-weight: bold;
`;

export const TilesWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: min-content;
  margin: 0 auto;
`;

export const Workouts = styled.div`
  background-color: #fff;
  border-radius: 12px;
  max-height: 90px;
  max-width: 100%;
  padding: 20px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
`;
