import React from "react";

const Add = ({ color }) => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.9906 24.9906V3.34257H3.34257V24.9906H24.9906ZM24.9906 0.25C25.8108 0.25 26.5974 0.575824 27.1774 1.15579C27.7573 1.73576 28.0831 2.52237 28.0831 3.34257V24.9906C28.0831 25.8108 27.7573 26.5974 27.1774 27.1774C26.5974 27.7573 25.8108 28.0831 24.9906 28.0831H3.34257C2.52237 28.0831 1.73576 27.7573 1.15579 27.1774C0.575824 26.5974 0.25 25.8108 0.25 24.9906V3.34257C0.25 2.52237 0.575824 1.73576 1.15579 1.15579C1.73576 0.575824 2.52237 0.25 3.34257 0.25H24.9906ZM12.6203 6.43514H15.7129V12.6203H21.898V15.7129H15.7129V21.898H12.6203V15.7129H6.43514V12.6203H12.6203V6.43514Z"
        fill={color}
      />
    </svg>
  );
};
export default Add;
