import * as S from "./styles";

const LoginForm = ({ onSubmit, children }) => {
  return (
    <>
      <S.Wrapper onSubmit={onSubmit}>{children}</S.Wrapper>
    </>
  );
};
export default LoginForm;
