import { useState } from "react";
import * as S from "./styles.js";
import { useEffect } from "react";
import UseServices from "hooks/UseServices";
import { HTTPErrorCallback } from "helpers/errorHelper.js";

const PAGE_SIZE = 10;

const Videos = () => {
  const { api } = UseServices();
  const [videos, setVideos] = useState([]);
  const [queriedVideos, setQueriedVideos] = useState([]);
  const [currentPageVideos, setCurrentPageVideos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getVideos();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const queriedVideos = videos.filter((v) =>
      v.title.toUpperCase().includes(query.toUpperCase())
    );

    if (!queriedVideos.length) setQueriedVideos(videos);
    else setQueriedVideos(queriedVideos);

    setCurrentPage(1);
    //eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    updateCurrentPageVideos();
    //eslint-disable-next-line
  }, [videos, queriedVideos, currentPage]);

  const getVideos = () => {
    api
      .get("/videos")
      .then((response) => {
        setVideos(response.data.videos);
        setQueriedVideos(response.data.videos);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
      });
  };

  const updateCurrentPageVideos = () => {
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    const currentVideos = queriedVideos.slice(startIndex, endIndex);
    setCurrentPageVideos(currentVideos);
  };

  if (!videos.length) return <h1>Carregando</h1>;

  return (
    <S.Wrapper>
      <S.Searchbar
        placeholder="Pesquisar..."
        type="search"
        onChange={(e) => setQuery(e.target.value)}
      />
      <S.VideosWrapper>
        {currentPageVideos.map((video) => (
          <S.VideoWrapper key={video.id}>
            <S.Video controls loading="lazy">
              <source src={video.src} loading="lazy" />
            </S.Video>
            <h3>{video.title}</h3>
          </S.VideoWrapper>
        ))}
        <S.PaginationWrapper>
          <S.PageButton
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Anterior
          </S.PageButton>
          <S.CurrentPageIndicator>Página {currentPage}</S.CurrentPageIndicator>
          <S.PageButton
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage * PAGE_SIZE >= videos.length}
          >
            Próxima
          </S.PageButton>
        </S.PaginationWrapper>
      </S.VideosWrapper>
    </S.Wrapper>
  );
};

export default Videos;
