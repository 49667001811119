import React from "react";

const FilledUser = ({ color }) => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 0.333313C12.9145 0.333313 14.271 0.895216 15.2712 1.89541C16.2714 2.8956 16.8333 4.25216 16.8333 5.66665C16.8333 7.08113 16.2714 8.43769 15.2712 9.43788C14.271 10.4381 12.9145 11 11.5 11C10.0855 11 8.72894 10.4381 7.72874 9.43788C6.72855 8.43769 6.16665 7.08113 6.16665 5.66665C6.16665 4.25216 6.72855 2.8956 7.72874 1.89541C8.72894 0.895216 10.0855 0.333313 11.5 0.333313ZM11.5 13.6666C17.3933 13.6666 22.1666 16.0533 22.1666 19V21.6666H0.833313V19C0.833313 16.0533 5.60665 13.6666 11.5 13.6666Z"
        fill={color}
      />
    </svg>
  );
};
export default FilledUser;
