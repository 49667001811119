import Home from "Pages/Common/Home";
import Profile from "Pages/Common/Profile";
import Workouts from "Pages/Common/Workouts/List";
import CreateWorkout from "Pages/Common/Workouts/Create";
import Exercises from "Pages/Common/Exercises/List";
import CreateExercise from "Pages/Common/Exercises/Create";
import Videos from "Pages/Common/Videos";
import Feedbacks from "Pages/Common/Feedbacks/List";
import CreateFeedback from "Pages/Common/Feedbacks/Create";

export const PROFILES = {
  ADMIN: 1,
  TEACHER: 2,
  STUDENT: 3,
};

const routes = {
  Home: {
    path: "/home",
    exact: true,
    component: Home,
    title: "Treinos",
    getTitle: (role) => {
      if (role === PROFILES["TEACHER"]) return "Alunos";
      return "Treinos";
    },
    icon: "menweight",
    getIcon: (role) => {
      if (role === PROFILES["TEACHER"]) return "magnifier";
      return "menweight";
    },
  },
  Trainings: {
    path: "/trainings",
    exact: true,
    component: Workouts,
    title: "Treinos",
    icon: "exercise",
    userType: PROFILES.TEACHER,
  },
  CreateTrainings: {
    path: "/create-trainings",
    exact: true,
    component: CreateWorkout,
    title: "Criar Treinos",
    icon: "createexercise",
    userType: PROFILES.TEACHER,
  },
  CreateExercise: {
    path: "/exercises/create",
    exact: true,
    component: CreateExercise,
    title: "Criar Exercícios",
    icon: "menweight",
    userType: PROFILES.TEACHER,
  },
  ListExercises: {
    path: "/exercises",
    exact: true,
    component: Exercises,
    title: "Exercícios",
    icon: "menweight",
    userType: PROFILES.TEACHER,
  },
  Videos: {
    path: "/videos",
    exact: true,
    component: Videos,
    title: "Videos",
    icon: "camera",
  },
  CreateFeedback: {
    title: "Criar Arquivos",
    icon: "note",
    path: "/criar-arquivos",
    exact: true,
    component: CreateFeedback,
    userType: PROFILES.TEACHER,
  },
  Feedbacks: {
    path: "/arquivos",
    exact: true,
    component: Feedbacks,
    title: "Arquivos",
    icon: "note",
  },

  Profile: {
    path: "/profile",
    exact: true,
    component: Profile,
    title: "Perfil",
    icon: "user",
  },
};

const routesKeys = Object.keys(routes);
const routesArr = [];

for (const key of routesKeys) routesArr.push(routes[key]);

export const TEACHER_ROUTES = routesArr.filter(
  (r) => !r.userType || r.userType === PROFILES.TEACHER
);
export const STUDENT_ROUTES = routesArr.filter(
  (r) => !r.userType || r.userType === PROFILES.STUDENT
);

export default routes;
